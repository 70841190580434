<template>
  <div v-if="loading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <template v-if="enabledDashboards.length !== 1">
      <ul class="nav justify-content-center">
        <li v-for="(d,idx) of enabledDashboards" :key="idx" class="nav-item">
          <router-link class="nav-link" :to="{name: d.url_path}" :title="d.name">
            {{ d.name }}
          </router-link>
        </li>
      </ul>
    </template>
    <router-view />
  </div>
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";


const ApplicationDashboards = [
  {
    url_path: 'internal-dashboard-plugin',
    permission_key: PermissionNames.INTERNAL_DASHBOARD_PLUGIN,
    name: 'Plugin Dashboard',
    enabled: false
  },
  {
    url_path: 'internal-dashboard-manual',
    permission_key: PermissionNames.INTERNAL_DASHBOARD_MANUAL,
    name: 'Manual Dashboard',
    enabled: false
  },
  {
    url_path: 'internal-dashboard-unfinished-business',
    permission_key: PermissionNames.INTERNAL_DASHBOARD_UNFINISHED_BUSINESS,
    name: 'Unfinished Business Dashboard',
    enabled: false
  }
]
export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    enabledDashboards() {
      if(!this.user || !this.userPermissions) return [];
      return ApplicationDashboards.filter((d) => {
        const dash_perm = this.userPermissions[d.permission_key];
        d.enabled = dash_perm ? (dash_perm.read || false) : false;
        return d.enabled;
      })
    },
  },
  watch: {
    enabledDashboards: { 
    handler(newVal){
      if(newVal.length == 1) {
        this.$router.push({ name: newVal[0].url_path});
        return;
      }
    }, immediate: true,}
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        text: 'Dashboards'
      }
    ]);
  },
}
</script>
