<template>
  <PopupModal ref="popup">
    <div>
      <h5 class="modal-title">
        {{ title }}
      </h5>
      <hr>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary mr-1" @click="confirm">
        {{ confirmButtonText }}
      </button>
      <button type="button" class="btn btn-white" @click="cancel">
        {{ cancelButtonText }}
      </button>
    </div>
  </PopupModal>
</template>

<script lang="js">
import PopupModal from '@/src/components/shared/PopupModal.vue'

export default {
  components: {
    PopupModal
  },
emits: ['confirm'],
  data() {
    return {
      // Parameters that change depending on the type of dialogue
      title: 'Confirm Action',
      message: 'Are you sure you would like to continue?', // Main text content
      confirmButtonText: 'Confirm', // Text for confirm button
      cancelButtonText: 'Cancel', // text for cancel button
    };
  },

  methods: {
    /**
     * Show the confirmation dialog
     * @param {Object} opts
     * @param {String} opts.title
     * @param {String} opts.message
     * @param {String} opts.confirmButtonText
     * @param {String} opts.cancelButtonText
     */
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message || this.message ;
      this.confirmButtonText = opts.confirmButtonText || this.confirmButtonText;
      this.cancelButtonText = opts.cancelButtonText || this.cancelButtonText;

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
    },

    confirm() {
      this.$refs.popup.close();
      this.$emit('confirm');
    },

    cancel() {
      this.$refs.popup.close();
    },
  },
}
</script>
