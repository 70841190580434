<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Roles and Permissions
    </template>
    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_roles_new' }" 
            class="btn btn-primary btn-sm ml-auto">
            Add new Role
          </router-link>
        </div>
      </div>

      <PortalTable ref="admin_roles_index" table-id="admin_roles_index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        empty="No roles found!"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="role_name" 
            header="Role" 
            filter-field="role_name"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by Role"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_roles_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { FilterMatchMode } from 'primevue/api';
import { useRoleStore } from '@/src/stores/role';
import { paginationMixin } from "@/src/mixins/paginationMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

export default {
  components: {
    CardSection,
    PortalTable,
    Column,
    InputText
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  setup() {
    const roleStore = useRoleStore();
    return { roleStore };
  },
  data() {
    return {
      search_params: '',
      filterValues: {
        "role_name" : { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      }
    }
  },
  async mounted() {
    await this.getRowData(true);
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber
      };

      params = { ...this.search_params, ...params };
      try {
        const response = await this.roleStore.loadAllRoles(params);
        this.processPagyResponse(response);

        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    onFilterChange(event) {
      this.search_params = event;
      this.getRowData(true);
    }
  }
}
</script>
