<template>
  <ModalLayout :ref="modalRef"
    @close="closeModal">
    <template #modal-header>
      <h5 class="modal-title">
        Preview Template
      </h5>
    </template>
    <template #modal-body>
      <PreviewValidate v-if="!preview" 
        :category-name="categoryName"
        :txc-code="txcCode"
        :template-id="templateId"
        @success="savePreview" 
        @close-modal="closeModal" />
      <PreviewShow v-if="preview"
        :preview="preview"
        @back="reset" />
    </template>
  </ModalLayout>
</template>

<script lang="js">
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import { useTemplateStore } from '@/src/stores/template';
import { storeToRefs } from 'pinia'
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import PreviewValidate from "@/src/components/admin-area/templates/preview/PreviewValidate.vue";
import PreviewShow from "@/src/components/admin-area/templates/preview/PreviewShow.vue";

export default {
  components: {
    ModalLayout,
    PreviewValidate,
    PreviewShow
  },
  mixins: [ modalActionsMixin, savableStateMixin ],
  props: {
    categoryName: { default: null, type: String },
    txcCode: { default: null, type: String },
    templateId: { default: null, type: String }
  },
  setup() {
    const templateStore = useTemplateStore();
    return {
      templateStore
    }
  },
  data() {
    return {
      preview: null,
      modalRef: 'templatePreviewModal',
    }
  },
  methods: {
    savePreview(response) {
      // store preview for display
      this.preview = response;
    },
    reset() {
      this.preview = null;
    },
    closeModal(){
      this.reset()
    },
  }
}
</script>
