<template>
  <div :class="cssClass">
    <template v-if="mode == 'local'">
      <DataTable :id="tableId"
        ref="dataTable"
        v-model:filters="filterData"
        v-model:selection="selectedRows"
        v-model:editingRows="editingRows"
        data-key="id"
        :value="tableData"
        :rows="rows"
        :loading="loading"
        :edit-mode="editMode"
        :selection-mode="selectionMode"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :row-style="rowStyle"
        :row-class="rowClass"
        removable-sort
        show-gridlines
        :paginator="paginator"
        scrollable
        class="table-bordered"
        table-style="min-width: 50rem"
        :filter-display="filterDisplay"
        @page="onPage($event)"
        @row-select="onRowSelect"
        @row-unselect="onRowUnselect"
        @row-select-all="onRowSelectAll"
        @row-unselect-all="onRowUnselectAll"
        @row-edit-save="onRowEditSave"
        @row-edit-init="onRowEditInit"
        @sort="onSort($event)"
        @update:filters="debounce(() => { onFilterChange($event) })">
        <slot name="columns" />
        <div style="background:#f2f2f2">
          <slot name="headers" />
        </div>
        <template v-if="empty" #empty>
          {{ empty }}
        </template>
      </DataTable>
    </template>
    <template v-else>
      <DataTable :id="tableId"
        ref="dataTable"
        v-model:filters="filterData"
        v-model:selection="selectedRows"
        v-model:editingRows="editingRows"
        data-key="id"
        :value="tableData"
        :first="getFirstRowIndex"
        :rows="rows"
        :rows-per-page-options="pageSize"
        :loading="loading"
        :total-records="totalRecords"
        :edit-mode="editMode"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :selection-mode="selectionMode"
        removable-sort
        show-gridlines
        scrollable
        :lazy="isLazy"
        class="table-bordered"
        table-style="min-width: 50rem"
        :filter-display="filterDisplay"
        @update:filters="debounce(() => { onFilterChange($event) })"
        @page="onPage($event)"
        @sort="onSort($event)"
        @row-select="onRowSelect"
        @row-unselect="onRowUnselect"
        @row-select-all="onRowSelectAll"
        @row-unselect-all="onRowUnselectAll"
        @row-edit-save="onRowEditSave"
        @row-edit-init="onRowEditInit">
        <slot name="columns" />
        <div style="background:#f2f2f2">
          <slot name="headers" />
        </div>
        <template v-if="empty" #empty>
          {{ empty }}
        </template>
      </DataTable>
      <Pagination v-if="remotePagination()" 
        :current_page="page" 
        :page_count="pageCount"
        @update-page="onPage($event)" />
    </template>
  </div>
</template>

<script lang="js">
import DataTable from 'primevue/datatable';
import Pagination from "@/src/components/shared/Pagination.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import { createDebounce } from "@/src/utils";

export default {
  components: {
    Pagination,
    DataTable,
  },
  props: {
    mode: { default: 'remote', type: String },
    totalRecords: { required: true, type: Number },
    page: { default: 1, type: Number },
    pageSize: { default: 20, type: Number },
    pageCount: { default: 1, type: Number },
    paginator: { default: false, type: Boolean },
    selectionMode: { default: null, type: String },
    rows: { default: 20, type: Number },
    tableId: { required: true, type: String },
    tableData: { required: true, type: Array }, // link data to table
    loading: { default: false, type: Boolean },
    empty: { default: null, type: String }, // Text to display in the empty table
    filterDisplay: { default: null, type: String },
    filterValues: { default: () => {}, type: Object },
    cssClass: { default: null, type: String }, // template styling
    editMode: { default: null, type: String }, //"cell" | "row"
    sortField: { default: null, type: String }, // set default sort field
    sortOrder: { default: null, type: String },
    rowStyle: { default: null, type: String },
    rowClass: { default: null, type: () => {} },
    addFilterToRoute: { default: false, type: Boolean } // set to true to add filter params to route
  },
  emits: ['onSort', 'onPage', 'onFilterChange', 'onSortChange', 'table-row-select', 'table-row-click', 'setPage', 'onRowEditSave', 'onRowEditInit'],
  setup() {
    const appStore = useAppCoreStore();
    return { appStore, debounce: createDebounce() }
  },
  data() {
    return {
      lazyParams: {},
      filterData: {},
      selectedRows: [],
      editingRows: []
    }
  },
  computed: {
    isLazy() {
      return this.mode == 'remote';
    }
  },
  watch:{
    $route (_to, _from) {
      if(this.addFilterToRoute) {
        // this.applyFiltersFromUrl();
      }
    }
  },
  mounted() {
    this.lazyParams = {
      first: this.first,
      rows: this.rows,
      filterField: null,
      filterBy: null,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
      totalRecords: this.totalRecords
    };
    this.filterData = this.filterValues;

    if(this.addFilterToRoute) {
      this.applyFiltersFromUrl();
      this.$nextTick(() => {
        this.$emit('onFilterChange')
      })
    }
  },
  methods: {
    remotePagination() {
      return this.mode == 'remote';
    },
    resetSelectedRows() {
      // reset selected rows
      this.selectedRows = [];
    },
    getFirstRowIndex() {
      // return index of first row
      return this.page == 1 ? 0 : this.tableConfig.pageSize * 20;
    },
    onFilterChange(event) {
      const keyValuePairs = Object.entries(event);
      if(this.addFilterToRoute) { 
        this.updateUrl(keyValuePairs);
        // send filter object back to parent
        this.$emit('onFilterChange', keyValuePairs);
      } else {
        //TODO: This else condition can be removed, once all onFilterChanges are refactored
        const filterBy = keyValuePairs.filter((item) => { 
          return item[1].value != ''
        })
        let filters = {};
        if (filterBy.length > 0) {
          filterBy.map((item, index) => {
            const filter = filterBy[index];
            filters[filter[0]] = filter[1].value ? filter[1].value : '';
          });
        }
        // send filter object back to parent
        this.$emit('onFilterChange', filters);
      }

      // Once we clear the filters, it is resting the matchMode to null, 
      // in case of anything other that FilterMatchMode.STARTS_WITH, it will not work 
      // if not reset it back.
      this.resetFilterMatchMode(keyValuePairs);
    },
    updateUrl(filters) {
      const currentPath = this.$route.path;

      let query = Object.assign({});
      filters.map((filter) => {
        const filterValue = filter[1].value;
        if(filterValue) {
          query[filter[0]] = filterValue;
        } 
      });
     
      // Depending on if it's the first time or not
      // we either add a historyState or replace it. The purpose is to 
      // avoid having a history state for each filter change. 
      const queryParamCount =  this.$route.query ? Object.keys(this.$route.query).length : 0;
      if(queryParamCount) {
        this.$router.replace({path: currentPath, query: query});
      } else {
        this.$router.push({path: currentPath, query: query});
      }
      
    },
    applyFiltersFromUrl() {
      const queryFilters = this.$route.query;
      if (Object.keys(queryFilters).length > 0){
        for( let key in queryFilters) {
          this.$refs.dataTable.d_filters[key] = { 
            value: queryFilters[key],
            matchMode: this.filterValues[key].matchMode
          };
        }
        this.$refs.dataTable.onFilterApply();
      }
    },
    onSortChange(event) {
      // emits on changing sort order
      // send 1, -1, or null
      this.$emit('onSortChange', event)
    },
    onPage(event) {
      this.lazyParams = event;
      this.$emit('onPage', this.lazyParams)
    },
    onSort(event) {
      this.lazyParams = event;
      this.$emit('onSort', this.lazyParams)
    },
    // Event handlers
    onRowSelect(event) {
      // Update internal sense of which rows are selected (only used for styling purposes)
      const row = event.data || {};
      const id = row._id;
      if (event.type == "checkbox") {
        // Emit event to parent, which will handle functional logic separate from styling
        this.$emit('table-row-select', this.selectedRows);
      } else {
        // Emit event to parent, which will handle functional logic separate from styling
        this.$emit('table-row-click', row);
      }
    },
    onRowUnselect(event){
      if (event.type == "checkbox") {
        // Emit event to parent
        this.$emit('table-row-select', this.selectedRows);
      }
    },
    onRowSelectAll(event){
      const rows = event.data || {};
      // Emit event to parent
      this.$emit('table-row-select', rows);
      
    },
    onRowUnselectAll(event){
      const rows = event.data || {};
          
      // Emit event to parent
      this.$emit('table-row-select', rows);
      
    },
    setPage(page) {
      this.$emit('setPage', page)
    },
    resetFilters(event) {
      const keys = Object.keys(event);

      if(Object.keys(this.$refs.dataTable.d_filters).length == 0) {
        this.$refs.dataTable.d_filters = this.filterValues;
      }

      this.$refs.dataTable.d_filters[`${keys[0]}`].value = event[keys[0]];
      this.$refs.dataTable.onFilterApply();
     
    },
    // Reset sort back to default settings
    resetSort() {
      this.$refs.dataTable.d_sortField = this.sortField;
      this.$refs.dataTable.d_sortOrder = this.sortOrder;
    },
    resetFilterMatchMode(filters) {
      if(this.$refs.dataTable) {
        filters.map((filter) => {
          this.$refs.dataTable.d_filters[`${filter[0]}`].matchMode = this.filterValues[`${filter[0]}`].matchMode
        })
      }
    },
    onRowEditSave(event) {
      this.$emit('onRowEditSave', event)
    },
    onRowEditInit(event) {
      this.$emit('onRowEditInit', event)
    }
  }
}

</script>
