<template>
  <div class="content-wrap dashboard-widgets">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card">
            <div class="card-header media" style="display: flex; align-items: center">
              <div class="media-left">
                <div>
                  <font-awesome-icon :icon="widgetIcon" />
                </div>
              </div>
              <div class="media-body">
                <h3 class="card-title">
                  {{ widgetTitle }}
                </h3>
                <nav class="nav card-nav">
                  <slot name="dashboardSidebarNav" />
                  <a v-if="enableFilters" :href="`#filters-${widgetId}`"
                    data-toggle="collapse" class="nav-link card-header-btn py-0 collapsed dashboard-sidenav-link" aria-expanded="false">
                    <span style="padding-right: 0.5rem">Filters</span>
                    <font-awesome-icon :icon="['fas', 'filter']" class="fa-sharp fa-light" />
                  </a>
                  <a :href="`#content-${widgetId}`" data-toggle="collapse"
                    class="nav-link card-header-btn py-0 dashboard-sidenav-link" aria-expanded="true">
                    <span style="padding-right: 0.5rem">Hide</span>
                    <font-awesome-icon :icon="['fas', 'chevron-down']" />
                  </a>
                </nav>
              </div>
            </div>
            <div v-if="enableFilters" :id="`filters-${widgetId}`" class="card-body card-options p-0 collapse">
              <div class="customize-panel">
                <h4>Filters</h4>

                <div class="row">
                  <div class="col-sm-12">
                    <slot name="filters" />
                  </div>
                </div>
              </div>
            </div>
            <div :id="`content-${widgetId}`" class="card-body collapse show">
              <div v-if="error" class="alert alert-danger">
                {{ error }}
              </div>
              <slot name="content" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="js">

export default {
  props: {
    widgetIcon: { required: true, type: String },
    widgetTitle: { required: true, type: String },
    widgetId: { required: true, type: String },
    enableFilters: { default: true, type: Boolean },
    error: { type: String, required: false, default: null },
  }
}
</script>
