<template>
  <div
    class="col-sm-8 message-component" v-if="message">
    <span :class="getAlertCss">{{ message }}
      <button v-if="isSuccess" type="button" class="close pl-2" data-dismiss="alert" @click="reset" aria-label="Close">
        <font-awesome-icon :icon="['fas', 'fa-times']" aria-hidden="true" />
      </button>
    </span>
  </div>
</template>

<script>
import { SaveStates } from "~/src/enums.js";

export default {
  components: {},
  data() {
    return {
      message: null,
      state: null,
    }
  },
  defineExpose() {
    setMessage,
    reset
  },
  computed: {
    isSuccess() {
      return this.state === SaveStates.Success;
    },
    getAlertCss() {
      if (this.state === SaveStates.Success) {
        return 'alert alert-success';
      } else if (this.state === SaveStates.Errored) {
        return 'alert-text';
      }
    }
  },
  methods: {
    setMessage(state, message) {
      this.state = state || null;
      this.message = message || null
    },
    reset() {
      this.state = null;
      this.message = null;
    }
  }
}
</script>
