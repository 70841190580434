<template>
  <div class="row">
    <div class="form-group-standard-6-column-medium-up">
      Previewing {{ preview.categoryName }} with Donor {{ preview.donorCode }} for Match {{ preview.matchCode }}
    </div>
  </div>
  <VeeForm @submit="onSubmit">
    <div v-if="preview.category_template_name === 'email'" class="row">
      <TextArea v-model="savedPreview.subject"
        name="Subject"
        label="Subject"
        rows="3"
        cols="100"
        col-style="col-12 mb-2"
        :disabled="true" />
    </div>
    <div class="row">
      <TextArea v-model="savedPreview.content"
        name="Body"
        label="Body"
        rows="15"
        cols="100"
        col-style="col-12 mb-2"
        :disabled="true" />
    </div>
    <ActionToolbar :component-state="currentState"
      primary-button-text="Back"
      secondary-button-text="Close" 
      :error-message="errorMessage"
      :data-dismiss="shouldDismissModal ? 'modal' : undefined"
      @secondary-click="close" />
  </VeeForm>
</template>
<script lang="js">
import TextArea from "@/src/components/shared/FormComponents/TextArea.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { Form as VeeForm } from 'vee-validate';

export default {
  components: {
    TextArea,
    ActionToolbar,
    VeeForm
  },
  props: {
    preview: { default: null, type: Object },
  },
  emits: [ 'back' ],
  data() {
    return {
      shouldDismissModal: null,
      savedPreview: this.preview
    }
  },
  methods: {
    close(){
      this.shouldDismissModal = true
      setTimeout(() => {
        this.shouldDismissModal = false
        }, 100);
    },
    onSubmit() {
      this.$emit("back")
    },
  }
}
</script>
<style>
 .page-content .form-control[readonly] {
    background-color: white;
  }
</style>
