<template>
  <div class="row">
    <div class="col-sm-12 mb-3">
      <p>
        <span v-if="userDeactivatedAt">Enable User</span>
        <span v-else>Disable User</span>
      </p>
    </div>
  </div>
  <ActionToolbar v-if="userDeactivatedAt"
    :secondary-enabled="false"   
    :component-state="currentState" 
    :error-message="formError" 
    success-message="Successfully disabled user!"
    primary-button-text="Enable User"
    @primary-click.prevent="onSubmitEnable()" />
  <ActionToolbar v-else :secondary-enabled="false"
    primary-style="danger"
    :component-state="currentState"  
    :error-message="formError" 
    success-message="Successfully enabled user!"
    primary-button-text="Disable User"
    @primary-click.prevent="onSubmitDisable()" />
</template>

<script>
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useUserStore } from '@/src/stores/user';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";

export default {
  components: {
    ActionToolbar
  },
  mixins: [ savableStateMixin ],
  props: {
    userDeactivatedAt: { required: true, type: String },
    userId: { required: true, type: String }
  },
  emits: ['load-user'],
  setup() {
    const userStore = useUserStore();
    return {
      userStore
    }
    },
  methods: {
    async onSubmitDisable() {
      await this.handleActivation('disabled');
    },
    async onSubmitEnable() {
      await this.handleActivation('enabled');
    },
    async handleActivation(setUser) {
      try {
        this.setSavableStateProcessing();
        const method = setUser == 'disabled' ? this.userStore.disableUser : this.userStore.enableUser;
        const response = await method(this.userId);
        this.$emit('load-user', response);
        this.setSavableStateSuccess();
        
      } catch(error) {
        this.handleFormError(error);
      }
    }
   }
}
</script>
