<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Edit Team {{ selectedTeamGroup && selectedTeamGroup.teamName ? `- ${selectedTeamGroup.teamName}` : '' }}
    </template>

    <template #body>
      <SubSection :sub-section-heading="subSectionHeading"
        :sub-section-nav="subSectionNav">
        <template #body>
          <VeeForm v-if="selectedTeamGroup" @submit="onSubmit">
            <TeamGroupDisabled :team-group="selectedTeamGroup" />

            <div class="row">
              <TextInput v-model="selectedTeamGroup.groupName" name="groupName" 
                label="Group Name"
                rules="required"
                col-style="form-group-standard-column" />
            </div>
            <div class="row">
              <label style="padding-left: .75rem;">
                Service Levels
              </label>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-2">
                <div class="form-check form-check-inline">
                  <input id="triage" 
                    v-model="selectedTeamGroup.triage"
                    class="form-check-input" 
                    type="checkbox"  
                    aria-labelledby="triage">
                  <label class="form-check-label" for="triage">Triage</label>
                </div>
              </div>
              <div class="col-lg-2 col-md-2">
                <div class="form-check form-check-inline">
                  <input id="offerHandling" 
                    v-model="selectedTeamGroup.offerHandling"
                    class="form-check-input" 
                    type="checkbox"  
                    aria-labelledby="offerHandling">
                  <label class="form-check-label" for="offerHandling">Offer Handling</label>
                </div>
              </div>
              <div class="col-lg-2 col-md-2">
                <div class="form-check form-check-inline">
                  <input id="caseHandling" 
                    v-model="selectedTeamGroup.caseHandling"
                    class="form-check-input" 
                    type="checkbox"  
                    aria-labelledby="caseHandling">
                  <label class="form-check-label" for="caseHandling">Case Handling</label>
                </div>
              </div>
            </div>
            <br>
            <PortalTable :ref="`team_group_index-${team_id}`"
              :table-id="`team_group_index-${team_id}`"
              :table-data="selectedTeamGroup.teamGroupAccesses"
              :loading="isSavableStateLoading"
              mode="local"
              edit-mode="cell"
              empty="No Team Access!">
              <template #columns>
                <Column field="transplant_center.code" 
                  header="TxC" />
                <Column field="organ_group.name" 
                  header="Organ Group" />
                <Column field="group_access" header="Part of Group">
                  <template #body="slotProps">
                    <input :id="`group-access-${slotProps.data.id}`"                    
                      v-model="slotProps.data.group_access"
                      type="checkbox" class="form-check-input">
                  </template>
                </Column>
              </template>
            </PortalTable>

            <ActionToolbar :primary-button-text="createNew ? 'Add new Group' : 'Save Group'"
              :component-state="currentState"
              :success-message="successMessage"
              :error-message="formError"
              :secondary-button-text="createNew ? 'Cancel' : 'Close'"
              @secondary-click="cancel" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useTeamStore } from '@/src/stores/team';
import SubSection from "@/src/components/shared/SubSection.vue";
import { Form as VeeForm } from 'vee-validate';
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {teamSubSectionNavMixin} from "@/src/components/admin-area/teams/teamSubSectionNavMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import TeamGroupDisabled from '@/src/components/admin-area/teams/_teamGroupDisabled.vue';

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    TextInput,
    PortalTable,
    Column,
    TeamGroupDisabled
  },
  mixins: [ savableStateMixin, teamSubSectionNavMixin ],
  setup() {
    const teamStore = useTeamStore();
    return { teamStore };
  },
  data() {
    return {
      selectedTeamGroup: null,
      successMessage: null,
    }
  },
  computed: {
    createNew() {
      return this.$route.params.group_id ? false : true;
    },
    subSectionHeading(){
      return (this.createNew ? 'New Group' : 'Edit Group' ) + ((this.selectedTeamGroup && this.selectedTeamGroup.groupName) ? ` - ${this.selectedTeamGroup.groupName}` : '' );
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      if(!this.createNew) {
        await this.loadTeamGroup(this.$route.params.id, this.$route.params.group_id); 
      } else {
        await this.loadTeam(this.$route.params.id); 
      }                                                                                                                      
      this.setSavableStateIdle();  
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    async loadTeamGroup(teamId, groupId) {
      const record = await this.teamStore.loadTeamGroup(teamId, groupId); 
      this.setSelectedTeamGroup(record)
    },
    async loadTeam(id) {
      const record = await this.teamStore.loadTeam(id);
      this.setSelectedTeam(record);
    },
    setSelectedTeam(record) {
      this.selectedTeamGroup = {
        teamId: record.id,
        teamName: record.name,
        teamGroupAccesses: record.team_accesses.map(ta => {
          return {
            transplant_center: ta.transplant_center,
            organ_group: ta.organ_group,
            group_access: false
          }
        })
      }
    },
    setSelectedTeamGroup(record) {
      this.selectedTeamGroup = {
        id: record.id,
        teamId: record.team_id,
        teamName: record.team.name,
        groupName: record.name,
        triage: record.service_levels.triage.enabled,
        offerHandling: record.service_levels.offer_handling.enabled,
        caseHandling: record.service_levels.case_handling.enabled,
        disabledAt: record.disabled_at,
        teamGroupAccesses: record.team_accesses.map(ta => {
          return {
            transplant_center: ta.transplant_center,
            organ_group: ta.organ_group,
            group_access: record.team_group_accesses.some((item) => {
              return item.organ_group_id == ta.organ_group_id && item.transplant_center_id == ta.transplant_center_id;
            })
          }
        })
      }
    },
    async onSubmit() {
      let response;
      this.setSavableStateProcessing();
      const payload = {
        name: this.selectedTeamGroup.groupName,
        team_id: this.selectedTeamGroup.teamId,
        service_levels: {
          triage: { enabled: this.selectedTeamGroup.triage || false },
          offer_handling: { enabled: this.selectedTeamGroup.offerHandling || false },
          case_handling: { enabled: this.selectedTeamGroup.caseHandling || false },
        },
        team_group_accesses: this.selectedTeamGroup.teamGroupAccesses
          .map(ta => {
            if(ta.group_access){
              return {
                transplant_center_id: ta.transplant_center.id,
                organ_group_id: ta.organ_group.id
              }
            }
        })
      }

      try {
        if (this.createNew) {
          response = await this.teamStore.createTeamGroup({ team_group: payload });

          this.successMessage = 'Successfully created team group!'
          this.$router.push({name: 'admin_teams_edit_groups_edit', params: {id: this.$route.params.id, group_id: response.id}});
        } 
        else {
          payload.id = this.selectedTeamGroup.id,
          response = await this.teamStore.updateTeamGroup({ team_group: payload });
          
          this.successMessage = 'Successfully updated team group!'
        } 
        this.setSelectedTeamGroup(response);
        this.setSavableStateSuccess();
      }catch(error) {
        this.handleFormError(error)
      }
    },
    cancel() {
      this.$router.push({ name: 'admin_teams_edit_groups_index' });
    },
  }
}
</script>
