<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Teams
    </template>
    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_teams_new' }" 
            class="btn btn-primary btn-sm ml-auto">
            Add New Team
          </router-link>
        </div>
      </div>

      <PortalTable ref="teams_index"
        table-id="admin-teams-index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        empty="No teams found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="name" header="Name" style="min-width: 100px;" 
            :sortable="true"
            filter-field="name" 
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by Team name"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="membership_category.name" header="Membership Category" style="min-width: 14rem"
            :sortable="true"
            sort-field="membership_category"
            filter-field="membership_category"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '14rem' }">
            <template #filter>
              <MultiSelectFilter ref="membership_category" 
                filter-field="membership_category"
                option-label="name"
                :options="teamStore.membershipCategories"
                @change="onChange" />
            </template>
          </Column>
          <Column field="team_accesses" header="Team Access" style="min-width: 170px;">
            <template #body="slotProps">
              <ul>
                <li v-for="ta in slotProps.data.team_accesses" :key="ta.id">
                  {{ ta.transplant_center.code }} - {{ ta.organ_group.name }}
                </li>
              </ul>
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_teams_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>           
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { useTeamStore } from '@/src/stores/team';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";



export default {
  components: {
    CardSection,
    PortalTable,
    Column,
    InputText,
    MultiSelectFilter
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  setup() {
    const teamStore = useTeamStore();
    return {
      teamStore
    }
  },
  data() {
    return {
      majorError: null,
      filterValues: {
        "name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "membership_category" : { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      }
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.teamStore.loadMembershipCategories();
      this.setSavableStateBlank();
      await this.getRowData();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;
      
      if (this.currentSortField) {
        this.sort_params = { sort_by: this.currentSortField, sort_type: this.currentSortType };
      } else {
        this.sort_params = '';
      }

      let params = {
        page: this.pageNumber
      };

      params = { ...this.sort_params,...this.search_params, ...params };
      try {
        const response = await this.teamStore.loadTeams(params)
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    onFilterChange(event) {
      if(event["membership_category"] === "") this.$refs.membership_category.clearFilters();

      this.search_params = event;
      this.getRowData(true);
    },
    onChange(event) {
      this.$refs.teams_index.resetFilters(event);
    }
  }
}
</script>
