import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP } from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useProcedureStore = defineStore('procedure-notes', {
  state: ()=> {
    return {
      // lookup data
      transplantCenters: [],
      organGroups: [],
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    

    // load transplant centres
    loadTransplantCentres() {
      return this.loadEntity(APIRoute(EP.admin.transplant_centers.index), 'transplantCenters')
    },
    // load organ groups
    loadOrganGroups() {
      return this.loadEntity(APIRoute(EP.admin.organ_groups.index), 'organGroups')
    },
    // Load procedure_notes
    loadProcedureNotes(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.procedure_notes.index), { params: params });
    },
    loadProcedureNote(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.procedure_notes.show, { id: id }));
    },
    createProcedureNotes(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.procedure_notes.create), { procedure_notes: payload });
    },
    updateProcedureNotes(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.admin.procedure_notes.update, { id: payload.id }), { procedure_notes: payload });
    },
    validateProcedureNotes(payload){
      return makeRequest(beApiClient.get, APIRoute(EP.admin.procedure_notes.validate), { params: { procedure_notes: payload } });
    }
  }
});