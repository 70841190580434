<template>
  <div class="row">
    <div class="col-sm-12 classic-pagination">
      <nav>
        <ul class="pagination mt-2 d-flex justify-content-end">
          <li class="page-item" :class="{ disabled: current_page <= 1 }" aria-label="First">
            <a class="page-link" href="#" @click="setPage(1)">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">First</span>
            </a>
          </li>
          <li class="page-item" :class="{ disabled: current_page <= 1 }"  aria-label="Previous">
            <a class="page-link" href="#" @click="setPage(current_page-1)">
              <span aria-hidden="true">&lt;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li v-for="page in getPageRange()" :key="page" class="page-item" :class="{ active: current_page == page }">
            <a class="page-link" href="#" @click="setPage(page)">{{page}}</a>
          </li>
          <li class="page-item" :class="{ disabled: current_page == page_count }" aria-label="Next">
            <a class="page-link" href="#" @click="setPage(current_page + 1)">
              <span aria-hidden="true">&gt;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
          <li class="page-item" :class="{ disabled: current_page == page_count }" aria-label="Last">
            <a class="page-link" href="#" @click="setPage(page_count)">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Last</span>
            </a>
          </li>
        </ul>
        <span class="d-flex justify-content-end page-count">{{`Showing page ${current_page} of ${page_count}`}}</span>
      </nav>
    </div>
  </div>
</template>

<script lang="js">

export default {
  props: {
    page_count: { default: null },
    current_page: { default: null },
  },
  data() {
    return {
      selectedStatus: null,
      selectedTeam: null,
      searchString: null,
      showDismissed: null,
      params: new URLSearchParams(window.location.search)
    }
  },
  mounted() {
    try {
      this.searchString = this.params.get('search') || null
      this.selectedStatus = this.params.get('status') || null
      this.showDismissed = this.params.get('show_dismissed') || null
      this.selectedTeam = this.params.get('team') || null
    }
    catch(error) {
      console.log(error)
    }
  },
  methods: {
    setPage(page) {
      this.$emit('updatePage', page)
    },
    getPageRange() {
      let pages = []
      if(this.current_page == 1) {
        for(let i = 0; i < this.page_count && i <=2; i++) {
          pages.push(this.current_page + i)
        }
      } 
      else if (this.current_page == this.page_count) {
        for(let i = this.page_count > 2 ? -2 : -1; i < this.page_count && i <= 0 && this.current_page + i > 0; i++) {
          pages.push(this.current_page + i)
        }
      }
      else {
        for(let i = -1; i <= 1; i++) {
          pages.push(this.current_page + i)
        }            
      }
      return pages
    },
  }
}
</script>
