<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Auto Rule Outs
    </template>
    <template #body>
      <div v-if="hasAnyExpiredCategories" class="row">
        <div class="col-sm-12">
          <div class="alert alert-warning" role="alert">
            Warning: There are Auto rule out rules with blank (expired) categories. Please review and update.
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_aro_rules_new' }" 
            class="btn btn-primary btn-sm ml-auto">
            Add New Auto rule out
          </router-link>
        </div>
      </div>

      <PortalTable ref="auto_rule_out_index"
        table-id="auto_rule_out_index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        :add-filter-to-route="true"
        filter-display="row"
        empty="No Auto rule outs found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="aro_rule_category.name" 
            header="Rule Category" 
            sort-field="aro_rule_category"
            sortable
            filter-field="rule_category_id"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter>
              <MultiSelectFilter ref="rule_category_id" 
                filter-field="rule_category_id"
                option-value="id"
                class="form-control form-control-select"
                option-label="name"
                :options="autoRuleOutStore.ruleCategories"
                @change="onChange" />
            </template>
          </Column>
          <Column field="rule_identifier" 
            header="Rule Identifier" 
            sortable
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText ref="rule_identifier" v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by Rule identifier"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="transplant_center.code" 
            sort-field="transplant_center"
            header="TxC" 
            sortable
            filter-field="tc_id"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter>
              <MultiSelectFilter ref="tc_id" 
                filter-field="tc_id"
                class="form-control form-control-select"
                cache-context-key="admin.transplant_center"
                :options="autoRuleOutStore.transplantCenters"
                @change="onChange" />
            </template>
          </Column>
          <Column field="organ_group.name" 
            header="Organ" 
            filter-field="organ_group_id"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter>
              <MultiSelectFilter ref="organ_group_id" 
                filter-field="organ_group_id"
                cache-context-key="admin.organ_group"
                class="form-control form-control-select"
                option-label="name"
                :options="autoRuleOutStore.organGroups"
                @change="onChange" />
            </template>
          </Column>
          <Column field="name" 
            header="State" 
            filter-field="state"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #body="slotProps">
              {{ slotProps.data.disabled_at ? 'Disabled' : 'Enabled' }}
            </template>
            <template #filter>
              <MultiSelectFilter ref="state" 
                filter-field="state"
                option-value="code"
                class="form-control form-control-select"
                option-label="name"
                :options="stateOptions"
                @change="onChange" />
            </template>
          </Column>
         
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_aro_rules_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { useAutoRuleOutStore } from '@/src/stores/aro-rules';
import { paginationMixin } from "@/src/mixins/paginationMixin";

export default {
  components: {
    CardSection,
    PortalTable,
    Column,
    InputText,
    MultiSelectFilter
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  beforeRouteLeave (to, from, next) {
    // Before leaving, clear the cached values
    this.$refs.tc_id.clearCacheValue();
    this.$refs.organ_group_id.clearCacheValue();

    next(); 
  },
  setup() {
    const autoRuleOutStore = useAutoRuleOutStore();
    return { autoRuleOutStore };
  },
  data() {
    return {
      filterValues: {
        "tc_id" : { value: null, matchMode: FilterMatchMode.IN },
        "rule_category_id" : { value: null, matchMode: FilterMatchMode.IN },
        "rule_identifier" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "organ_group_id" : { value: null, matchMode: FilterMatchMode.IN },
        "state" : { value: null, matchMode: FilterMatchMode.IN }
      },
      stateOptions: [
        { code: "enabled", name: "Enabled" },
        { code: "disabled", name: "Disabled" }
      ]
    }
  },
  computed: {
    hasAnyExpiredCategories() {
      return this.records == [] ? false : this.records.some(item => !item.hasOwnProperty('aro_rule_category'));
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.autoRuleOutStore.loadTransplantCentres();
      await this.autoRuleOutStore.loadRuleCategories();
      await this.autoRuleOutStore.loadOrganGroups();
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }

    // We remove this from mounted because the portal table will emit a filter changed
    // await this.getRowData();
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      params = { ...this.searchParams, ...params };
      try {
        const response = await this.autoRuleOutStore.loadAutoRuleOuts(params);
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    onChange(event) {
      this.$refs.auto_rule_out_index.resetFilters(event);
    }
  }
}
</script>
