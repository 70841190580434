<template>
  <div>
    <PortalTable ref="team_memberships_index"
      table-id="team_memberships_index"
      mode="remote"
      :table-data="usersOnTeam"
      :total-records="totalRecords"
      :page="pageNumber"
      :page-count="pageCount"
      :loading="loading"
      empty="No assigned User Membership for the Team!"
      filter-display="row"
      :filter-values="filterValues"
      css-class="mb-3"
      edit-mode="row"
      @on-filter-change="onFilterChange"
      @on-page="onPageChange"
      @on-row-edit-save="onRowEditSave"
      @on-row-edit-init="onRowEditInit">
      <template #headers>
        <div class="card-header media" style="display: flex; align-items: center">
          <div class="media-left">
            <div>
              <h4 class="card-title d-flex">
                Team Access
              </h4>
            </div>
          </div>
        </div>
      </template>
      <template #columns>
        <Column field="user.full_name"
          header="User Name"
          filter-field="full_name"
          :show-filter-menu="false"
          :filter-menu-style="{ width: '14rem' }"
          style="min-width: 14rem">
          <template #body="slotProps">
            <router-link :to="{ name: 'admin_users_edit_teams', params: { id: slotProps.data.user.id } }">
              {{ slotProps.data.user.full_name }}
            </router-link>
          </template>
          <template #filter="{ filterModel = { value: null }, filterCallback }">
            <InputText v-model="filterModel.value"
              type="text"
              class="p-column-filter form-control"
              placeholder="Search by User Name"
              @input="filterCallback()" />
          </template>
        </Column>
        <Column field="user.email"
          header="Email"
          filter-field="email"
          :show-filter-menu="false"
          :filter-menu-style="{ width: '14rem' }"
          style="min-width: 14rem">
          <template #filter="{ filterModel = { value: null }, filterCallback }">
            <InputText v-model="filterModel.value"
              type="text"
              class="p-column-filter form-control"
              placeholder="Search by Email"
              @input="filterCallback()" />
          </template>
        </Column>
        <Column field="role.role_name"
          header="Role"
          filter-field="role"
          :show-filter-menu="false"
          :filter-menu-style="{ width: '14rem' }"
          style="min-width: 14rem">
          <template #filter>
            <MultiSelectFilter ref="role_filter"
              filter-field="role"
              option-value="id"
              class="form-control form-control-select"
              option-label="role_name"
              :options="roleStore.roles"
              @change="onChangeRole" />
          </template>
          <template #editor="{ data }">
            <!-- This slotProps.data.id will map to the unique team membership id. We can rely on this to keep each form separate.-->
            <MultiSelectInput v-model="editRowData.roleId[data.id]"
              name="role"
              label="Role"
              :options="roleStore.roles"
              label-key="role_name"
              value-key="id"
              rules="required"
              placeholder="Select a Role" />
          </template>
        </Column>
        <Column :row-editor="true" style="width: 10%; min-width: 8rem" body-style="text-align:center" />
        <Column header="Actions">
          <template #body="slotProps">
            <ActionToolbar :component-state="editRowData.currentState[slotProps.data.id]" 
              :primary-button-text="`Delete Membership`"
              primary-style="danger"
              :success-message="editRowData.message[slotProps.data.id]"
              :error-message="editRowData.message[slotProps.data.id]"
              toolbar-size="col-md-12"
              :secondary-enabled="false"
              @primary-click="deleteMembership(slotProps.data)" />
          </template>
        </Column>
      </template>
    </PortalTable>
  </div>
</template>
<script>
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import DismissableMessage from "@/src/components/shared/DismissableMessage.vue";
import { useRoleStore } from '@/src/stores/role';
import { useUserStore } from '@/src/stores/user';
import { useTeamStore } from '@/src/stores/team';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";

export default {
    components: {
        PortalTable,
        Column,
        MultiSelectFilter,
        MultiSelectInput,
        InputText,
        ActionToolbar
    },
    mixins: [savableStateMixin],
    props: {
        usersOnTeam: { required: true, type: Array },
        selectedTeam: { required: true, type: Object },
        loading: { required: true, type: Boolean },
        totalRecords: { required: true, type: Number },
        pageNumber: { required: true, type: Number },
        pageCount: { required: true, type: Number }
    },
    emits: ['membershipsUpdated', 'filtersUpdated', 'handlePageChange'],
    setup() {
        const roleStore = useRoleStore();
        const userStore = useUserStore();
        const teamStore = useTeamStore();
        return { roleStore, userStore, teamStore }
    },
    data() {
        return {
            editRowData: {
                roleId: {},
                message: {},
                currentState: {}
            },
            selectedRows: [],
            filterValues: {
                "full_name" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                "email" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                "role" : { value: null, matchMode: FilterMatchMode.IN },
            },
        }
    },
    methods: {
        onFilterChange(event) {
          if(event["role"] === "") this.$refs.role_filter.clearFilters();
          this.$emit('filtersUpdated', event);
          },
        onChangeRole(event) {
          this.$refs.team_memberships_index.resetFilters(event);
        },
        selectMembershipRows(data) {
          this.selectedRows = data;
        },
        onPageChange(event) {
          this.$emit('handlePageChange', event);
        },
        /**
         * This handles the save even as it comes from the table, we localize the messaging and state to the 
         * editRowData for that one row based on the Team Membership ID that comes from event.data.id
         */
        async onRowEditSave(event) {
            try {
                this.editRowData.currentState[event.data.id] = this.SaveStates.Processing;
                this.editRowData.message[event.data.id] = 'Saving...';

                // This sets the payload to null
                const payload = this.editRowData.roleId[event.data.id] ? { role_id: this.editRowData.roleId[event.data.id] } : {};
                await this.userStore.updateMembership(event.data.id, event.data.user_id, payload); 
                
                this.editRowData.roleId[event.data.id] = null;
                this.editRowData.currentState[event.data.id] = this.SaveStates.Success;
                this.editRowData.message[event.data.id] = 'Role successfully Updated!';
                this.$emit('membershipsUpdated');
            } catch(error) {
                this.editRowData.currentState[event.data.id] = this.SaveStates.Errored;
                this.editRowData.message[event.data.id] = this.convertSaveErrorMessage(error);
            };
        },
        onRowEditInit(event) {
          this.editRowData.currentState[event.data.id] = this.SaveStates.Blank;
        },
        async deleteMembership(selectedRow) {
            if(!confirm('Are you sure you want to delete this user membership?')) {
                return;
            }

            try {
                this.editRowData.currentState[selectedRow.id] = this.SaveStates.Processing;
                await this.teamStore.deleteMembership(this.selectedTeam.id, [selectedRow]);
                this.editRowData.currentState[selectedRow.id] = this.SaveStates.Success;
                this.$emit('membershipsUpdated');
            } catch(error) {
                this.editRowData.currentState[selectedRow.id] = this.SaveStates.Errored;
                this.editRowData.message[selectedRow.id] = this.convertSaveErrorMessage(error);
            }
        },
        convertSaveErrorMessage(error) {
            return error === 'Missing parameters.' ? 'Role is required.' : error;
        }
    }
}
</script>