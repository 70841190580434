<template v-if="user">
  <div v-if="isOnCall" class="row">
    <div class="col-sm-12">
      <div class="alert alert-success" role="alert">
        <span>You are currently on call.</span>
      </div>
      <div v-if="showGroupWarning" class="alert alert-warning" role="alert">
        <span> Warning! You are on-call but not assigned to any groups.</span>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <div class="col-sm-12">
      <div class="alert alert-danger" role="alert">
        <span>You are currently off call.</span>
      </div>
    </div>
  </div>

  <ActionToolbar :secondary-enabled="false" :component-state="currentState" :error-message="formError"
    success-message="Saved Successfully!"
    :primary-button-text="isOnCall ? 'Take off call' : 'Put on call'"
    :primary-style="isOnCall ? 'danger' : 'success'"
    :primary-button-icon="isOnCall ? ['far', 'calendar-xmark'] : ['far', 'calendar-check'] "
    @primary-click.prevent="enableOrDisableCall()" />
</template>

<script>
import { useUserStore } from '@/src/stores/user';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useAppCoreStore } from "@/src/stores/app-core";

export default {
  components: {
    ActionToolbar
  },
  mixins: [ savableStateMixin ],
  props: {
    user: { required: true, type: Object },
    loading: { type: Boolean, required: false, default: false },
    showGroupWarning: { type: Boolean, required: false, default: false }
  },
emits: ['reload'],
  setup() {
    const userStore = useUserStore();
    const appStore = useAppCoreStore();    
    return {
      userStore,
      appStore
    }
  },
  computed: {
    isOnCall() {
      return this.currentUser.isUserOnCall();
    },
    currentUser() {
      return this.appStore.currentUser;
    }
  },
  methods: {
    async enableOrDisableCall() {
      try {
        this.setSavableStateProcessing();
        this.isOnCall ? await this.userStore.takeOffCall() : await this.userStore.putOnCall();
        this.$emit('reload');
        setTimeout(() => {
          this.setSavableStateSuccess();
        }, 200);
      } catch(error) {
        this.handleFormError(error);
      }
    }
  }
}
</script>
