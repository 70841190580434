<template>
  <LeftNav :enabled-nav="enabledPluginNavs" />
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";
import LeftNav from "@/src/components/shared/LeftNav.vue";

export default {
  components: {
    LeftNav
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    enabledPluginNavs() {
      const params = this.$route.params;
      let subSections = [];

      subSections = [
        {
          name: 'Comms Templates',
          url_path: 'generate-instance',
          permission_key: PermissionNames.INTERNAL_MATCH_COMMUNICATION_TEMPLATES,
          enabled: false,
          params: {
            donor: params.donor,
            match: params.match,
            txc: params.txc,
            organGroup: params.organGroup,
          }
        }
      ]

      const PluginAreaNavs = [
        {
          name: 'Match Links',
          sub_sections: subSections,
          enabled: false
        },
      ];

      if(!this.user || !this.userPermissions) return [];
       return PluginAreaNavs.map((nav_item) => {
        nav_item.sub_sections.map((sub) =>{
          const nav_perm = this.userPermissions[sub.permission_key];
          sub.enabled = nav_perm ? (nav_perm.read || false) : false;
          nav_item.enabled = nav_item.enabled || sub.enabled ? true : false;
          return sub;
        })
        return nav_item;
      })
    },
  }
}
</script>
