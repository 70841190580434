<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      Integrations - OmniLife - Manage User Mapping
    </template>
    <template #body>
      <PortalTable ref="workflow_mapping_index" table-id="omni-life-workflow-mapping-index"
        :table-data="records"
        :loading="isSavableStateProcessing"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        edit-mode="row"
        mode="local"
        @on-sort="handleSortChange"
        @on-page="handlePageChange">
        <template #columns>
          <Column header="User email" field="user.email" />
          <Column header="OmniLife User Id" field="omnilife_user_id" />
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { useIntegrationsOmnilifeStore } from "@/src/stores/integrations/omnilife.js" 

export default {
  components: {
    CardSection,
    PortalTable,
    Column
  },
  mixins: [savableStateMixin, paginationMixin],
  setup() {
    const omniLifeStore = useIntegrationsOmnilifeStore();

    return { omniLifeStore };
  },
  data() {
    return {
    }
  },
  async mounted() {
    try {
      this.getRowData(true);
    } catch(error) {
      this.handleMajorError(error);
    }
  },

  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      params = { ...this.search_params, ...params };

      try {
        const response = await this.omniLifeStore.loadUserMappings();
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.majorError = error;
        this.setSavableStateErrored();  
      }
    },
  }
}
</script>