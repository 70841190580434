<template>
  <div v-if="!isSavableStateLoading" class="row">
    <div class="col sub-section-header">
      <nav class="navbar navbar-expand m-0 p-0 bg-body-tertiary">
        <h5 class="legend-title card-title">
          Procedure Notes
        </h5>
        <ul v-if="canEdit" class="navbar-nav ml-auto">
          <li class="nav-item">
            <a href="#" data-toggle="collapse"
              data-target="#content-coordinators-aro-rules" class="nav-link card-header-btn py-0" aria-expanded="true"
              style="display: flex; align-items: center" @click="toggleCollapse">
              <span style="padding-right: 0.5rem">Edit</span>
              <font-awesome-icon :icon="['fas', (currentMode === Mode.EDIT ? 'chevron-up' : 'chevron-down')]" />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <template v-if="procedureNote">
        <p class="preview-template" style="color: red;">
          {{ procedureNote }}
        </p>
      </template>
      <template v-else>
        <div class="alert alert-light" role="alert">
          No Procedure Notes have been created for this type of offer.
        </div>
      </template>
    </div>
  </div>
  <h5 class="legend-title card-title">
    Rule Out Policies
  </h5>
  <hr>
  <div v-if="!ruleExists" class="row">
    <div class="col-sm-12">
      <div class="alert alert-light" role="alert">
        No Auto rule out criteria have been created for this type of offer.
      </div>
    </div>
  </div>
  <VeeForm v-else @submit="onSubmit">
    <PortalTable :ref="`auto_rule_out_index-${match_id}`"
      :table-id="`auto_rule_out_index-${match_id}`"
      :table-data="tableData"
      :loading="isSavableStateLoading"
      sort-field="aro_rule.rule_identifier"
      :sort-order="1"
      mode="local"
      edit-mode="cell"
      empty="No Auto rule outs were saved as ‘Yes’. ">
      <template #columns>
        <Column field="aro_rule.rule_description" 
          header="Rule">
          <template #body="slotProps">
            <span class="preserve-spacing">
              {{ slotProps.data.aro_rule.rule_description }}
            </span>
          </template>
        </Column>
        <Column field="data_reference_parsed" 
          header="Variable Reference">
          <template #body="slotProps">
            <span class="preserve-spacing">
              {{ slotProps.data.data_reference_parsed }}
            </span>
          </template>
        </Column>
        <Column field="aro_rule.data_source" 
          header="Data Source" />
        <Column field="aro_rule.organ_group.name" 
          header="Decline Codes">
          <template #body="slotProps">
            <template v-if="slotProps.data.decline_codes[0]">
              {{ slotProps.data.decline_codes[0] }}
              <template v-if="slotProps.data.unos_comment_one || slotProps.data.aro_rule.unos_comment_one">
                <span class="preserve-spacing">- {{ slotProps.data.unos_comment_one || slotProps.data.aro_rule.unos_comment_one }} </span>
              </template>
            </template>
            <template v-if="slotProps.data.decline_codes[1]">
              <div style="padding: 10px;" />
              {{ slotProps.data.decline_codes[1] }}
              <template v-if="slotProps.data.unos_comment_two || slotProps.data.aro_rule.unos_comment_two">
                <span class="preserve-spacing">- {{ slotProps.data.unos_comment_two || slotProps.data.aro_rule.unos_comment_two }} </span>
              </template>
            </template>
          </template>
        </Column>
        <Column field="verification_status"
          header="Confirmation">
          <template #body="slotProps">
            <template v-if="currentMode == Mode.EDIT">
              <SingleSelect v-model="slotProps.data.verification_status"
                name="verified"
                :options="autoRuleOutStore.getVerificationTypes"
                label-key="label"
                value-key="name"
                placeholder="Select.." />
            </template>
            <template v-else>
              <font-awesome-icon :icon="['far', 'circle-check']" style="color: #1bd124;" /> Yes
            </template>
          </template>
        </Column>
      </template>
    </PortalTable>
    <div v-if="currentMode == Mode.VIEW">
      <span v-if="verifeidAROExists"><font-awesome-icon :icon="['far', 'square-check']" />
        Saved: {{ formatedLastUpdatedAt }}</span>
    </div>
    <!-- save controls -->
    <ActionToolbar v-else :component-state="currentState" 
      toolbar-size="col-md-12"
      primary-button-text="Save Rule Selections"
      secondary-button-text="Clear Selections" 
      tertiary-button-text="No to remaining"
      :tertiary-enabled="true"
      :error-message="formError"
      :primary-disabled="disableSave"
      :secondary-disabled="disableReset"
      :tertiary-disabled="disableReset"
      @secondary-click="onReset"
      @tertiary-click="setNoToRemaining" />
  </VeeForm>
</template>

<script>
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { useAutoRuleOutStore } from '@/src/stores/aro-rules';
import { Form as VeeForm } from 'vee-validate';
import SingleSelect from "@/src/components/shared/FormComponents/SingleSelect.vue";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import { formatDateTimeTotoLocaleString } from "@/src/date-helpers";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";

const Mode = {
  VIEW: 'view',
  EDIT: 'edit',
}

export default {
  components: {
    PortalTable,
    Column,
    VeeForm,
    SingleSelect,
    ActionToolbar
  },
  mixins: [ savableStateMixin, paginationMixin ],
  props: {
    match_id: { required: true, type: String }
  },
emits: ['reset'],
  setup() {
    const autoRuleOutStore = useAutoRuleOutStore();
    return {
      autoRuleOutStore
    }
  },
  data() {
    return {
      Mode,
      procedureNote: null,
      records: [],
      verifiedAROs: [],
      lastUpdatedAt: null,
      currentMode: null
    }
  },
  computed: {
    canEdit() {
      if(this.records && this.records.length == 0) return false;

      return this.lastUpdatedAt != null ? true : false;
    },
    ruleExists() {
      return this.records && this.records.length > 0 ? true : false;
    },
    verifeidAROExists() {
      return this.verifiedAROs && this.verifiedAROs.length > 0 ? true : false;
    },
    disableSave() {
      if(this.records  == null || this.records && this.records.length === 0) return true;

      const records = this.records.some(record => {
        return record.verification_status == null
      });
      return records ? true : false;
    },
    disableReset() {
      if(this.records && this.records.length === 0) return true;
      return false;
    },
    formatedLastUpdatedAt() {
      return this.lastUpdatedAt ? formatDateTimeTotoLocaleString(this.lastUpdatedAt) : null;
    },
    tableData(){
      if(this.currentMode == Mode.EDIT) {
        return this.records;
      } else if(this.currentMode == Mode.VIEW){
        return this.verifiedAROs;
      } 
      return [];
    }
  },
  watch: {
    'match_id': {
      handler: async function(value) {
        this.records = null;
        this.lastUpdatedAt = null;
        this.currentMode = null;
        if(value) await this.loadData();
      }
    },
  },
  async mounted() {
    await this.loadData();
    if(this.lastUpdatedAt != null) {
      this.currentMode = Mode.VIEW 
    } else {
      this.currentMode = Mode.EDIT;
    }
  },
  methods: {
    async loadData() {
      if(!this.match_id) return null; 
      try {
      this.setSavableStateLoading();
        const procedureNoteResponse = await this.autoRuleOutStore.loadProcedureNote(this.match_id);
        this.procedureNote = procedureNoteResponse ? procedureNoteResponse.note : null;
        // Get records from match applicable aro rules
        const response = await this.autoRuleOutStore.loadApplicableAutoRulesForMatch(this.match_id);
        this.formatData(response.data);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async onSubmit() {
      this.setSavableStateProcessing();
      try {
        let payload = [];

        this.records.forEach((record) => {
          payload.push({
            id: record.id || null,
            aro_rule_id: record.aro_rule.id,
            verification_status: record.verification_status,
            data_reference_parsed: record.data_reference_parsed
          });
        });
        
        const response = await this.autoRuleOutStore.updateApplicableAutoRulesForMatch(this.match_id,payload);
        this.formatData(response.data);
        this.$emit("reset");
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async onReset() {
      this.setSavableStateProcessing();
      if(!confirm('Resetting will discard any of your saved changes. Are you sure you want to continue?')) {
        this.setSavableStateIdle();
        return;
      }

      try {
        // reset aro rules
        this.currentMode = null;
        const response = await this.autoRuleOutStore.resetApplicableAutoRulesForMatch(this.match_id);
        this.formatData(response.data);
        this.currentMode = Mode.EDIT;
        this.setSavableStateSuccess();
        this.$emit("reset");
      } catch (error) {
        this.handleFormError(error);
      }
    },
    async setNoToRemaining() {
      this.records.forEach((record) => {
        record.verification_status = record.verification_status ? record.verification_status : 'No'
      });
    },
    async toggleCollapse() {
      this.currentMode = this.currentMode === Mode.EDIT ? Mode.VIEW : Mode.EDIT;
    },
    formatData(response) {
      this.records = response.applicable_rules;
      this.lastUpdatedAt = response.applicable_rules_last_saved_at;
      this.verifiedAROs = this.records && this.records.length > 0 ? this.records.filter(record => {
        return record.verification_status =='Yes'
      }) : [];
    },
  }
}
</script>

<style>
.preserve-spacing {
  white-space: pre-wrap
}
</style>