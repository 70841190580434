// Setup Axios
import axios from 'axios';

const beApiClient = axios.create({
  baseURL: '/api/v1'
});

beApiClient.interceptors.request.use(function (config) {
  const meta = document.querySelector('meta[name=csrf-token]');
  config.headers['X-CSRF-Token'] =  meta.attributes['content'] ? meta.attributes['content'].value : null;

  return {
    ...config,
    validateStatus: function (status) {
      return (status >= 200 && status < 300 || status === 422);
    }
  };
});

beApiClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // If the response is unauthorized most likely the user has timed out
  // Or signed out. So we just refresh and let Rails handle it
  if(error.response.status === 401) {
    window.location.reload();
  }
  return Promise.reject(error);
});

export default beApiClient;
