/**
 * This mixin provides standard functions to handle data sent through pagy for paginated pages in the
 * application. 
 * 
 * Expectations:
 * - Expects a getRowData() method defined in the component that will take care of actually going and getting
 *  the data from the server
 * 
 * It injects the following into the component:
 * Data Properties:
 *  - searchParams: Search parameters to send to api, default {}
 *  - records: Array to store the records to display in the current table, default []
 *  - totalRecords: Total number of records for current response, default 0
 *  - pageSize: Number of records to display on the page, default 20
 *  - pageNumber: The page number to display, default 1
 *  - pageCount: Total page count, default null
 * 
 * Methods:
 *  - handlePageChange: 
 *  - processPagyResponse: For a given pagy response set the appropriate component properties
 *  - onFilterChange: 
 */
import { FilterMatchMode } from 'primevue/api';

export const paginationMixin = {
  data() {
    return {
      records: [],
      totalRecords: 0,
      pageSize: 20,
      pageNumber: 1,
      pageCount: null,
      currentSortField: '',
      currentSortType: '',
      searchParams: {}
    }
  },
  methods: {
    // sets the page number and reload the data
    handlePageChange(page) {
      this.pageNumber = page;
      this.getRowData();
    },
    handleSortChange(params) {
      this.currentSortField = params.sortField;
      switch(params.sortOrder) {
        case 1:
          this.currentSortType = 'asc';
          break;
        case -1:
          this.currentSortType = 'desc';
          break;
        default:
          this.currentSortType = null;
      }
      
      this.getRowData();
    },
    processPagyResponse(response) {
      this.records = response.data;
      if(response.pagy) {
        this.totalRecords = response.pagy.count;
        this.pageCount = response.pagy.pages;
        this.pageNumber = response.pagy.page;
      }
    },
    /**
     * Sets search params and reload the table, also set/clears multiselect filter on the table.
     * 
     * @param {Array} event [key: [matchMode, value]] 
     *  key - multiselect-filter: filter-field
     *  matchMode - FilterMatchMode used to define the filter  mode types like "in" | "endsWith" | "startsWith" | "contains" etc
     *  value - selected value 
     */
    onFilterChange(event) {
      // Converts the incoming array to key value pairs 
      let filters = {};
      if (event && event.length > 0) {
        event.map((item, index) => {
          const filter = event[index];
          filters[filter[0]] = filter[1].value ? filter[1].value : '';
        });
      }
      // assign to the search params to send to the api
      this.searchParams = filters;

      if(event) {
        event.map((filter) => {
          const filterValue = filter[1].value;
          if(this.filterValues[filter[0]].matchMode == FilterMatchMode.IN) {
            if(filterValue) {
              // This will set the value in multiselect filter in the portal table
              this.$refs[filter[0]].setFilters(filter[1].value);
            } else {
              // clears the value from the multiselect filter in the portal table
              this.$refs[filter[0]].clearFilters();
              this.$refs[filter[0]].clearCacheValue();
            }
          }
        });
     }
      // Load the data based on the search params
      this.getRowData(true);
    }
  }
}