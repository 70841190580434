<template class="page-content">
  <!-- Modal -->
  <ModalLayout :ref="modalRef"
    modal-id="declineModal"
    :loading="isSavableStateLoading"
    @close="modalClosed()">
    <template #modal-header>
      <h5 v-if="selectedMatch && selectedMatch.transplant_center" class="modal-title">
        {{ `Donor: ${selectedMatch.donor.code} | Match: ${selectedMatch.code}-${selectedMatch.organ.name} | TC: ${selectedMatch.transplant_center.name}
                     | Offer Date: ${selectedMatch.offer_date}` }}
      </h5>
    </template>
    <template #modal-body>
      <aro-rules v-if="appStore.currentUser.hasPermission('internal_match_aro_rules')" :match_id="matchId" @reset="reset" />
      
      <hr>
      <div class="row">
        <div class="col-sm-12 mb-2">
          <h3 class="legend-title card-title">
            Match and Offer Details
          </h3>
        </div>
      </div>
      <div v-if="selectedOffers" class="row">
        <div class="col-sm-12 mb-2">
          <PortalTable :table-id="`coordinator-decline-table-${match_id}`"
            :table-data="selectedOffers"
            :total-records="selectedOffers.count"
            :loading="isSavableStateProcessing"
            selection-mode="single"
            mode="local"
            @table-row-click="populateFields">
            <template #columns>
              <Column field="seq" header="Seq." style="min-width: 100px;">
                <template #body="slotProps">
                  {{ slotProps.data.seq ? slotProps.data.seq : 'Match' }}
                </template>
              </Column>
              <Column field="recipient_last_name" header="Last Name" style="min-width: 100px;" />
              <Column field="decline_code_1" header="Decline&nbsp;Code&nbsp;1" style="min-width: 100px;" />
              <Column field="decline_code_2" header="Decline&nbsp;Code&nbsp;2" style="min-width: 100px;" />
              <Column field="physician" header="Physician" style="min-width: 100px;" />
              <Column field="decline_note" header="Decline Comment" style="min-width: 100px;" />
              <Column field="auto_rule_out" header="Auto Rule Out" style="min-width: 100px;">
                <template #body="slotProps">
                  <font-awesome-icon v-if="slotProps.data.auto_rule_out" :icon="['fas', 'fa-check']" />
                </template>              
              </Column>
            </template>
          </PortalTable>
        </div>
      </div>
      <form :ref="`declineModalForm`" @submit="saveOfferDetails">
        <fieldset>
          <div class="row">
            <div class="form-group-standard-column">
              <label for="match">Match:</label>
              <input id="match" type="text" readonly="readonly" class="form-control" :value="currentSeq">
            </div>
            <div class="form-group-standard-column">
              <label for="physician">Physician:</label>
              <input id="physician" v-model="currentPhysician"
                :disabled="disableInputs" title="Please select the Offer or Match to add details." type="text"
                class="form-control">
            </div>
            <div class="form-group-standard-column">
              <p class="p-label">
                Auto rule out
              </p>
              <div class="form-check form-check-inline">
                <input id="auto_rule_out"
                  v-model="autoRuleOut"
                  :disabled="disableInputs"
                  aria-labelledby="auto_rule_out"
                  type="checkbox"
                  class="form-check-input" @change="setPhysician()">
                <label for="auto_rule_out" class="form-check-label">
                  <span>Yes</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <TextArea v-model="currentDeclineNote"
              :disabled="disableInputs" title="Please select the Offer or Match to add details."
              name="currentDeclineNote"
              label="Decline Comments:"
              rows="4"
              cols="50" 
              col-style="col-12" />
          </div>
          <!-- save controls -->
          <ActionToolbar :component-state="currentState" 
            toolbar-size="col-md-12"
            :primary-disabled="disableInputs || isSavableStateLoading"
            :primary-button-text="Save"
            :secondary-enabled="false"
            :tertiary-enabled="true"
            :tertiary-disabled="disableInputs || disableFillIn"
            :tertiary-button-text="`Auto-fill Decline Comments`"
            :tertiary-button-icon="['fas', 'paint-roller']"
            :error-message="formError" 
            @tertiary-click="copyDeclineComments()" />
        </fieldset>
      </form>
    </template>
  </ModalLayout>
</template>

<script lang="js">
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import ARORules from "@/src/components/buckeye-coordinator-area/match/ARORules.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import { useAutoRuleOutStore } from '@/src/stores/aro-rules';
import { useMatchStore } from '@/src/stores/match';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import {PermissionNames} from "@/src/enums";
import TextArea from "@/src/components/shared/FormComponents/TextArea.vue";

export default {
  components: {
    PortalTable,
    ModalLayout,
    ActionToolbar,
    TextArea,
    Column,
    'aro-rules': ARORules
  },
  mixins: [savableStateMixin, modalActionsMixin],
  props: {
    matchId: { required: true, type: String }
  },
emits: ['close'],
  setup() {
    const appStore = useAppCoreStore();
    const autoRuleOutStore = useAutoRuleOutStore();
    const matchStore = useMatchStore();

    return {
      appStore, autoRuleOutStore, matchStore
    }
  },
  data() {
    return {
      modalRef: `decline-modal`,
      selectedOffers: null,
      currentPhysician: '',
      currentDeclineNote: '',
      autoRuleOut: false,
      currentSeq: '',
      currentId: null,
      ruleDescriptions: []
    }
  },
  computed: {
    disableInputs() {
      return this.currentId == null ? true : false;
    },
    user() {
      return this.appStore.currentUser;
    },
    disableFillIn() {
      if(this.ruleDescriptions && this.ruleDescriptions.length > 0) return false;
      return true;
    }
  },
  watch: {
    async matchId() {
      await this.getModalData();
      this.disableSaving = false;
    }
  },
  async mounted() {   
    this.selectedMatch = null;
    await this.getModalData();
  },
  methods: {
    resetDeclineModalForm() {
      this.selectedOffers = null;
      this.currentPhysician = '';
      this.currentDeclineNote = '';
      this.autoRuleOut = false;
      this.currentSeq = '';
    },
    async getModalData() {
      if(!this.matchId) return;
      this.resetDeclineModalForm();

      try {
        this.setSavableStateLoading();
        const response = await this.matchStore.loadMatch(this.matchId);
        // get offers
        let offers = [response];
        offers = offers.concat(response.offers_ordered_by_seq.filter((item) => {
          return item.decline_code_1 !== 0 || item.decline_code_2 !== 0
        }));
        // update data
        this.selectedMatch = response;
        this.selectedOffers = offers;
        // finish
        this.setSavableStateIdle();
      } catch (error) {
        this.handleFormError(error);
      }
    },
    modalClosed() {
      this.setSavableStateBlank();
      this.currentId = null;
      this.$emit('close')
    },
    // Set Physician value to 'Auto rule out' on auto rule out flag checked and no physician is specified
    // On uncheck, if physician value is 'Auto rule out', set physician to empty
    setPhysician() {
      if(this.autoRuleOut && !this.currentPhysician) {
        this.currentPhysician = 'Auto rule out';
      } else {
        if(this.currentPhysician === 'Auto rule out') this.currentPhysician = '';
      }
    },
    async populateFields(rowData) {
      let row = rowData;
      this.currentId = row.id;
      this.currentPhysician = row.physician;
      this.currentSeq = row.seq ? row.seq : 'Match';
      this.currentDeclineNote = row.decline_note;
      this.autoRuleOut = row.auto_rule_out;

      if(this.appStore.currentUser.hasPermission(PermissionNames.INTERNAL_MATCH_ARO_RULES)) await this.getRuleDescriptions();
    },
    async saveOfferDetails(e) {
      e.preventDefault()
      if(this.currentId == null) return true;

      const payload = {
        physician: this.autoRuleOut && !this.currentPhysician ? 'Auto rule out' : this.currentPhysician,
        decline_note: this.currentDeclineNote,
        auto_rule_out: this.autoRuleOut
      }

      try {
        this.setSavableStateProcessing();
        // make request
        this.currentSeq === 'Match' ? await this.matchStore.updateMatch(this.currentId, payload) : await this.matchStore.updateOffer(this.currentId, payload) ;
        
        // refresh ui
        await this.getModalData();
        this.setSavableStateSuccess();
        this.currentId = null;
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async copyDeclineComments() {  
      await this.getRuleDescriptions();
      if(this.ruleDescriptions.length > 0) {
        this.currentDeclineNote = this.ruleDescriptions.join('; ');
        this.currentPhysician = 'Auto rule out';
        this.autoRuleOut = true;
      } else {
        this.currentPhysician = '';
        this.autoRuleOut = false;
      }
    },
    async getRuleDescriptions() {
      this.ruleDescriptions = [];  
      const response = await this.autoRuleOutStore.loadApplicableAutoRulesForMatch(this.matchId);
      if(response.data.applicable_rules) {
          response.data.applicable_rules.forEach(item =>{
          if(item.verification_status === 'Yes') this.ruleDescriptions.push(item.aro_rule.rule_description + (item.data_reference_parsed ? " (" + item.data_reference_parsed + ")" : '' ));
        })
      }
    },
    reset() {
      this.ruleDescriptions = [];
      this.currentPhysician = '';
      this.currentDeclineNote = '';
      this.autoRuleOut = false;
      this.currentSeq = '';
      this.currentId = null;
    }
  }
}
</script>
