<template>
  <div :class="colStyle">
    <Field
      v-model="getValue"
      v-slot="{ errors, field }" 
      :name="name"
      :rules="rules">
      <label :for="name">{{label}} <span v-if="isRequired" class="text-danger">*</span></label>
      <VueDatePicker
        :name="name" 
        v-model="vModel"
        month-picker
        :auto-apply="true"
        @update:modelValue="onUpdate"
        :placeholder="placeholder"
        :class="{
          'is-invalid': !disabled && hasErrors(errors), 
          }"
      />
      <InvalidFeedback :name="getErrorName" :errors="errors"/>
    </Field>
  </div>
</template>

<script>
import { useForm, Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import InvalidFeedback from "@/src/components/shared/InvalidFeedback.vue";
import { formMixin } from "@/src/mixins/formMixin.js";
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  components: {
    InvalidFeedback,
    VeeForm,
    Field,
    ErrorMessage,
    VueDatePicker
  },
  props: {
    colStyle: { default: 'form-group-standard-6-column-medium-up' },
    placeholder: { default: null },
  },
  emits: ['update:modelValue'],
  mixins: [ formMixin ],
  data() {
    return {
      vModel: null
    }
  },
  watch: {
    'modelValue': {
      handler: function(value) {
        this.vModel = value;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onUpdate(value) {
      this.$emit('update:modelValue', value)
    },
  }
}
</script>

