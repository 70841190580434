<template>
  <div :class="colStyle">
    <Field v-slot="{ errors, field }"
      v-model="getValue" 
      :name="name"
      :rules="rules">
      <label v-if="!hideLabel" :for="name">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
      <MultiSelect v-bind="field"
        v-model="vModel"
        :option-value="valueKey"
        :option-label="labelKey" 
        :option-disabled="optionDisableKey"
        :placeholder="placeholder" 
        class="form-control form-control-with-multiselect"
        :loading="loading"
        :display="multiple ? 'chip' : 'comma'"
        :max-selected-labels="multiple ? null : 1"
        :selection-limit="multiple ? null : 1"
        :options="options"
        :disabled="disabled"
        :show-toggle-all="showToggleAll" 
        :filter="filter"
        @update:model-value="onUpdate"
        @change="onChange">
        <template v-if="optionDisableKey" #removetokenicon="slotProps">
          <span v-if="isDisabled(slotProps.item)" />
        </template>
      </MultiSelect>
      <InvalidFeedback :name="getErrorName" :errors="errors" />
    </Field>
  </div>
</template>

<script>
import { useForm, Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import InvalidFeedback from "@/src/components/shared/InvalidFeedback.vue";
import MultiSelect from 'primevue/multiselect';
import { formMixin } from "@/src/mixins/formMixin.js";

export default {
  components: {
    MultiSelect,
    InvalidFeedback,
    Field
  },
  mixins: [ formMixin ],
  props: {
    modelValue: { default: null, type: Object },
    colStyle: { default: 'form-group-standard-6-column-medium-up', type: String },
    options: { default: (() => []), type: Array },
    labelKey: { default: 'value', type: String },
    valueKey: { default: 'code', type: String },
    optionDisableKey: { default: null,type: String },
    placeholder: { default: null, type: String },
    multiple: { default: false, type: Boolean },
    loading: { default: false, type: Boolean },
    hideLabel: { default: false, type: Boolean },
    showToggleAll: { default: true, type: Boolean },
    cacheContextKey: { required: false, default: null, type: String},
    filter: { default: true, type: Boolean }
  },
  emits: ['update:modelValue', 'change'], // explicitly declare emit events used
  data() {
    return {
      vModel: null
    }
  },
  watch: {
    // We need to watch for the options changing to handle the cacheContext. In some cases the options are
    // not available on mounted so we check once the options become available if we should be loading from the cache
    // The conditions are:
    // 0. The modelValue was not supplied from the calling component was supplied
    // 1. We went from 0 to more options
    // 2. We have a cacheContextKey
    // 3. The key actually matches one of the options.
    // if we do then we can safely set the value
    'options': {
      handler: function() {
        this.setValueFromCache();
      }
    },
    // used when receiving a value from parent
    'modelValue': {
      handler: function(value) {
        let newValue = value;
        if (value && this.options.length > 0) {
          // Note: primevue will show 'null' if the option doesn't exist
          // So we need to check the values given against the available options.
          // Below we may be given a string or array so prepare value before testing so can check both
          const checkAgainst = Array.isArray(newValue) ? newValue : [newValue];
          const found = this.options.find((item) => { return checkAgainst.includes(item[this.valueKey]); });
          // if not found, clear the value
          if (!found) { newValue = null; }
        }

        // if we receive a value, update our local copy
        if (newValue) {
          // primevue multi-select only works with arrays, strings will error it
          // if we have a new value;
          // if it's multi-select, store it without conversion (it'll be an array)
          // if it's single-select, convert it to an array
          this.vModel = Array.isArray(newValue) ? newValue : [newValue];
          this.cacheSelection();
        } else {
          // if it's blank, set our internal copy to an empty array, as primevue prefers arrays
          this.vModel = [];
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.setValueFromCache();
  },
  methods: {
    formatValue(value) {
      // primevue only works with arrays but externally if single-select calling components will expect a string,
      // otherwise return an array
      return this.multiple ? value : value.toString();
    },
    onUpdate(value) {
      if (value.length === 0) {
        this.$emit('update:modelValue', null)
      } else {
        this.$emit('update:modelValue', this.formatValue(value))
      }
    },
    onChange(event) {
      if (event && event.value.length > 0) {
        this.$emit('change', this.formatValue(event.value)); // if selected value, emit
      }
    },
    isDisabled(item) {
      if(!this.optionDisableKey) return false;

      const option = this.options.find((option) => option.id == item);
      return option && this.optionDisableKey ? option[this.optionDisableKey] : false;
    },
    cacheSelection() {
      if(!this.cacheContextKey || !this.vModel) return;

      window.localStorage.setItem(`multiselect.${this.cacheContextKey}`, this.vModel.join(','));
      window.localStorage.setItem(`multiselectfilter.${this.cacheContextKey}`, this.vModel.join(','));
    },
    getCachedValue() {
      if(!this.cacheContextKey) return null;

      return window.localStorage.getItem(`multiselect.${this.cacheContextKey}`);
    },
    setValueFromCache() {
      if((this.vModel & this.vModel.length > 0) || this.modelValue || !this.cacheContextKey || this.options.length === 0) return;

      let cachedValue = this.getCachedValue();
      if(!cachedValue) return;

      cachedValue = cachedValue.split(',');
      const found = this.options.find((item) => { return cachedValue.includes(item[this.valueKey]); });

      if(!found) return;

      this.vModel = cachedValue;
      this.onUpdate(cachedValue);
    }
  }
}
</script>
