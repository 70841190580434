<template class="page-content">
  <ModalLayout :ref="modalRef" :modal-id="`coordinator-notes-modal-${matchId}`"
    :loading="isSavableStateLoading"
    @close="modalClosed">
    <template #modal-header>
      <h5 v-if="selectedMatch && selectedMatch.transplant_center" class="modal-title">
        {{ `Donor: ${selectedMatch.donor.code} | Match: ${selectedMatch.code}-${selectedMatch.organ.name} | TC: ${selectedMatch.transplant_center.name}
                     | Offer Date: ${selectedMatch.offer_date}` }}
      </h5>
    </template>
    <template #modal-body>
      <form @submit.prevent="saveNote">
        <fieldset>
          <div class="row">
            <div class="col-sm-12">
              <div class="row">
                <div class="standard-form-group-large">
                  <label for="note">Coordinator Note:</label>
                  <textarea id="note" v-model="currentNote" rows="5" class="form-control" />
                </div>
              </div>
              <div class="save-toolbar action-row">
                <ActionToolbar :component-state="currentState" 
                  toolbar-size="col-md-6"
                  :primary-disabled="disableSaving || currentNote?.length == 0 || isSavableStateLoading"
                  success-message="Successfully updated note"
                  primary-button-text="Save Note"
                  :secondary-enabled="false"
                  :error-message="formError" />  
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <div v-if="selectedMatch && selectedMatch.coordinator_note && selectedMatch.coordinator_note.ordered_versions && selectedMatch.coordinator_note.ordered_versions.length > 0" class="row">
        <div class="col-sm-12 mb-2">
          <PortalTable :table-id="`coordinator-notes-table-${matchId}`"
            :table-data="selectedMatch.coordinator_note.ordered_versions"
            :total-records="selectedMatch.coordinator_note.ordered_versions.count"
            :loading="isSavableStateLoading"
            selection-mode="single"
            @table-row-click="populateFields">
            <template #columns>
              <Column field="created_at" header="Date Changed" style="min-width: 100px;">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.created_at) }}
                </template>
              </Column>
              <Column field="changed_by" header="Changed By" style="min-width: 100px;" />
              <Column field="changed_to.note" header="Changed To" style="white-space: pre-wrap; min-width: 100px;" />
            </template>
          </PortalTable>
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script lang="js">
import {APIRoute, EP} from "~/src/endpoints";
import beApiClient from "@/src/be-api-client";
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';

export default {
  components: {
    PortalTable,
    ModalLayout,
    Column,
    ActionToolbar
  },
  mixins: [savableStateMixin, modalActionsMixin],
  props: {
    matchId: { required: true, type: String }
  },
  emits: ['close', 'note-updated'],
  data() {
    return {
      selectedMatch: null,
      disableSaving: false,
      currentNote: null,
      currentState: null,
      modalRef: `notes-modal`,
      errorMessage: null
    }
  },
  watch: {
    async matchId() {
      await this.getModalData();
    }
  },
  async mounted() {
    this.selectedMatch = null;
    await this.getModalData();
  },
  methods: {
    formatDate(dateString) {
      let date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    async getModalData() {
      if(!this.matchId) return;

      try {
        this.setSavableStateLoading();
        const endpoint = APIRoute(EP.matches.show, { id: this.matchId });
        // make request
        const response = await beApiClient.get(endpoint);
        // update data
        this.selectedMatch = response.data; 
        this.currentNote = this.selectedMatch.coordinator_note?.note ? this.selectedMatch.coordinator_note.note : '';

        // finish
        this.setSavableStateIdle();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    modalClosed() {
      this.setSavableStateBlank();
      this.$emit('close');
    },
    populateFields(rowData) {
      let row = rowData
      this.disableSaving = row.next != null;
      this.currentNote = row.changed_to.note;
    },
    async saveNote() {
      if(this.currentNote.length > 0) {
        const payload = {
          coordinator_note: {
            note: this.currentNote
          }
        }
        const endpoint = APIRoute(EP.coordinator_notes.update, { match_id: this.selectedMatch.id })
         this.setSavableStateProcessing();
        try {
          await beApiClient.patch(endpoint, payload);
          await this.getModalData();
          this.$emit('note-updated');
          this.setSavableStateSuccess();
        } catch(error) {
          this.handleFormError(error);
        }
      }
    }
  }
}
</script>
