<template>
  <HorizontalBarChart chart-id="chart-non-recovery"
    size-classes="col-12"
    :config="chartConfig"
    :results="chartResults"
    :loading="chartLoading"
    :error="chartError"
    :header="`Non Recovery`"
    @previewReport="previewReportWithExtraParams"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import HorizontalBarChart from "@/src/components/shared/charts/HorizontalBarChart.vue";

export default {
  components: {
    HorizontalBarChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],

  data() {
    return {
      chartConfig: {
        xTitle: 'Number Of Offers',
        barColours: ['#EF8C8C'],
        barHoverColours: ['#f19d9d'],
        resultLabelField: 'non_recovery',
        datasets: [
          {
            dataField: 'number_of_offers',
            label: 'Number Of Offers',
          }
        ]
      },
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getChartResults(EP.external_analytics.chart_non_recovery, filterParameters);
    },

    previewReportWithExtraParams(value) {
      const routeData = this.$router.resolve({ name: 'external-dash-reports', query: this.previewReportsQuery(value, true) });
      window.open(routeData.href, '_blank');
    },
  },
}
</script>
