<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError"
    header-class="title-bar">
    <template #header>
      Edit Application Configuration - Robocall Reduction
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="robocallReduction" @submit="submitRobocallReduction">   
            <ActionToolbar :secondary-enabled="false" :component-state="currentState" :error-message="formError"
              success-message="Successfully updated robocall reduction!"
              :primary-button-text="isEnabled ? 'Stop Robocall Reduction' : 'Start Robocall Reduction'"
              :primary-style="isEnabled ? 'danger' : 'success'"
              :primary-button-icon="isEnabled ? ['fa', 'ban'] : ['fa', 'check'] "
              @primary-click.prevent="enableOrDisableSettings()" />
            <hr>
            <h1 class="my-2"><strong>Fields</strong></h1>
            <hr>
            <TextInput v-model="robocallReduction.config.unos_robocall_number"
              name="unos_robocall_number"
              label="UNOS Robocall Number"
              rules="required|phone_number"
              col-style="form-group-standard-column" 
            />
            <TextInput v-model="robocallReduction.config.issue_alert_email"
              name="issu_alert_email"
              label="Issue alert email"
              rules="required|email"
              col-style="form-group-standard-column" 
            />
            <!-- save controls -->
            <ActionToolbar :component-state="currentState" 
              success-message="Successfully updated robocall reduction!"
              primary-button-text="Save"
              secondary-button-text="Cancel"
              :error-message="formError"
              @secondary-click="secondaryButtonClick">
            </ActionToolbar>
          </VeeForm>
        </template>
      </SubSection>
      <confirm-dialogue ref="confirmDialogue"
        @confirm="editOffer"
        @cancel="showSearchForm" />
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { Form as VeeForm } from 'vee-validate';
import { useRobocallReductionsStore } from '@/src/stores/robocall_reductions';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import SingleSelect from "@/src/components/shared/FormComponents/SingleSelect.vue";

/**
 * The component handles both the new and edit pages for Application Settings */
export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    TextInput,
    SingleSelect,
    VeeForm,
  },
  mixins: [ savableStateMixin ],
  setup() {
    const robocallReductionsStore = useRobocallReductionsStore();
    return { robocallReductionsStore };
  },
  data() {
    return {
      robocallReduction: {config: {}},
      type: null,
    }
  },
  computed: {
    isEnabled() {
      return this.robocallReduction.active
    },
  },
  watch: { 
    '$route.params.id': {
      handler: function() {
        this.buildRobocallReduction();
      }
    }
  },
  async mounted() {
    let url = window.location.pathname.split('/')
    this.type = url[url.length-(this.$route.params.id ? 3 : 2)]
    this.buildRobocallReduction();
  },
   methods: {
    async enableOrDisableSettings() {
      try {
        this.setSavableStateProcessing();
        if(this.isEnabled) {
          await this.robocallReductionsStore.disableRobocallReduction();
        }
        else {
          await this.robocallReductionsStore.enableRobocallReduction()
        }
        await this.buildRobocallReduction()
        setTimeout(() => {
          this.setSavableStateSuccess();
        }, 200);
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async buildRobocallReduction() {
      try {
        this.setSavableStateLoading();
        // on edit
        const response = await this.robocallReductionsStore.loadRobocallReduction(this.$route.params.id, this.type);
        this.setRobocallReduction(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    async submitRobocallReduction() {
      const payload = {
        config: this.robocallReduction.config,
        active: this.robocallReduction.active,
      }
      try {
        this.setSavableStateProcessing();
        let response;
        response = await this.robocallReductionsStore.updateRobocallReduction(payload);
        this.setRobocallReduction(response);
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },

    setRobocallReduction(record) {
      this.robocallReduction = {
        id: record.id,
        configCategory: record.config_category,
        config: record.config,
        active: record.active,
      }
    },
    /**
     * Handles the secondary button click in the action toolbar.
     */
    secondaryButtonClick() {
      this.$router.push({ name: 'admin_configurations_robocall_reductions' });
    },
  }
}
</script>
