<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      Manage Template Categories
    </template>

    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_communication_template_categories_new' }" class="btn btn-primary btn-sm ml-auto">
            Add New Category
          </router-link>
        </div>
      </div>

      <PortalTable table-id="template_categories_index" 
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        empty="No Template categories found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="category_name" header="Category Name" sortable />
          <Column field="category_template_name" header="Category Type" 
            sort-field="category_type" sortable>
            <template #body="slotProps">
              {{ translateCategoryType(slotProps.data.category_template_name) }}
            </template>
          </Column>
          <Column field="created_by.full_name" 
            header="Created By" 
            sortable />
          <Column field="updated_by.full_name" 
            header="Last Edited By" />
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_communication_template_categories_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { useTemplateStore } from '@/src/stores/template';
import { storeToRefs } from 'pinia';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import { urlParams } from "~/src/endpoints";

export default {
  components: {
    CardSection,
    PortalTable,
    Column
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  setup() {
    const templateStore = useTemplateStore();
    const { getCategoryTypes, translateCategoryType } = storeToRefs(templateStore)
    return {
      templateStore, getCategoryTypes, translateCategoryType
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.templateStore.loadCategoryTypes();
      this.setSavableStateIdle;
    } catch(error) {
      this.handleMajorError(error);
    }

    await this.getRowData();
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;
      
      if (this.currentSortField) {
        this.sort_params = urlParams({ sort_by: this.currentSortField, sort_type: this.currentSortType });
      } else {
        this.sort_params = '';
      }
      const search_params = [this.search_params].filter((p) => { return p && p.length >= 0; });

      const params = {
        page: this.pageNumber,
        search: `${search_params.length > 0 ? '&' : ''}${search_params.join('&')}`,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      try {
        const response = await this.templateStore.loadPaginatedCategories(params);
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    }
  }
}
</script>
