<template>
  <div>
    <fieldset>
      <div class="row" v-if="subSectionHeading || subSectionNav">
        <div class="col sub-section-header">
          <nav class="navbar navbar-expand m-0 p-0 bg-body-tertiary">
            <h4 class="d-inline m-0 h4" v-if="subSectionHeading">{{subSectionHeading}}</h4>
            <ul class="navbar-nav ml-auto" v-if="subSectionNav">
              <li v-for="link in subSectionNav" class="nav-item">
                <router-link class="nav-link" :to="link.to"> {{ link.label }} </router-link>
              </li>
             
            </ul>
          </nav>
        </div>
      </div>
      <slot name="header"/>
      <loading-section v-if="loading"/>
      <div v-else>
        <div class="alert alert-danger" v-if="error && !loading">
          {{ error }}
        </div>
        <slot name="body"></slot>
      </div>
    </fieldset>
  </div>
</template>

<script lang="js">
import LoadingSection from "@/src/components/shared/LoadingSection.vue";

export default {
  components: {
    LoadingSection
  },
  props: {
    error: {
      type: String,
      required: false,
      default: null
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    subSectionHeading: {
      type: String,
      required: false,
      default: null
    },
    subSectionNav: {
      type: Array,
      required: false,
      default: null
    },
    showLoading: { default: false },
  }
}
</script>
