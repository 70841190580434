<template>
  <VeeForm @submit="onSubmit">
    <div class="row">
      <TextInput v-model="donorCode"
        name="donorId"
        label="Donor ID" />

      <TextInput v-model="matchCode"
        name="matchId"
        label="Match ID" />
    </div>
    <ActionToolbar :component-state="currentState" 
      primary-button-text="Next"
      :error-message="errorMessage"
      :data-dismiss="shouldDismissModal ? 'modal' : undefined"
      @secondary-click="close" />
  </VeeForm>
</template>

<script lang="js">
import { useTemplateStore } from '@/src/stores/template';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { Form as VeeForm } from 'vee-validate';

export default {
  components: {
    TextInput,
    ActionToolbar,
    VeeForm,
  },
  mixins: [ savableStateMixin ],
  props: {
    categoryName: { default: null, type: String },
    txcCode: { default: null, type: String },
    templateId: { default: null, type: String }
  },
  emits: [ 'closeModal', 'success'],
  setup() {
    const templateStore = useTemplateStore();
    return {
      templateStore
    }
  },
  data() {
    return {
      donorCode: null,
      matchCode: null,
      errorMessage: null,
      shouldDismissModal: null
    }
  },
  mounted() {
    this.donorCode = localStorage.getItem("donor") || "";
    this.matchCode = localStorage.getItem("match") || "";
  },
  methods: {
    async onSubmit() {
      this.shouldDismissModal = false
      localStorage.setItem("donor", this.donorCode);
      localStorage.setItem("match", this.matchCode);

      this.setSavableStateIdle();
      try {
        const response = await this.templateStore.validateMatch(this.donorCode, this.matchCode, this.txcCode);
        // check for number of matches
        if (response.length > 0) {
          // get the id of the first one (there could be multiple)
          const id = response[0].id;
          const content = await this.templateStore.previewTemplate(this.templateId, id);
          if (content && content.computed_match_template) {
            const preview = {
              categoryName: this.categoryName,
              donorCode: this.donorCode,
              matchCode: this.matchCode,
              content: content.computed_match_template.body,
              subject: content.computed_match_template.subject,
              category_template_name: content.computed_match_template.category_template_name
            }
            this.$emit("success", preview)
            this.setSavableStateSuccess();
          } else {
            this.errorMessage = 'Error generating preview';
            this.setSavableStateErrored();
          }
        } else {
          this.errorMessage = 'The selected combination of Match and Donor is not valid for the selected template';
          this.setSavableStateErrored();
        }
      } catch (error) {
        // if no error, revert to match error
        if (!error) {
          this.errorMessage = 'The selected combination of Match and Donor is not valid for the selected template';
        } else {
          this.errorMessage = error;
        }
        this.setSavableStateErrored();
      }
    },
    close(){
      this.shouldDismissModal = true
      setTimeout(() => {
        this.shouldDismissModal = false
        }, 100);
      this.$emit("closeModal");
    },
  }
}
</script>
