<template>
  <div v-if="message" class="row">
    <div class="col-12">
      <div class="alert fade-out-transition" :class="alertStateCSS" role="alert">
        <span>{{ message }}</span>
        <button type="button" 
          class="close" 
          data-dismiss="alert" 
          aria-label="Close" 
          @click="hideBanner">
          <font-awesome-icon :icon="['fas', 'fa-times']" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { savableStateMixin } from '@/src/mixins/savableStateMixin';
import { SaveStates } from "@/src/enums";

export default {
  mixins: [savableStateMixin],
  props: {
    message: { required: true, type: String },
    // Current state of the component, uses SaveStates enum. Understands: Blank (default), Processing, Success and Errored
    componentState: { default: SaveStates.Blank, type: String },
  },
  data() {
    return {
      messageContent: null
    }
  },
  computed: {
    alertStateCSS() {
      if (this.currentState === SaveStates.Success) {
        return 'alert alert-success';
      } else if (this.currentState === SaveStates.Errored) {
        return 'alert-danger';
      } else {
        return 'alert-light';
      }
    }
  },
  watch: {
    // We need an internal state, so we use a bridge as it ensures
    // that parent is always becomes the source of truth if it changes.
    componentState(newVal) {
      this.currentState = newVal;
    }
  },
  created() {
    this.currentState = this.componentState;
  }
}
</script>