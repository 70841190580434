import { error } from "jquery";

// filter delay method, defaults to 500ms
export function createDebounce() {
  let timeout = null;
  return function (fnc, delayMs) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fnc();
    }, delayMs || 500);
  };
};

function parseErrorResponse(errorResponse) {
  if(errorResponse.data.errors) {
    return errorResponse.data.errors;
  }

  if(errorResponse.status === 404) {
    return 'Entity Not Found';
  }

  return errorResponse;
}

export function handleErrors(response) {
  if (response.message) {
    const errors = parseErrorResponse(response.response);
    let errorStrings;
    if(errors && typeof errors === 'object') {
      const errorValues = Object.values(errors);
      errorStrings = Array.isArray(errorValues) ? errorValues.join(', ') : errorValues;
    } else {
      errorStrings = errors;
    }
    const message = (`${errorStrings}`);
    return message;
  } else {
    return response;
  }
};

export function makeRequest(method, url, payload = null) {
  return new Promise((resolve, reject) => {
    method(url, payload).then(response => {
      resolve(response.data);
    }).catch(error => {
      const message = handleErrors(error);
      reject(message);
    })
  })
};