import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const usePermissionStore = defineStore('permission', {
  state: ()=> {
    return {
      // lookup data
      permissions: []
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    // Load permissions
    loadPermissions() {
      return this.loadEntity(APIRoute(EP.admin.permissions.index), 'permissions')
    },
    
  }
});