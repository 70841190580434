<template>
  <div :class="colStyle">
    <Field v-slot="{ errors, field }"
      v-model="getValue" 
      :name="name"
      :rules="rules">
      <label v-if="!hideLabel" :for="name">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
      <Dropdown v-bind="field"
        v-model="vModel"
        :option-value="valueKey"
        :option-label="labelKey" 
        :placeholder="placeholder" 
        class="form-control form-control-with-dropdown"
        :loading="loading"
        :options="options"
        :filter="filter"
        :show-clear="showClear"
        @update:model-value="onUpdate"
        @change="onChange" />
      <InvalidFeedback :name="getErrorName" :errors="errors" />
    </Field>
  </div>
</template>

<script>
import { useForm, Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import InvalidFeedback from "@/src/components/shared/InvalidFeedback.vue";
import Dropdown from 'primevue/dropdown';
import { formMixin } from "@/src/mixins/formMixin.js";

export default {
  components: {
    InvalidFeedback,
    Field,
    Dropdown
  },
  mixins: [ formMixin ],
  props: {
    modelValue: { default: null, type: Object },
    colStyle: { default: 'form-group-standard-6-column-medium-up', type: String },
    options: { default: (() => []), type: Array },
    labelKey: { default: 'value', type: String },
    valueKey: { default: 'code', type: String },
    placeholder: { default: 'Select...', type: String },
    loading: { default: false, type: Boolean },
    hideLabel: { default: false, type: Boolean },
    filter: { default: false, type: Boolean },
    showClear: { default: false, type: Boolean },
  },
  emits: ['update:modelValue', 'change'], // explicitly declare emit events used
  data() {
    return {
      vModel: null
    }
  },
  watch: {  
    // used when receiving a value from parent
    'modelValue': {
      handler: function(value) {
        let newValue = value;
        if (value && this.options.length > 0) {
          const found = this.options.find((item) => { return newValue.includes(item[this.valueKey]); });
          // if not found, clear the value
          if (!found) { newValue = null; }
        }
        // if we receive a value, update our local copy
        if (newValue) {
          this.vModel = newValue;
        } else {
          this.vModel =  null;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onUpdate(value) {
      this.$emit('update:modelValue', value)
    },
    onChange(event) {
      this.$emit('change',event.value); 
    }
  }
}
</script>
<style>
.form-control-with-dropdown {
  display: inline-flex;
  padding: .25rem .175rem !important;
}
span, .p-inputtext {
  padding: .35rem !important;
}
</style>