<template>
  <div class="row">
    <div class="col-sm-12 mb-3">
      <span v-if="user.virtual_phone_number">User virtual number: {{ user.virtual_phone_number.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') }}</span>
      <span v-else>No virtual number associated with user.</span>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">   
      <ActionToolbar :primary-style="user.virtual_phone_number ? 'danger' : 'success'"
        :secondary-enabled="false"   
        :component-state="currentState" 
        :error-message="formError" 
        success-message="Saved successfully!"
        :primary-button-text="user.virtual_phone_number ? 'Remove number' : 'Associate a number'"
        @primary-click.prevent="onSubmit()" />
    </div>
  </div>
</template>
<script>
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useUserStore } from '@/src/stores/user';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
export default {
  components: {
    ActionToolbar
  },
  mixins: [savableStateMixin],
  props: {
    user: { required: true, type: Object }
  },
emits: ['update'],
  setup() {
    const userStore = useUserStore();
    return {
      userStore
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.setSavableStateProcessing();
        let response;
        if (this.user.virtual_phone_number) {
          response = await this.userStore.removeVirtualNumber(this.user.id);
        } else {
          response = await this.userStore.addVirtualNumber(this.user.id);
        }
        this.$emit('update', response);
        this.setSavableStateSuccess()
      } catch (error) {
        this.handleFormError(error);
      }
    }
  }
}
</script>