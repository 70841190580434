<template>
  <div>
    Integrations
    <ul>
      <li>
        <router-link :to="{name: 'admin_integrations_omnilife_workflow_mappings'}">
          Case Mappings
        </router-link>
      </li>
    </ul>
  </div>
</template>
