import { format, utcToZonedTime } from "date-fns-tz";

const Format = Object.freeze({
  date_format: 'MM/dd/yyyy',
  date_time_format: 'MM/dd/yyyy HH:mm',
  time_format: 'HH:mm'
});

export default Format;

/**
 *
 * @param datetime: String
 * @returns DateTime
 */
export function formatDateTime(datetime) {
  const dt = new Date(datetime);
  const d= format(utcToZonedTime(dt, "UTC"), Format.date_time_format, { timeZone: "UTC"});
  return d;
}

/**
 *
 * @param datetime: String
 * @returns Time
 */
export function formatTime(datetime) {
  const dt = new Date(datetime);
  return format(utcToZonedTime(dt, "UTC"), Format.time_format, { timeZone: "UTC"});
}

/**
 *
 * @param datetime: String
 * @returns Date
 */
export function formatDate(datetime) {
  const dt = new Date(datetime);
  return format(utcToZonedTime(dt, "UTC"), Format.date_format, { timeZone: "UTC"});
}

/**
 *
 * @param datetime: String
 * @returns Date
 */
export function formatDateTimeTotoLocaleString(dateString) {
  const date = new Date(dateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}

/**
 *
 * @param height: number
 * @returns number
 */
export function convertCmToInch(height){
  if(height) {
      return (height * 0.39370).toFixed(1);
  }
  return '';
}

/**
 *
 * @param height: number
 * @returns number
 */
export function convertKgToLbs(weight){
  if(weight) {
      return (weight * 2.2046).toFixed(1);
  }
  return '';
}

