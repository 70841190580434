<template>
  <div>
    <div class="content-wrap dashboard-widgets">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <CardSection :custom-header="true">
              <template #header>
                <div class="card-header media" style="display: flex; align-items: center">
                  <div class="media-left">
                    <div>
                      <font-awesome-icon :icon="['fas', 'user-group']" />
                    </div>
                  </div>
                  <div class="media-body">
                    <h3 class="card-title d-flex">
                      <span>Recipients</span>
                      <ul class="nav card-nav">
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-dash-available-offers' }" class="router-link-active">
                            Donors
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link :to="{ name: 'external-donor-details', query: {organ: organ, tc: tc} }" class="ml-auto">
                            Donor Details
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <a href="#" data-toggle="modal" data-target="#txCNotesModal" class="ml-auto" @click="getModalData()">Notes</a>
                        </li>
                      </ul>
                    </h3>
                  </div>
                </div>
              </template>
              <template #body>
                <PortalTable :table-id="troubleshoot-donors-table"
                  :table-data="recipients"
                  :total-records="recipients.count"
                  :loading="loading"
                  selection-mode="single"
                  empty="No recipients found!"
                  @table-row-click="populateData">
                  <template #columns>
                    <Column field="recipient" header="Recipient" style="white-space: pre-wrap; min-width: 150px">
                      <template #body="slotProps">
                        <b>{{ slotProps.data.recipient_first_name + ' ' + slotProps.data.recipient_last_name + ' | S' + (slotProps.data.seq ? slotProps.data.seq :'N/A') + ' | R' + (slotProps.data.rank ? slotProps.data.rank : 'N/A') }}</b>
                      </template>
                    </Column>
                    <Column field="age" header="Age" style="min-width: 50px" />
                    <Column field="gender" header="Sex" style="min-width: 100px">
                      <template #body="slotProps">
                        <span>{{ slotProps.data.gender ? slotProps.data.gender : 'N/A' }}</span>
                      </template>
                    </Column>
                    <Column field="blood_type" header="ABO" style="min-width: 50px" />
                    <Column field="mismatch" header="Mismatch" style="min-width: 160px">
                      <template #body="slotProps">
                        <span>{{ 'A: ' + (slotProps.data.mismatch_a ? slotProps.data.mismatch_a : 0) + ', B: ' + (slotProps.data.mismatch_b ? slotProps.data.mismatch_b : 0) + ', DR: ' + (slotProps.data.mismatch_dr ? slotProps.data.mismatch_dr : 0) }}</span>
                      </template>
                    </Column>
                    <Column field="height" header="Height" style="min-width: 60px">
                      <template #body="slotProps">
                        <span>{{ slotProps.data.height ? slotProps.data.height + 'cm' : 'N/A' }}</span>
                      </template>
                    </Column>
                    <Column field="weight" header="Weight" style="min-width: 60px">
                      <template #body="slotProps">
                        <span>{{ slotProps.data.weight ? slotProps.data.weight+ 'kg' : 'N/A' }}</span>
                      </template>
                    </Column>
                    <Column field="bmi" header="BMI" style="min-width: 60px">
                      <template #body="slotProps">
                        <span>{{ slotProps.data.bmi ? slotProps.data.bmi : 'N/A' }}</span>
                      </template>
                    </Column>
                    <Column field="compare" style="min-width: 60px">
                      <template #body="slotProps">
                        <a href="#" title="Compare" class="btn btn-sm btn-primary ml-3" @click.prevent="compare(slotProps.data)">Compare</a>
                      </template>
                    </Column>
                  </template>
                </PortalTable>
              </template>
            </CardSection>
          </div>
        </div>
      </div>
    </div>
    <NotesModal :match-id="matchId" />
  </div>
</template>

<script lang="js">
import beApiClient from "~/src/be-api-client";
import { APIRoute, EP } from "~/src/endpoints";
import NotesModal from "@/src/components/external-clinical-area/TxCNotes.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import CardSection from "@/src/components/shared/CardSection.vue";
import { useAppCoreStore } from "@/src/stores/app-core";

export default {
  components: {
    CardSection,
    NotesModal,
    PortalTable,
    Column
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  data() {
    return {
      recipients: [],
      loading: false,
      majorError: null,
      donor: '',
      match: '',
      matchId: '',
      organ: '',
      tc: '',
      selectedData: {},
      notes: ''
    }
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([{text: 'Loading...'}]);
    this.donor = this.$route.params.donor_id;
    this.matchId = this.$route.params.id;
    this.organ = this.$route.query.organ;
    this.tc = this.$route.query.tc;
    this.getmatch();
    
  },
  methods: {
    populateData(data,col) {
      if(!col) return;

      if( col.field == "compare" ) {
        this.compare(data);
      }
    },
    compare(row) {
      this.$router.push({ 
        name: 'compare-donor-recipient',
        params: {
          id: this.match.id,
          donor_id: this.donor,
          recipient_id: row.id,
        },
        query: {            
          organ: this.organ,
          tc: this.tc,
          seq: row.seq,
          rank: row.rank
        } 
      });
    },
    getmatch() {
      // Get data
      this.loading = true;
      beApiClient.get(APIRoute(EP.matches.show, { id: this.matchId}))
      .then((responses) => {
        this.match = responses.data;
        this.getRecipientInfo();
      }).catch(e => {
        this.majorError = e;
        this.loading = false;
      }).finally(() => { 
        this.loading = false 
      });
    },
    getRecipientInfo() {
      Promise.all([
        beApiClient.get(APIRoute(EP.matches.recipients.index, { match_id: this.matchId}),{params: { limit: 10} }),
      ]).then((responses) => {
        this.recipients = responses[0].data.recipients;
      }).catch(e => {
        this.majorError = e;
      }).finally(() => {
        this.setBreadcrumbs();
      });
    },
    setBreadcrumbs() {
      this.appStore.setAppBreadcrumbs([
        {
          to: { name: 'external-dash-available-offers' },
          text: 'Donors',
        },
        {
          to: { name: 'external-donor-details', query: { organ: this.organ, tc: this.tc } },
          text: `${this.match?.donor?.code} - ${this.match?.code} - ${this.organ}`,
        },
        {
          text: 'Recipients',
        },
      ]);
    },
    getModalData() {
      this.selectedData = {
        donor: this.match.donor.code,
        match: this.match,
        organ: this.organ,
        tc: this.tc,
        notes: this.match.coordinator_note
      }
    },
  }
}
</script>
