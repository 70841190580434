<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      Import Users
    </template>
    <template #body>
      <SubSection sub-section-heading="Mass Import Users">
        <template #body>
          <div class="row">
            <div class="col-lg-6">
              <VeeForm @submit="onSubmit">
                <div class="row">
                  <div class="col-12">
                    <label for="document">Upload CSV</label>
                    <input id="user-import-csv" 
                      ref="csvUploader" 
                      rules="required" 
                      type="file" 
                      :disabled="isSavableStateProcessing"
                      class="form-control" 
                      accept=".csv"
                      @change="onFileSelect($event)">
                  </div>
                </div>
                <ActionToolbar :component-state="currentState" 
                  :primary-disabled="!csvFile || !csvFile[0]"
                  primary-button-text="Upload"
                  success-message="Upload completed. Check below for any errors!"
                  :secondary-enabled="false"
                  :error-message="formError" />
              </VeeForm>
            </div>
            <div class="col-lg-6">
              <p class="help-text">
                Expects a CSV with the following columns exactly matching the spelling/case below.
              </p>
              <table class="table-bordered">
                <tr>
                  <td>Column header</td>
                  <td>Description</td>
                </tr>
                <tr>
                  <td><strong>full_name</strong></td>
                  <td>The full name of the user</td>
                </tr>
                <tr>
                  <td><strong>email</strong></td>
                  <td>The email of the user</td>
                </tr>
                <tr>
                  <td><strong>login_mechanism</strong></td>
                  <td>The login mechanism for the user</td>
                </tr>
                <tr>
                  <td><strong>membership_categories</strong></td>
                  <td>The (user) membership category names for the user, separated by &apos;;&apos;. Will be used to check teams against</td>
                </tr>
                <tr>
                  <td><strong>team</strong></td>
                  <td>Team names separated by &apos;;&apos; to connect the user to</td>
                </tr>
                <tr>
                  <td><strong>role</strong></td>
                  <td>The role for the team membership. This is a single role used for all teams</td>
                </tr>
              </table>
            </div>
          </div>

          <hr>
          <PortalTable v-if="importResponse"
            ref="user_import_status_table"
            table-id="user-import-status-table"
            :table-data="importResponse"
            filter-display="row"
            :row-class="rowClass"
            empty="No import data to manage!"
            css-class="mb-3"
            mode="local">
            <template #columns>
              <Column field="full_name" header="Full Name" />
              <Column field="email" header="Email" />
              <Column field="login_mechanism" header="Login Mechanism" />
              <Column field="membership_categories" header="Membership Categories" />
              <Column field="team" header="Team" />
              <Column field="role" header="Role" />
              <Column field="imported" header="Imported?" />
              <Column field="errors" header="Errors">
                <template #body="{ data }">
                  <ul v-if="data.errors">
                    <li v-for="(error, idx) of data.errors" :key="idx">
                      {{ error }}
                    </li>
                  </ul>
                </template>
              </Column>
            </template>
          </PortalTable>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { Form as VeeForm } from 'vee-validate';
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP} from "@/src/endpoints";

export default {
  components: {
    CardSection,
    SubSection,
    VeeForm,
    ActionToolbar,
    PortalTable,
    Column
  },
  mixins: [savableStateMixin],
  data() {
    return {
      csvFile: null,
      importResponse: null
    }
  },
  methods: {
    onFileSelect(evt) {
      this.setSavableStateIdle();
      this.csvFile = evt.target.files;
    },
    rowClass(data) {
      return [{ 'table-success': data.imported, 'table-danger': !data.imported }];
    },
    async onSubmit() {
      try {
        this.setSavableStateProcessing();
        this.importResponse = null;
        const route = APIRoute(EP.admin.users.mass_import);
        let payload  = new FormData();
        if(this.csvFile) {
          payload.append('import_csv', this.csvFile[0]);
        }
      
        const response = await beApiClient.post(route, payload, {
          'Content-Type': 'multipart/form-data'
        });

        this.importResponse = response.data.imported_users;
        this.setSavableStateSuccess();

      } catch(error) {
        this.handleFormError(error);
      }
    }
  }
}
</script>