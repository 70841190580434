<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Edit Team {{ selectedTeam == null ? '' : `- ${selectedTeam.teamName}` }}
    </template>

    <template #body>
      <SubSection sub-section-heading="Advanced" :sub-section-nav="subSectionNav">
        <template #body>
          <template v-if="!isSavableStateLoading">
            <div class="bordered-section">
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <p v-if="currentState == 'idle' || currentState == 'errored'">
                    In order to delete a team all associated memberships must be deleted. 
                    <br>
                    Please ensure that all Team memberships are deleted in the 
                    <router-link :to="{ name: 'admin_teams_edit_manage', params: { id: selectedTeam.id } }"
                      class="text-danger">
                      Manage Members
                    </router-link>
                    area. 
                    <br>
                    Once they are deleted you can delete this team.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mb-3">
                  <ActionToolbar :component-state="currentState" 
                    :error-message="formError" 
                    primary-style="danger"
                    primary-button-text="Delete Team"
                    :secondary-enabled="false"
                    @primary-click="onSubmitDelete()" />
                </div>
              </div>
            </div>
          </template>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import { useTeamStore } from '@/src/stores/team';
import SubSection from "@/src/components/shared/SubSection.vue";
import '@/src/vee-validate.js';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {teamSubSectionNavMixin} from "@/src/components/admin-area/teams/teamSubSectionNavMixin";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar
  },
  mixins: [ savableStateMixin, teamSubSectionNavMixin ],
  setup() {
   const teamStore = useTeamStore();
   return {
      teamStore
    }
  },
  data() {
    return {
      selectedTeam: null
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.loadTeam(this.$route.params.id)
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    async loadTeam(id) {
      const record = await this.teamStore.loadTeam(id)
      this.selectedTeam = {
        id: record.id,
        teamName: record.name,
        membershipCategoryId: record.membership_category_id,
        teamAccess: record.team_accesses,
      }
    },
    async onSubmitDelete() {
      try {
        await this.teamStore.deleteTeam(this.selectedTeam.id);
        // alert and redirect as team no longer exists
        alert("Team Deleted!")
        this.$router.push({ name: 'admin_teams' });
      } catch(error) {
        this.handleFormError(error);
      }
    },
  }
}
</script>
