<template>
  <ModalLayout :ref="modalRef"
    @close="closeModal">
    <template #modal-header>
      <h5 class="modal-title">
        Variable Reference
      </h5>
    </template>
    <template #modal-body>
      <PortalTable table-id="template_variables_index"
        mode="local"
        :table-data="templateVariables"
        :total-records="templateVariables.length"
        filter-display="row"
        :filter-values="filterValues"
        empty="No template variables found!"
        :loading="isSavableStateLoading">
        <template #columns>
          <Column field="name"
            header="Variable"
            filter-field="name"
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by Variable name"
                @input="filterCallback()" />
            </template>
            <template #body="slotProps">
              {{ slotProps.data.name }}
              <button :class="`copy_${slotProps.data.name}`"
                type="button"
                class="btn btn-outline-secondary"
                data-dismiss="modal"
                @click="copyToClipboard(slotProps.data.name)">
                copy
              </button>
            </template>
          </Column>
          <Column field="description"
            header="Description"
            filter-field="description"
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by description"
                @input="filterCallback()" />
            </template>
          </Column>
        </template>
      </PortalTable>
      <ActionToolbar :component-state="currentState" 
        :primary-enabled="false"
        secondary-button-text="Close" 
        :error-message="errorMessage"
        :data-dismiss="shouldDismissModal ? 'modal' : undefined"
        @secondary-click="closeModal" />
    </template>
  </ModalLayout>
</template>

<script lang="js">
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { useTemplateStore } from '@/src/stores/template';
import { storeToRefs } from 'pinia'
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {FilterMatchMode} from "primevue/api";
import InputText from "primevue/inputtext";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";

export default {
  components: {
    ModalLayout,
    PortalTable,
    Column,
    InputText,
    ActionToolbar
  },
  mixins: [ modalActionsMixin, savableStateMixin ],
  emits: ['close'],
  setup() {
    const templateStore = useTemplateStore();
    const { getVariables } = storeToRefs(templateStore)
    return {
      templateStore, getVariables
    }
  },
  data() {
    return {
      errorMessage: null,
      shouldDismissModal: null,
      modalRef: 'templateVariablesModal',
      templateVariables: [],
      filterValues: {
        name: { value: "", matchMode: FilterMatchMode.CONTAINS },
        description: { value: "", matchMode: FilterMatchMode.CONTAINS }
      }
    }
  },
  async mounted() {
    this.setSavableStateLoading();
    try {
      await this.templateStore.loadVariables()
      this.templateVariables = this.getVariables.map((v) => {
        return {
          name: `{{ ${v.name} }}`,
          description: v.description
        }
      });
      this.setSavableStateIdle();
    } catch(error) {
      this.handleFormError(error);
      this.errorMessage = this.formError;
    }
  },
  methods: {
    copyToClipboard(string) {
      navigator.clipboard.writeText(string).then(() => {
        this.setSavableStateIdle();
        this.closeModal();
      }).catch((error) => {
        this.handleFormError(error);
        this.errorMessage = `Could not copy text: ${this.formError}`;
      });
    },
    closeModal(){
      this.shouldDismissModal = true
      this.errorMessage = null
      this.setSavableStateIdle();
      setTimeout(() => {
        this.shouldDismissModal = false
        }, 100);
        this.$emit("close");
    },
  }
}
</script>
