<template>
  <div class="form-group" :class="colStyle">
    <Field v-slot="{ errors, field }"
      v-model.trim="getValue" 
      :name="name"
      :rules="rules">
      <label :for="name">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
      <input :name="name" 
        v-bind="field" 
        type="text"
        :placeholder="placeholder"
        :class="{
          'is-invalid': !disabled && hasErrors(errors), 
          'form-control': !readonly, 
          'form-control-plaintext': readonly,
        }"
        :readonly="readonly||disabled"
        @input="$emit('update:modelValue', $event.target.value)">
      <InvalidFeedback :name="getErrorName" :errors="errors" />
    </Field>
  </div>
</template>

<script>
import { Field } from 'vee-validate';
import InvalidFeedback from "@/src/components/shared/InvalidFeedback.vue";
import { formMixin } from "@/src/mixins/formMixin.js";

export default {
  components: {
    InvalidFeedback,
    Field
  },
  mixins: [ formMixin ],
  props: {
    colStyle: { default: 'form-group-standard-6-column-medium-up', type: String },
    placeholder: { default: '', type: String }
  },
  emits: ['update:modelValue'],
}
</script>

