<template>
  <CardSection header-class="title-bar">
    <template #header>
      Troubleshoot Donors
    </template>
    <template #body>
      <fieldset>
        <legend>
          <h4 class="legend-title">
            Search donor
          </h4>
        </legend>
        <form @submit.prevent="searchDonor()">
          <div class="row">
            <div class="standard-form-group">
              <label for="donor-code">Donor Code</label>
              <input id="donor-code" v-model="donorCode" type="text" class="form-control">
            </div>
          </div>
          <div class="row">
            <div class="standard-form-group">
              <div class="save-toolbar action-row">
                <button type="submit" class="btn btn-success sec-submit">
                  Search
                </button>
                <div v-if="error" class="fade-list-item notification notification-error notification-inline button-area">
                  <p>
                    <font-awesome-icon :icon="['fas', 'circle-exclamation']" />
                    {{ error }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="standard-form-group">
            <button class="btn btn-success" @click="showErroredDonors()">
              Show Errored Donors
            </button>
          </div>
          <div class="standard-form-group">
            <button class="btn btn-success" @click="showOngoingDonors()">
              Show Ongoing Donors
            </button>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <h4 class="legend-title">
            Donors list
          </h4>
        </legend>
        <div class="row">
          <div v-if="!donors || donors.length < 1" class="col-12">
            <template v-if="donorCode || (donors && donors.length < 1)">
              No donor found
            </template>
            <template v-else>
              Please search for a donor code
            </template>
          </div>
          <div v-else class="col-12">
            <PortalTable v-if="donors"
              :table-id="troubleshoot-donors-table"
              :table-data="donors"
              :total-records="donors.count"
              :loading="loading">
              <template #columns>
                <Column field="seq" header="Sq#" style="min-width: 100px;">
                  <template #body="slotProps">
                    {{ slotProps.data.seq ? slotProps.data.seq : 'Match' }}
                  </template>
                </Column>
                <Column field="code" header="Code" style="min-width: 100px;" />
                <Column field="processing_started_at" header="Processing started at" style="min-width: 100px;" />
                <Column field="processing_completed_at" header="Processing completed at" style="min-width: 100px;" />
                <Column field="processing_status" header="Processing Status" style="min-width: 100px;" />
                <Column field="processing_error" header="Processing error" style="min-width: 100px;" />
                <Column field="id" header="Actions" style="min-width: 100px;">
                  <template #body="slotProps">
                    <button class="btn btn-primary btn-sm" @click.prevent="triggerReprocess(slotProps.data.id)">
                      Reprocess
                    </button>
                  </template>
                </Column>
              </template>
            </PortalTable>
          </div>
        </div>
      </fieldset>
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import {APIRoute, EP} from "@/src/endpoints";
import beApiClient from "@/src/be-api-client";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';

export default {
  components: {
    CardSection,
    PortalTable,
    Column
  },
  data() {
    return {
      donorCode: '',
      donors: null,
      error: null,
      loading: false,
    }
  },
  methods: {
    showErroredDonors() {
      beApiClient.get(APIRoute(EP.admin.donors.index), { params: { query: 'errored' }}).then(response => {
        this.donors = response.data;
      }).catch(error => {
        this.error = error;
      })
    },
    showOngoingDonors() {
      beApiClient.get(APIRoute(EP.admin.donors.index), { params: { query: 'ongoing' }}).then(response => {
        this.donors = response.data;
      }).catch(error => {
        this.error = error;
      })
    },
    searchDonor() {
      this.error = null;

      if(!this.donorCode) {
        this.error = 'You need specify a code';
        return;
      }

      // Get data
      this.loading = true;
      beApiClient.get(APIRoute(EP.admin.donors.index), { params: { query: this.donorCode }}).then(response => {
        this.donors = response.data;
        this.loading = false;
      }).catch(error => {
        this.error = error;
        this.loading = false;
      })
    },
    triggerReprocess(donorId) {
      if(!confirm('Are you sure you want to reprocess?')) {
        return;
      }
      beApiClient.post(APIRoute(EP.admin.donors.reprocess, { id: donorId })).then(() => {
        this.searchDonor();
      }).catch(error => {
        this.error = error;
      })
    }
  }
}
</script>
