/**
 * This mixin provides helper utilities that are useful
 * @type {{computed: {getParamsId(): *}, methods: {setPageAndLoad(*): void, generateBreadCrumb(*, *, *, *): string, sortArray(*, *): ([]|*)}}}
 */
export const pageMixin = {
  computed: {
    getParamsId() {
      return this.$route.params.id || null;
    }
  },
  methods: {
    // sets the page number and reload the data
    setPageAndLoad(page) {
      this.pageNumber = page;
      this.getRowData();
    },
    generateBreadCrumb(donorCode, matchCode, txcCode, organCode) {
      return `${donorCode} - ${matchCode} - ${txcCode} - ${organCode}`;
    },
    sortArray(array, field) {
      if (!array) return [];
      // sort by field
      return array.sort((a, b) => {
        if (a[field] < b[field]) {
          return -1;
        }
        if (a[field] > b[field]) {
          return 1;
        }

        // fields must be equal
        return 0;
      });
    }
  }
}