<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }}
      Membership Category {{ selectedCategory.categoryName ? `- ${selectedCategory.categoryName}` : '' }}
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedCategory" @submit="onSubmit">
            <div class="row">
              <TextInput v-model="selectedCategory.categoryName"
                name="categoryName"
                label="Category Name"
                rules="required"
                col-style="form-group-standard-column" />
            </div>
            <div class="row">
              <TextInput v-model="selectedCategory.domains"
                name="domains"
                label="Authorized email domains"
                col-style="form-group-standard-column" />
            </div>
            <ActionToolbar :component-state="currentState" 
              :error-message="formError"  
              :success-message="successMessage" 
              primary-button-text="Save"
              :secondary-button-text="createNew ? 'Cancel' : 'Close'"
              @secondary-click="cancel" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useMembershipCategoryStore } from '@/src/stores/membership-category';
import SubSection from "@/src/components/shared/SubSection.vue";
import '@/src/vee-validate.js';
import { SaveStates } from "~/src/enums.js";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { Form as VeeForm } from 'vee-validate';

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    TextInput,
    VeeForm
  },
  mixins: [ savableStateMixin ],
  setup() {
    const membershipCategoryStore = useMembershipCategoryStore();
    return {
      membershipCategoryStore
    }
  },
  data() {
    return {
      selectedCategory: {},
      formErrorMessage: null,
      majorError: null,
      successMessage: null,
    }
  },
  computed: {
    createNew() {
      return this.$route.params.id ? false : true;
    }
  },
  async mounted() {
    this.setSavableStateLoading();
    this.selectedCategory = {
      id: this.$route.params.id,
      categoryName: null,
      domains: [],
    };

    try {

      if(!this.createNew) {
        // on edit
        const record = await this.membershipCategoryStore.loadCategory(this.$route.params.id)
        this.selectedCategory = {
          id: this.$route.params.id,
          categoryName: record.name,
          domains: record.domains.toString(),
        }
      }
      
      this.setSavableStateIdle();

    } catch(error) {
      this.majorError = error;
      this.setSavableStateErrored();
    }
  },
  methods: {
    async onSubmit() {
      try {
        this.setSavableStateProcessing()
        const payload = {
          name: this.selectedCategory.categoryName,
          domains: (this.selectedCategory.domains.length > 0 ) ? this.selectedCategory.domains.split(',').map(domain => domain.trim()) : []
        }
        if (this.createNew) {
          const response = await this.membershipCategoryStore.createCategory(payload)
          this.selectedCategory.id = response.id
          this.selectedCategory.categoryName = response.name
          this.successMessage = 'Successfully created membership category!' 
          this.setSavableStateSuccess();
          this.$router.push({ name: 'membership_categories_edit', params: { id: response.id } });
        } else {
          payload.id = this.selectedCategory.id;
          await this.membershipCategoryStore.updateCategory(payload)
          this.setSavableStateSuccess();
          this.successMessage = 'Successfully updated membership category!' 
        }
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    cancel() {
      this.$router.push({ name: 'membership_categories' });
    }
  }
}
</script>