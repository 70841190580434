<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Procedure notes
    </template>
    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_procedure_notes_new' }" 
            class="btn btn-primary btn-sm ml-auto">
            Add New Procedure
          </router-link>
        </div>
      </div>

      <PortalTable ref="procedure_notes_index" table-id="procedure_notes_index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        empty="No teams found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="transplant_center.code" 
            sort-field="transplant_center"
            header="TxC" 
            sortable
            filter-field="transplant_center_id"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter>
              <MultiSelectFilter ref="transplant_center_id" 
                filter-field="transplant_center_id"
                class="form-control form-control-select"
                :options="procedureStore.transplantCenters"
                @change="onChange" />
            </template>
          </Column>
          <Column field="organ_group.name" 
            header="Organ" 
            sort-field="organ_group"
            sortable
            filter-field="organ_group_id"
            :show-filter-menu="false"
            :filter-menu-style="{ width: '15rem' }">
            <template #filter>
              <MultiSelectFilter ref="organ_group_id" 
                filter-field="organ_group_id"
                class="form-control form-control-select"
                option-label="name"
                :options="procedureStore.organGroups"
                @change="onChange" />
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_procedure_notes_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { useProcedureStore } from '@/src/stores/procedure_notes';
import { paginationMixin } from "@/src/mixins/paginationMixin";

export default {
   components: {
    CardSection,
    PortalTable,
    Column,
    MultiSelectFilter
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],
  setup() {
    const procedureStore = useProcedureStore();
    return {
      procedureStore
    }
  },
   data() {
    return {
      majorError: null,
      filterValues: {
        "transplant_center_id" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "organ_group_id" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      }
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.procedureStore.loadTransplantCentres();
      await this.procedureStore.loadOrganGroups();
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error); 
    }
    this.getRowData();
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      params = { ...this.search_params, ...params };

      try {
        const response = await this.procedureStore.loadProcedureNotes(params)
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
      this.handleMajorError(error);  
      }
    },
    onChange(event) {
      this.$refs.procedure_notes_index.resetFilters(event);
    },
    onFilterChange(event) {
      if(event["transplant_center_id"] === "") this.$refs.transplant_center_id.clearFilters();
      if(event["organ_group_id"] === "") this.$refs.organ_group_id.clearFilters();

      this.search_params = event;
      this.getRowData(true);
    }
  }
}
</script>