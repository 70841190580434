import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useDashboardStore = defineStore('dashboard', {
  state: ()=> {
    return {
      // lookup data
      transplantCenters: [],
      organGroups: [],
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
   
    // load transplant centres
    loadTransplantCentres() {
      return this.loadEntity(APIRoute(EP.admin.transplant_centers.index), 'transplantCenters')
    },
    // load organ groups
    loadOrganGroups() {
      return this.loadEntity(APIRoute(EP.admin.organ_groups.index), 'organGroups')
    },

    loadMatchesWithUnfinishedBusiness(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.unfinished_business_dashboard.index), { params: params });
    }
    
  }
});