<template>
  <div>
    <VeeForm @submit="uploadReport">
      <fieldset>
        <div class="row">
          <div class="col-sm-12">
            <div class="row"> 
              <div class="standard-form-group-large">
                <label for="document">Upload QA Report</label>
                <input ref="fileUploader"
                  input-id="qa_report" 
                  type="file" 
                  class="form-control" 
                  @change="onFileUpload($event)"> 
              </div>
            </div>
            <p v-if="isSavableStateLoading" class="text-center">
              <font-awesome-icon :icon="['fas', 'fa-spinner']" class="fa-4x fa-spin" />
            </p>
            <ActionToolbar :component-state="currentState" 
              primary-button-text="Upload"
              :primary-disabled="!document"
              success-message="Uploaded Successfully!" 
              :error-message="formError" 
              :secondary-enabled="false" />
          </div>
        </div>
      </fieldset>  
    </VeeForm>
  </div>
</template>

<script lang="js">
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP} from "@/src/endpoints";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { Form as VeeForm } from 'vee-validate';

export default {
  components: {
    ActionToolbar,
    VeeForm
  },
  mixins: [ savableStateMixin ],
  data() {
    return {
      document: null,
      error: null
    }
  },
  methods: {
    uploadReport() {
      this.setSavableStateLoading();
      const method = beApiClient.post;
      const route = APIRoute(EP.reports.qa_report.upload);
      const headers = {
        'Content-Type': 'multipart/form-data'
      };
      let payload  = new FormData();
      if(this.document) {
        payload.append('attachment', this.document[0]);
      }
      method(route, payload, headers).then(response => {
        if(response.data.success) {
          this.setSavableStateSuccess();
        } else {
          this.error =  `Error Uploading! ${response.data.errors}`;
          this.handleFormError(`Error Uploading! ${response.data.errors}`);
        }
      }).catch((error) => {
        this.success = 0; 
        this.handleFormError(`Error Uploading! ${error}`);
      }).finally(() => {
        this.$refs.fileUploader.value = '';
        this.document = null;
      })
    },
    onFileUpload(event) {
      this.document = event.target.files;
    }
  }
}
</script>
