<template>
  <div class="form-group" :class="colStyle">
    <Field v-slot="{ errors, field }"
      v-model="getValue" 
      :name="name"
      :rules="rules">
      <label v-if="!hideLabel" :for="name">{{ label }} <span v-if="isRequired" class="text-danger">*</span></label>
      <slot name="header" />
      <textarea :name="name" 
        v-bind="field"
        :class="{
          'is-invalid': !disabled && hasErrors(errors), 
          'form-control': !readonly, 
          'form-control-plaintext': readonly,
        }"
        :rows="rows"
        :cols="cols" 
        :readonly="readonly||disabled"
        @input="onChange($event.target.value)" />   
      <InvalidFeedback :name="getErrorName" :errors="errors" />
    </Field>
  </div>
</template>

<script>
import { useForm, Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import InvalidFeedback from "@/src/components/shared/InvalidFeedback.vue";
import { formMixin } from "@/src/mixins/formMixin.js";

export default {
  components: {
    InvalidFeedback,
    Field,
  },
  mixins: [ formMixin ],
  props: {
    colStyle: { default: 'form-group-standard-6-column-medium-up', type: String },
    rows: { default: '5', type: String },
    cols: { default: '100', type: String },
    hideLabel: { default: false, type: Boolean },
  },
  emits: ['update:modelValue', 'change'],
  methods: {
    onChange(value) {
      this.$emit('update:modelValue', value)
      // register dirty event on template instance
      this.$emit('change');
    },
  }
}
</script>

