<template>
  <CardSection :loading="isSavableStateLoading" 
    :error="majorError">
    <template #header>
      Comms Templates
    </template>

    <template v-if="!isSavableStateLoading" #body>
      Match Instances
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import { useTemplateStore } from '@/src/stores/template';
import '@/src/vee-validate.js';
import { pageMixin } from "@/src/mixins/pageMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {APIRoute, EP} from "@/src/endpoints";
import beApiClient from "@/src/be-api-client";
import { storeToRefs } from 'pinia'
import {useAppCoreStore} from "@/src/stores/app-core";


export default {
  components: {
    CardSection
  },
  mixins: [ pageMixin, savableStateMixin ],
  props: {
    donor: { required: true, type: String },
    match: { required: true, type: String },
    txc: { required: true, type: String },
    organ: { required: true, type: String }
  },
  setup() {
    const templateStore = useTemplateStore();
    const appStore = useAppCoreStore();
    const { getTemplateList } = storeToRefs(templateStore)
    return {
      templateStore, getTemplateList, appStore
    }
  },
  data() {
    return {
      communicationTemplate: {
        lastReset: null,
        lastSaved: null,
        templateNotes: null,
        templateBody: null
      },
      errorMessage: null,
      donorDetails: null,
      matchDetails: null,
    }
  },
  async mounted() {
    try {
      // get donor & match details
      this.setSavableStateLoading()
      const donorDetails = await beApiClient.get(APIRoute(EP.donors.show, { id: this.donor }));
      const matchDetails = await beApiClient.get(APIRoute(EP.matches.show, { id: this.match }));

      if (!donorDetails.data || !matchDetails.data) { return false; }

      // store locally
      this.donorDetails = donorDetails.data;
      this.matchDetails = matchDetails.data;

      this.appStore.setAppBreadcrumbs([
        {
          to: { name: 'internal-dashboards-root' },
          text: 'Internal'
        },
        {
          to: { name: 'internal-dashboard-plugin' },
          text: 'Dashboard'
        },
        {
          to: { name: 'edit-instance', 
                params: { 
                  donor: this.donor, 
                  match: this.match, 
                  txc: this.txc, 
                  organ: this.organ }
          },
          text: this.generateBreadCrumb(
            this.donorDetails.donor.code,
            this.matchDetails.code,
            this.txc,
            this.organ
          )
        },
        {
          text: 'Donor'
        }
      ]);

      this.setSavableStateIdle();
    } catch(error) {
       this.handleMajorError(error);
    }
  },
  methods: {
    onCancel() {
      this.$router.push({ name: 'internal-dashboard-plugin' })
    }
  }
}
</script>

