<template>
  <div v-if="!appStore.userPermissions" class="alert alert-danger" role="alert">
    You don’t have any roles assigned. Please contact an administrator to get your account set up.
  </div>
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";

export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  mounted() {
    if(this.appStore.currentUser) {
      if(this.appStore.currentUser.canRead(PermissionNames.AREA_INTERNAL)) {
        this.$router.push({ name: 'internal-dashboards-root'});
        return;
      }
      if(this.appStore.currentUser.canRead(PermissionNames.AREA_EXTERNAL)) {
        this.$router.push({ name: 'external-dashboards-root'});
        return;
      }
    }
  }
}
</script>
