<template>
  <div v-if="!majorError">
    <DashboardWidgetLayout widget-id="external-offer-dashboard-full"
      widget-title="Offer Handling"
      :widget-icon="['fas', 'user-group']">
      <template #dashboardSidebarNav>
        <a href=""
          class="nav-link card-header-btn py-0 dashboard-sidenav-link"
          @click.prevent="openColumnConfigModal">
          <span style="padding-right: 0.5rem">Customize Columns</span>
        </a>
      </template>
      <template #filters>
        <div class="row">
          <MultiSelectInput v-model="selectedOrgans"
            name="organ_filter"
            label="Organ"
            :options="getFormattedOrgans"
            label-key="value"
            value-key="id"
            col-style="form-group col-md-3 col-sm-6"
            :multiple="true" />
        </div>
        <div class="sub-divider" />
        <div class="save-toolbar-column">
          <button type="button" data-target="#idTransplantInProgress"
            class="btn btn-success" @click="filterRecords()">
            Search
          </button>
          <button type="button" data-target="#idTransplantInProgress"
            class="btn btn-secondary ml-3" @click="clearFilters()">
            Clear Filters
          </button>
        </div>
      </template>
      <template #content>
        <PortalTable table-id="external-dashboard"
          mode="remote"
          :total-records="totalRecords"
          :page="page"
          :page-count="pageCount"
          :table-data="recordList"
          :loading="loading"
          @on-page="onPortalPage">
          <template #columns>
            <Column v-if="showColumn('donor.code')" field="donor.code" header="Donor" style="min-width: 100px;">
              <template #body="slotProps">
                <a target="_blank" :href="linkToOffer(slotProps.data)" class="table-link">{{ slotProps.data.donor.code }}</a>
              </template>
            </Column>
            <Column v-if="showColumn('code')" field="code" header="Match" style="min-width: 100px;" />
            <Column v-if="showColumn('organ.organ_group.name')" field="organ.organ_group.name" header="Organ" style="min-width: 100px;" />
            <Column v-if="showColumn('transplant_center.code')" field="transplant_center.code" header="TC" style="min-width: 70px;" />
            <Column v-if="showColumn('donor.opo.code')" field="donor.opo.code" header="OPO" style="min-width: 100px;" />
            <Column v-if="showColumn('bd_dcd')" field="bd_dcd" header="BD/DCD" style="min-width: 60px;">
              <template #body="slotProps">
                {{ slotProps.data.donor.dcd_criteria_meets ? 'DCD' : 'BD' }}
              </template>
            </Column>
            <Column v-if="showColumn('donor.blood_type')" field="donor.blood_type" header="ABO" style="min-width: 50px" />            
            <Column v-if="showColumn('seq')" field="seq" header="Seq." style="min-width: 80px;">
              <template #body="slotProps">
                {{ (slotProps.data.match_effective_value && slotProps.data.match_effective_value.effective_seq) ? slotProps.data.match_effective_value.effective_seq : 'N/A' }}
              </template>
            </Column>
            <Column v-if="showColumn('rank')" field="rank" header="Rank" style="min-width: 100px;">
              <template #body="slotProps">
                {{ (slotProps.data.match_effective_value && slotProps.data.match_effective_value.effective_rank) ? slotProps.data.match_effective_value.effective_rank : 'N/A' }}
              </template>
            </Column>
            <Column v-if="showColumn('coordinator_note?.note')" field="coordinator_note?.note" header="Coordinator Note To File" style="min-width: 100px;">
              <template #body="slotProps">
                {{ slotProps.data.coordinator_note?.note }}
              </template>
            </Column>
            <Column v-if="showColumn('status.name')" field="status.name" header="Status" style="min-width: 100px;" />
            <Column class="btn-2-icon-group">
              <template #body="slotProps">
                <a href="#" title="Donor Details" class="action btn btn-fill btn-outline-primary mx-2" @click="showDonorDetails(slotProps.data)">
                  <font-awesome-icon class="desktop-action" :icon="['fas', 'file-lines']" /><span class="mobile-action">Donor Details</span>
                </a>
              </template>
            </Column>
          </template>
        </PortalTable>
      </template>
    </DashboardWidgetLayout>
    <ColumnConfigModal ref="columnConfigModal"
      v-model="selectedColumns"
      column-key="externalClinicalDashboard"
      config-id="external-clinical-dashboard"
      :option-defaults="getColumnDefaults"
      :options="getColumnOptions"
      @change-selected="onChangeSelectedColumns"
      @reload="reload" />
  </div>
  <div v-else class="alert alert-danger" role="alert">
    <span>You do not have permissions to see this page 
      <router-link :to="{ name: 'external-dashboards-root'}" title="TxC Home">TxC Home</router-link>
    </span>
  </div>
</template>

<script lang="js">
import beApiClient from "~/src/be-api-client";
import {APIRoute, EP} from "~/src/endpoints";
import DashboardWidgetLayout from "~/src/components/shared/DashboardWidgetLayout.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import ColumnConfigModal from "~/src/components/shared/column-config/ColumnConfigModal.vue";

export default {
  components: {
    DashboardWidgetLayout,
    PortalTable,
    Column,
    MultiSelectInput,
    ColumnConfigModal
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  data() {
    return {
      recordList: [],
      loading: false,
      pageCount: 1,
      totalRecords: 0,
      page: 1,
      selectedOrgans: null,
      organGroups: [],
      collapseText: "Hide",
      transplantCenters: [],
      selectedColumns: [],
      majorError: null
    }
  },
  computed: {
    getFormattedOrgans() {
      const formattedRows = this.organGroups.map((row) => {
        return {
          id: row.id,
          value: `${row.organ_code} - ${row.name}`
        }
      })
      return formattedRows;
    },
    currentUser() {
      return this.appStore.currentUser;
    },
    getColumnDefaults() {
      return [
        'donor.code',
        'code',
        'organ.organ_group.name',
        'transplant_center.code',
        'donor.opo.code',
        'bd_dcd',
        'donor.blood_type',
        'seq',
        'rank',
        'coordinator_note?.note',
        'status.name',
      ];
    },
    getColumnOptions() {
      return [
        { label: 'Donor', field: 'donor.code' },
        { label: 'Match', field: 'code' },
        { label: 'Organ', field: 'organ.organ_group.name' },
        { label: 'TC', field: 'transplant_center.code' },
        { label: 'OPO', field: 'donor.opo.code' },
        { label: 'BD/DCD', field: 'bd_dcd' },
        { label: 'ABO', field: 'donor.blood_type' },
        { label: 'Seq.', field: 'seq' },
        { label: 'Rank', field: 'rank' },
        { label: 'Coordinator Note To File', field: 'coordinator_note?.note' },
        { label: 'Status', field: 'status.name' }
      ];
    },
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        to: { name: 'external-dashboards-root' },
        text: 'Dashboards',
      },
      {
        text: 'Offers Dashboard',
      },
    ]);
    this.getBaseData();
    this.getRowData();
    this.selectedColumns = this.buildColumnsFilter();
    return;
  },
  methods: {
    showColumn(key) {
      return this.selectedColumns.includes(key);
    },
    onChangeSelectedColumns(newValues) {
      this.selectedColumns = newValues;
    },
    buildColumnsFilter() {
      // get selected columns from user preferences or defaults
      return this.appStore.currentUser.getColumnPreferences('externalClinicalDashboard', this.getColumnDefaults);
    },
    getSelectedColumns() {
      // get selected columns from user preferences or defaults
      return this.appStore.currentUser.getColumnPreferences('externalClinicalDashboard', this.getColumnDefaults);
    },
    openColumnConfigModal() {
      this.$refs['columnConfigModal'].resetModal();
      this.$refs['columnConfigModal'].showModal();
    },
    toggleCollapseText(event) {
      event.currentTarget.childNodes[0].textContent = event.currentTarget.childNodes[0].textContent === 'Show' ? "Hide" : "Show";
    },
    populateData(data, col) {
      if(!col) return;

      if( col.field === "donor-details" ) {
        this.showDonorDetails(data);
      }
    },
    showDonorDetails(row){
      this.$router.push({ 
        name: 'external-donor-details',
        params: { 
          id: row.id,
          donor_id: row.donor.id
        }, 
        query: {
          organ: row.organ.organ_group.name,
          tc: row.transplant_center.code
        } 
      });
    },
    filterRecords() {
      this.getRowData(true);
    },
    getRowData(reset = false) {
      const _vm = this;
      if (reset) this.page = 1;

      const params = {
        page_number: this.page, 
        organs: this.selectedOrgans ? this.selectedOrgans.join(',') : null
      }

      // Get data
      this.loading = true
      const url = APIRoute(EP.external_clinical.dashboard.index);
      beApiClient.get(url, {
        headers: {'Accept': 'application/json'},
        params: params })
      .then((response) => {
        _vm.totalRecords = response.data.total_records
        _vm.recordList = response.data.records;
        _vm.pageCount = response.data.page_count;
        _vm.loading = false
      })
      .catch((error) => {
        _vm.majorError = error.toString();
      })
      .finally(() => {
        _vm.loading = false;
      })
    },
    // Get all base data need for the form
    getBaseData() {
       this.loading = true;

      beApiClient.get(APIRoute(EP.admin.organ_groups.index)).then((response) => {
        this.organGroups = response.data;
      }).catch(e => {
        this.error = e;
      }).finally(() => { this.loading = false });
    },
    clearFilters() {
      this.selectedOrgans = null;
      this.page = 1;

      this.getRowData()
    },
    linkToOffer(row) {
      return row.email_url && row.email_url.length > 0 ? row.email_url : 'https://auth.unos.org/Login?redirect_uri=https%3A%2F%2Fportal.unos.org';
    },
    reload(){
      this.selectedColumns = this.buildColumnsFilter();
    },
    onPortalPage(page) {
      this.page = page;
      this.getRowData();
    },    
  }
}
  
</script>
