<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError">
    <template #header>
      Manage Membership Categories
    </template>

    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'membership_categories_new' }"
            class="btn btn-primary btn-sm ml-auto">
            Add New Category
          </router-link>
        </div>
      </div>

      <PortalTable table-id="membership_categories_index"
        mode="remote"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :table-data="records"
        :loading="isSavableStateLoading"
        filter-display="row"
        :filter-values="filterValues"
        empty="No Membership Categories found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="name"
            header="Category Name"
            filter-field="search"
            sortable
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by Category name"
                @input="filterCallback()" />
            </template>
          </Column>

          <Column field="domains"
            header="Authorized email domains">
            <template #body="slotProps">
              {{ slotProps.data.domains.toString() }}
            </template>
          </Column>

          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'membership_categories_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { useMembershipCategoryStore } from '@/src/stores/membership-category';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {urlParams} from "~/src/endpoints";
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import { paginationMixin } from "@/src/mixins/paginationMixin";

export default {
  components: {
    CardSection,
    PortalTable,
    Column,
    InputText,
  },
  mixins: [ savableStateMixin, paginationMixin ],
  setup() {
    const membershipCategoryStore = useMembershipCategoryStore();
    return {
      membershipCategoryStore
    }
  },
  data() {
    return {
      currentSortField: '',
      currentSortType: '',
      search : "",
      filterValues: {
        "search": { value: "", matchMode: FilterMatchMode.STARTS_WITH },
      }
    }
  },
  async mounted() {
    this.setSavableStateLoading();
    await this.getRowData();
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();

      if (reset) this.pageNumber = 1;

      if (this.currentSortField) {
        this.sort_params = urlParams({ sort_by: this.currentSortField, sort_type: this.currentSortType });
      } else {
        this.sort_params = '';
      }

      const params = {
        page: this.pageNumber,
        search: this.search,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };


      try {
        const response = await this.membershipCategoryStore.loadCategories(params)
        this.processPagyResponse(response);

        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    onFilterChange(event) {
      this.search = event.search;
      this.getRowData(true);
    },
  }
}
</script>
