<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError"
    header-class="title-bar">
    <template #header>
      {{ isNew ? 'New' : 'Edit' }}
      Procedure Note
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedProcedure" @submit="onSubmit">           
            <div class="row">
              <MultiSelectInput v-model="selectedProcedure.transplantCenter"
                name="transplantCenter"
                label="Transplant Center"
                :options="procedureStore.transplantCenters"
                label-key="code"
                value-key="id"
                rules="required"
                cache-context-key="admin.transplant_center"
                col-style="form-group-standard-column" />

              <MultiSelectInput v-model="selectedProcedure.organGroup"
                name="organ"
                label="Organ"
                :options="procedureStore.organGroups"
                label-key="name"
                value-key="id"
                rules="required"
                cache-context-key="admin.organ_group"
                col-style="form-group-standard-column" />
            </div>
            <template v-if="inEditMode">
              <div class="row">
                <TextArea v-model="selectedProcedure.procedureNote"
                  name="procedureNote"
                  label="Procedure Note"
                  rows="15"
                  cols="100" />
              </div>
            </template>

            <!-- save controls -->
            <ActionToolbar :component-state="currentState" 
              :success-message="successMessage" 
              :primary-button-text="inEditMode ? 'Save' : 'Next'"
              :secondary-button-text="secondaryButtonText"
              :error-message="formError"
              @secondary-click="secondaryButtonClick">
              <template v-if="duplicateProcedure" #message>
                <span>
                  A Procedure Note already exists for this combination. <br> Please click 
                  <a :href="`/ui/admin/procedure-notes/${duplicateProcedure}`" click.prevent="reloadPage">here</a> 
                  to edit that note, instead.
                </span>
              </template>
            </ActionToolbar>
          </VeeForm>
        </template>
      </SubSection>
      <confirm-dialogue ref="confirmDialogue"
        @confirm="editOffer"
        @cancel="showSearchForm" />
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import { Form as VeeForm } from 'vee-validate';
import TextArea from "@/src/components/shared/FormComponents/TextArea.vue";
import { useProcedureStore } from '@/src/stores/procedure_notes';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";

const EDIT_MODES = {
  VERIFYING: 'verifying',
  EDITING: 'editing'
}

/**
 * The component handles both the new and edit pages for Procedure notes. It uses the currentMode to handle
 * the states when dealing with the two step New Procedure note process
 */
export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    MultiSelectInput,
    TextArea,
    VeeForm,
  },
  mixins: [ savableStateMixin ],
  setup() {
    const procedureStore = useProcedureStore();
    return { procedureStore };
  },
  data() {
    return {
      currentMode: EDIT_MODES.VERIFYING,
      selectedProcedure: null,
      duplicateProcedure: null,
      successMessage: null,
    }
  },
  computed: {
    /**
     * Check if we are creating a new Procedure or editing an existing one
     */
    isNew() {
      return this.$route.params.id ? false : true;
    },
    inEditMode() {
      return this.currentMode === EDIT_MODES.EDITING;
    },
    inVerifyingMode() {
      return this.currentMode === EDIT_MODES.VERIFYING;
    },
    secondaryButtonText() {
      if(this.inEditMode && !this.isNew) {
        return 'Close';
      }
      
      return this.inEditMode && this.isNew ? 'Back' : 'Cancel' ;
    }
  },
  watch: { 
    '$route.params.id': {
      handler: function() {
        this.buildProcedure();
      }
    }
  },
  async mounted() {
    this.selectedProcedure = {};
    this.buildProcedure();
  },
   methods: {
    async buildProcedure() {
      try {
        this.setSavableStateLoading();
        this.duplicateProcedure = null;
        this.currentMode = this.isNew ? EDIT_MODES.VERIFYING : EDIT_MODES.EDITING;
        
        await this.procedureStore.loadTransplantCentres();
        await this.procedureStore.loadOrganGroups();
        
        // on edit
        if(!this.isNew) {
          const response = await this.procedureStore.loadProcedureNote(this.$route.params.id);
          this.setSelectedProcedure(response);
        }
        this.setSavableStateIdle();
        const history = this.$router.options.history.state;
        if (!history.replaced && history.back == '/ui/admin/procedure-notes/new') {
          // We need set time out to wait for the action toolbar to render first
          setTimeout(() => {
            this.successMessage = 'Successfully created procedure note!'
            this.setSavableStateSuccess();
          }, 100);
        }
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    onSubmit() {
      if(this.inEditMode) {
        this.submitProcedureNote();
      } else {
        this.validateProcedureNotes();
      }
    },
    async submitProcedureNote() {
      const payload = {
        organ_group_id: this.selectedProcedure.organGroup,
        transplant_center_id: this.selectedProcedure.transplantCenter,
        note: this.selectedProcedure.procedureNote,
      }
      try {
        this.setSavableStateProcessing();
        let response;
        if(this.isNew) {
          response = await this.procedureStore.createProcedureNotes(payload);
          this.$router.push({ name: 'admin_procedure_notes_edit', params: { id: response.id }});
        } else {
          payload.id = this.selectedProcedure.id;
          this.successMessage = 'Successfully updated procedure note!';
          response = await this.procedureStore.updateProcedureNotes(payload);
        }
        this.setSelectedProcedure(response);
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async validateProcedureNotes() {
      try {
        this.setSavableStateProcessing();
        this.duplicateProcedure = null;
        const payload = {
          organ_group_id: this.selectedProcedure.organGroup,
          transplant_center_id: this.selectedProcedure.transplantCenter,
        }
        const response = await this.procedureStore.validateProcedureNotes(payload);
       
        if(response) {
          this.duplicateProcedure = response.id;
          this.setSavableStateErrored();
        } else {
          this.currentMode = EDIT_MODES.EDITING;
          this.setSavableStateIdle();
        }
      } catch(error) {
        this.handleFormError(error);
      }
    },
    setSelectedProcedure(record) {
      this.selectedProcedure = {
        id: record.id,
        organGroup: record.organ_group_id,
        transplantCenter: record.transplant_center_id,
        procedureNote: record.note,
      }
    },
    /**
     * Handles the secondary button click in the action toolbar.
     * We want a two step process in case of new:
     * On Select TxC + organ group screen go back to index
     * if on step 2 of new then go back to step 1
     * 
     * When editing an exiting go to index
     */
    secondaryButtonClick() {
      if(this.isNew && this.inEditMode) {
        this.currentMode = EDIT_MODES.VERIFYING;
      } else {
        this.$router.push({ name: 'admin_procedure_notes' });
      }
    },
    reloadPage() {
    window.location.reload(); 
  },
  }
}
</script>
