<template>
  <div>
    <router-view name="PageHeaderBar" />
    <div class="content-wrap">
      <div class="container-fluid">
        <div id="administration-area" class="nav-wrapper">
          <nav id="side-nav" class="page-nav" :class="{ closed: sideNavClosed }">
            <LeftNavToolbar :class="{ closed: sideNavClosed }" @toggle="toggleSideNav">
              <template #return-link>
                <router-link class="nav-link no-scroll" title="Return to previous page" aria-label="A control that returns to the previous page" :to="{ name: 'internal-dashboards-root' }">
                  <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
                  <span class="sr-only">
                    Return to previous page
                  </span>
                </router-link>
              </template>
            </LeftNavToolbar>
            <AdminNav @toggle="toggleSideNav" />
          </nav>
          <div class="page-content">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import AdminNav from "~/src/components/admin-area/AdminNav.vue";
import LeftNavToolbar from "@/src/components/shared/LeftNavToolbar.vue";

export default {
  components: {
    LeftNavToolbar,
    AdminNav
  },
  data() {
    return {
      sideNavClosed: false
    }
  },
  methods: {
    toggleSideNav() {
      this.sideNavClosed = !this.sideNavClosed;
    }
  }
}
</script>
