<template>
  <div class="side-nav-container">
    <div class="nav-block-wrap">
      <hr>
      <template v-if="enabledNav.length > 0">
        <ul v-for="(nav,idx) of enabledNav" :key="idx" class="nav nav-block flex-column active">
          <li v-if="nav.enabled" class="nav-item">
            <span class="nav-link open router-link-exact-active router-link-active">
              <font-awesome-icon :icon="['fas', 'chevron-down']" />
              {{ nav.name }}
            </span>
            <ul v-for="(sub_section,index) of nav.sub_sections" :key="index" class="nav flex-column">
              <li v-if="sub_section.enabled" class="nav-item">
                <router-link :to="{ name: sub_section.url_path}" class="nav-link" @click.prevent="toggleSideNav">
                  {{ sub_section.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>
<script lang="js">
export default {
  props: {
    enabledNav: { required: true, type: Array },
  },
emits: ['toggle'],
  methods: {
    toggleSideNav() {
      const innerWidth = window.innerWidth;
      if (innerWidth < 991) {
        this.$emit('toggle');
      }
    }
  }
}
</script>