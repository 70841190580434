<template>
  <div v-if="teamGroup && teamGroup.disabledAt" class="row">
    <div class="col-sm-12">
      <div class="alert alert-danger" role="alert">
        <span>This Team Group is disabled.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    teamGroup: { required: true, type: Object }
  }
}
</script>
