<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      {{ isNew ? 'New' : 'Edit' }}
      Template
    </template>
    <template #body>
      <SubSection>
        <template #body>
          <VeeForm @submit="onSubmit">
            <!-- category select start -->
            <div class="row">
              <MultiSelectInput v-model="selectedTemplate.communicationTemplateCategory"
                name="communicationTemplateCategory"
                label="Template Category"
                :options="templateStore.categories"
                label-key="category_name"
                value-key="id"
                rules="required"
                :disabled="inEditMode"
                col-style="form-group-standard-column" />

              <MultiSelectInput v-model="selectedTemplate.organGroup"
                name="organ"
                label="Organ"
                :options="templateStore.organGroups"
                label-key="name"
                value-key="id"
                rules="required"
                :disabled="selectedTemplate.hasInstance"
                col-style="form-group-standard-column" />

              <MultiSelectInput v-model="selectedTemplate.transplantCenter"
                name="transplantCenter"
                label="Transplant Centre"
                :options="templateStore.transplantCenters"
                label-key="code"
                value-key="id"
                rules="required"
                :disabled="selectedTemplate.hasInstance"
                col-style="form-group-standard-column" />
            </div>
            <!-- category select end -->

            <template v-if="inEditMode">
              <div class="row">
                <TextArea v-model="selectedTemplate.templateNotes"
                  name="templateNotes"
                  label="Instructions/Notes To Coordinator"
                  rows="3"
                  cols="100" />
              </div>

              <!-- meta start -->
              <template v-for="(field, index) of categoryFields">
                <template v-if="field.control == 'input'">
                  <div :key="index" class="row">
                    <TextInput v-model="selectedTemplate.fields[field.id]"
                      :name="field.id"
                      :label="field.value" />
                  </div>
                </template>
                <template v-else>
                  <div :key="index" class="row">
                    <TextArea v-model="selectedTemplate.fields[field.id]"
                      :name="field.id"
                      :label="field.value"
                      rows="3"
                      cols="100" />
                  </div>
                </template>
              </template>
              <!-- meta end -->

              <div class="row">
                <TextArea v-model="selectedTemplate.templateBody"
                  name="templateBody"
                  rows="15"
                  cols="100"
                  :hide-label="true"
                  rules="required">
                  <template #header>
                    <div class="action-row d-flex">
                      <label for="templateBody">
                        Body <span class="text-danger">*</span>
                      </label>
                      <button class="btn btn-primary ml-auto"
                        @click.prevent="openVariableReference()">
                        Variable Reference
                      </button>
                    </div>
                  </template>
                </TextArea>
              </div>
            </template>

            <!-- save controls -->
            <ActionToolbar :component-state="currentState" 
              :secondary-enabled="inEditMode"
              :success-message="successMessage"
              :tertiary-enabled="!isNew && inEditMode"
              :primary-button-text="inEditMode ? 'Save Template' : 'Next'"
              :secondary-button-text="cancelText" 
              :error-message="formError"
              tertiary-button-text="Preview"
              :toolbar-size="isNew ? 'col-12' : 'col-md-6'"
              @secondary-click="cancel()" 
              @tertiary-click="openTemplatePreview()">
              <!-- duplicate message -->
              <template v-if="duplicateTemplate" #message>
                <span>
                  A template already exists for this combination.<br>
                  Please click 
                  <a href="#" @click.prevent="switchToTemplate()">here</a> 
                  to edit that instead.
                </span>
              </template>
            </ActionToolbar>
          </VeeForm>

          <ModalVariableReference ref="templateVariablesModal" />
          <ModalTemplatePreview v-if="selectedTemplate.id"
            ref="templatePreviewModal"
            :txc-code="translateTxcCode(selectedTemplate.transplantCenter)"
            :category-name="translateCategoryName(selectedTemplate.communicationTemplateCategory)"
            :template-id="selectedTemplate.id" />
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import ModalVariableReference from "@/src/components/admin-area/templates/ModalVariableReference.vue";
import ModalTemplatePreview from "@/src/components/admin-area/templates/ModalTemplatePreview.vue";
import { useTemplateStore } from '@/src/stores/template';
import SubSection from "@/src/components/shared/SubSection.vue";
import { Form as VeeForm } from 'vee-validate';
import { storeToRefs } from 'pinia'
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import TextArea from "@/src/components/shared/FormComponents/TextArea.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";

const EDIT_MODES = {
  VERIFYING: 'verifying',
  EDITING: 'editing'
}

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    ModalVariableReference,
    ModalTemplatePreview,
    TextInput,
    TextArea,
    MultiSelectInput
  },
  mixins: [ savableStateMixin ],
  setup() {
    const templateStore = useTemplateStore();
    const { getCategoryTypes, translateTxcCode, translateCategoryName } = storeToRefs(templateStore)
    return {
      templateStore, getCategoryTypes, translateTxcCode, translateCategoryName
    }
  },
  data() {
    return {
      currentMode: EDIT_MODES.VERIFYING,
      selectedTemplate: {
        communicationTemplateCategory: null,
        organGroup: null,
        transplantCenter: null,
        templateNotes: null,
        templateBody: null,
        fields: {},
        hasInstance: false
      },
      duplicateTemplate: null,
      categoryFields: null,
      successMessage: null,
      newTemplate: null
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id;
    },
    inEditMode() {
      return this.currentMode === EDIT_MODES.EDITING;
    },
    cancelText() {
      return this.isNew ? 'Back' : 'Close';
    }
  },
  watch: { 
    '$route.params.id': {
      // use the function syntax to access this.
      handler: function() {
        this.buildTemplate();
      }
    }
  },
  async mounted() {
    localStorage.removeItem("donor");
    localStorage.removeItem("match");
    await this.buildTemplate();
  },
  methods: {
    async buildTemplate() {
      try {
        this.setSavableStateLoading();
        this.duplicateTemplate = null;
        this.currentMode = this.isNew ? EDIT_MODES.VERIFYING : EDIT_MODES.EDITING;
        
        await this.templateStore.loadCategories();
        await this.templateStore.loadCategoryTypes();
        await this.templateStore.loadTransplantCentres();
        await this.templateStore.loadOrganGroups();
        
        // on edit
        if(!this.isNew) {  
          const template = await this.templateStore.loadTemplate(this.$route.params.id)
          // generate template from existing data
          await this.loadTemplateForm(template);
        }
        this.setSavableStateIdle();
        const history = this.$router.options.history.state;
         if (!history.replaced && history.back == '/ui/admin/templates/new' && this.newTemplate) {
          // We need set time out to wait for the action toolbar to render first
          setTimeout(() => {
            this.successMessage = 'Successfully created template!';
            this.setSavableStateSuccess();
          }, 100);
        }
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    onSubmit() {
      if(this.inEditMode) {
        this.onSubmitTemplate();
      } else {
        this.verifyTemplate()
      }
    },
    async verifyTemplate() {
      this.setSavableStateProcessing();
      try {
        // check for duplicate
        const duplicate = await this.templateStore.verifyTemplate(
          this.selectedTemplate.communicationTemplateCategory,
          this.selectedTemplate.organGroup,
          this.selectedTemplate.transplantCenter);
        // if duplicate found, show link
        if (duplicate && duplicate.id) {
          this.duplicateTemplate = duplicate.id; 
        }
        this.formError = ''; // Let the other error take over
        this.setSavableStateErrored();
      } catch(error) {
        // no duplicate found, find what fields the category has
        this.categoryFields = await this.templateStore.loadCategoryFields(this.selectedTemplate.communicationTemplateCategory)
        // switch to step 2
        this.duplicateTemplate = false;
        this.currentMode = EDIT_MODES.EDITING;
        this.setSavableStateIdle();
      }
    },
    async onSubmitTemplate() {
      // build payload
      const payload = {
        communication_template_category_id: this.selectedTemplate.communicationTemplateCategory,
        organ_group_id: this.selectedTemplate.organGroup,
        transplant_center_id: this.selectedTemplate.transplantCenter,
        template_notes: this.selectedTemplate.templateNotes,
        template_body: this.selectedTemplate.templateBody,
        fields: this.selectedTemplate.fields
      }

      try {
        this.setSavableStateProcessing();
        if (this.isNew) {
          this.newTemplate = true
          const response = await this.templateStore.createTemplate(payload);
          this.selectedTemplate.hasInstance = response.has_instance;
          this.$router.push({ name: 'admin_communication_template_edit', params: { id: response.id, isNew: true }});
        } else {
          this.successMessage = 'Successfully updated template!';
          payload.id = this.selectedTemplate.id;
          const response = await this.templateStore.updateTemplate(payload);
          this.loadTemplateForm(response);
        }
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    jumpToIndex() {
      this.$router.push({ name: 'admin_communication_templates' });
    },
    cancel() {
      if (this.isNew) {
        // second cancel button should go back one step
        this.currentMode = EDIT_MODES.VERIFYING;
      } else {
        // otherwise go to template index
        this.$router.push({ name: 'admin_communication_templates' });
      }
    },
    switchToTemplate() {
      this.$router.push({
        path: `/ui/admin/templates/${this.duplicateTemplate}`,
        reload: true
      });
    },
    openVariableReference() {
      this.$refs['templateVariablesModal'].showModal();
    },
    openTemplatePreview() {
      this.$refs['templatePreviewModal'].reset();
      this.$refs['templatePreviewModal'].showModal();
    },
    async loadTemplateForm(template) {
      this.selectedTemplate = {
        id: template.id,
        communicationTemplateCategory: template.communication_template_category.id,
        organGroup: template.organ_group.id,
        transplantCenter: template.transplant_center.id,
        templateNotes: template.template_notes || null,
        templateBody: template.template_body || null,
        fields: template.fields,
        hasInstance: template.has_instance
      }
      // find what fields this category has
      this.categoryFields = await this.templateStore.loadCategoryFields(this.selectedTemplate.communicationTemplateCategory)
    }
  }
}
</script>
