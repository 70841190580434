<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      Edit User {{ selectedUser ? `- ${selectedUser.full_name}` : '' }}
    </template>
    <template #body>
      <SubSection sub-section-heading="Manage Team Memberships" :sub-section-nav="subSectionNav">
        <template #body>
          <template v-if="!isSavableStateLoading && selectedUser">
            <UserDisabled :user="selectedUser" />

            <VeeForm ref="addMembershipForm"
              @submit="onAddMembership">
              <div class="row">
                <MultiSelectInput v-model="selectedTeams"
                  name="team"
                  label="Team(s)"
                  :options="teamsMissingFromUser"
                  label-key="name"
                  value-key="id"
                  col-style="form-group-5-column-large col-xl-5"
                  :multiple="true"
                  rules="required" />

                <MultiSelectInput v-model="selectedRole"
                  name="role"
                  label="Role"
                  :options="userStore.roles"
                  label-key="role_name"
                  value-key="id"
                  col-style="form-group-5-column-large col-xl-4"
                  rules="required" />
              </div>

              <ActionToolbar :component-state="currentState" 
                :primary-button-text="`Add Membership`"
                :success-message="`Successfully Updated User!`"
                toolbar-size="col-md-6"
                :secondary-enabled="false"
                :error-message="formError" /> 
            </VeeForm>

            <hr>

            <UserTeamMembershipsTable :team-memberships="selectedUser ? selectedUser.team_memberships : []"
              :selected-user="selectedUser"
              :loading="isSavableStateLoading || isSavableStateProcessing"
              @memberships-updated="reloadUser()" />
          </template>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import '@/src/vee-validate.js';
import { Form as VeeForm } from 'vee-validate';
import { useUserStore } from '@/src/stores/user';
import { pageMixin } from "@/src/mixins/pageMixin";
import UserDisabled from "@/src/components/admin-area/users/_userDisabled.vue";
import {userSubSectionNavMixin} from "@/src/components/admin-area/users/userSubSectionNavMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import UserTeamMembershipsTable from "./_userTeamMembershipsTable.vue";

export default {
  components: {
    CardSection,
    SubSection,
    MultiSelectInput,
    VeeForm,
    UserTeamMembershipsTable,
    UserDisabled,
    ActionToolbar
  },
  mixins: [ pageMixin, userSubSectionNavMixin, savableStateMixin ],
  setup() {
    const userStore = useUserStore();
    return {
      userStore
    }
  },
  data() {
    return {
      majorError: null,
      formError: null,
      selectedUser: null,
      selectedRole: null,
      selectedTeams: null,
      teamsMissingFromUser: [],
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.userStore.loadTeams();
      await this.userStore.loadRoles();
      await this.loadUser();
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
    this.loading = false;
  },
  methods: {
    async loadTeamsMissingFromUser() {
      const teamsMissingFromUser = await this.userStore.loadTeamsMissingFromUser(this.$route.params.id);
      this.teamsMissingFromUser = teamsMissingFromUser.data || [];
    },
    
    async onAddMembership() {
      try {
        this.setSavableStateProcessing();
        let teamMemberships = [];
        this.selectedTeams.forEach((teamId) => {
          teamMemberships.push({
            team_id: teamId,
            role_id: this.selectedRole
          });
        });
        await this.userStore.addMembership(this.$route.params.id, { team_memberships: teamMemberships });
        await this.loadUser();
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleMajorError(error);
      }
      
    },
    async loadUser(){
      // reload user and reset form
      this.selectedTeams = null;
      this.selectedRole = null;
      this.selectedUser = await this.userStore.loadUser(this.$route.params.id);

      if(this.$refs.addMembershipForm) {
        await this.$refs.addMembershipForm.resetForm();
      }
    
      await this.loadTeamsMissingFromUser();
    },

    async reloadUser() {
      try {
        this.setSavableStateProcessing();
        await this.loadUser();
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    }
  }
}
</script>