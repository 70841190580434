<template>
  <div v-if="aroRule && aroRule.disabledAt" class="row">
    <div class="col-sm-12">
      <div class="alert alert-danger" role="alert">
        <span>This Auto rule out is disabled</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aroRule: { required: true, type: Object }
  }
}
</script>
