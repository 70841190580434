// This mixin helps to copy text to the clipboard.
export const clipboardMixin = {
  data() {
    return {
      copied: false,  // Indicates whether text was copied successfully.
      majorError: ''
    };
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.copied = true;
        // Reset copied after 2 seconds.
        setTimeout(() => {
          this.copied = false;
        }, 2000);
        this.setSavableStateIdle();
      }).catch((error) => {
        this.majorError = `Could not copy text: ${error}`;
      });
    }
  }
}