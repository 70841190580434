export const formMixin = {
  props: {
    modelValue: { default: null },
    name: { required: true },
    label: { required: true },
    rules: { default: null },
    readOnly: { default: false },
    disabled: { default: false },
  },
  computed: {
    getErrorName() {
      return `${this.name}_error`
    },
    getValue() {
      return this.modelValue
    },
    isRequired() {
      return this.rules && this.rules.includes("required")
    }
  },
  methods: {
    hasErrors(errors) {
      return (errors || []).length > 0;
    },
  }
}