<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      Edit User {{ selectedUser ? `- ${selectedUser.full_name}` : '' }}
    </template>
    <template #body>
      <SubSection sub-section-heading="Advanced" :sub-section-nav="subSectionNav">
        <template #body>
          <template v-if="!isSavableStateLoading && selectedUser">
            <UserDisabled :user="selectedUser" />
            <div class="bordered-section">
              <UserInvite :user="selectedUser" />
            </div>
            <div class="bordered-section">
              <UserActivate :user-deactivated-at="selectedUser.deactivated_at"
                :user-id="userId" 
                @load-user="loadUser" /> 
            </div>
            <div class="bordered-section">
              <UserVirtualNumber :user="selectedUser" @update="loadUser" />
            </div>
          </template>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import UserDisabled from "@/src/components/admin-area/users/_userDisabled.vue";
import { useUserStore } from '@/src/stores/user';
import { pageMixin } from "@/src/mixins/pageMixin";
import {userSubSectionNavMixin} from "@/src/components/admin-area/users/userSubSectionNavMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import UserInvite from "@/src/components/admin-area/users/_userInvite.vue";
import UserActivate from "@/src/components/admin-area/users/_userActivate.vue";
import UserVirtualNumber from "@/src/components/admin-area/users/_userVirtualNumber.vue";
  
  export default {
    components: {
      CardSection,
      SubSection,
      UserDisabled,
      UserInvite,
      UserActivate,
      UserVirtualNumber
    },
    mixins: [ pageMixin, savableStateMixin, userSubSectionNavMixin ],
    setup() {
      const userStore = useUserStore();
      return {
        userStore
      }
    },
    data() {
      return {
        selectedUser: null,
        userId: this.$route.params.id
      }
    },
    async mounted() {
      try {
        this.setSavableStateLoading();
        this.loadUser(await this.userStore.loadUser(this.$route.params.id))
        this.setSavableStateSuccess();
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    methods: {
      loadUser(user) {
        this.selectedUser = {
          id: user.id,
          full_name: user.full_name,
          email: user.email,
          deactivated_at: user.deactivated_at,
          virtual_phone_number: user.virtual_phone_number,
          membership_categories: user.membership_categories.map((item) => {
            return item.id
          }),
          team_memberships: user.team_memberships
        }
      },
    }
  }
</script>
