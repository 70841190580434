import AdminIntegrationsRoot from "@/src/components/admin-area/integrations/AdminIntegrationsRoot.vue";
import WorkflowMappingIndex from "@/src/components/admin-area/integrations/omni-life/WorkflowMappingIndex.vue";
import UserMappingIndex from "@/src/components/admin-area/integrations/omni-life/UserMappingIndex.vue";
import { setAdminBreadCrumb } from "@/src/routes/admin-routes.js";

export const adminIntegrationRoutes = [
  {
    path: 'integrations',
    beforeEnter: [ setAdminBreadCrumb ],
    children: [
      {
        path: '',
        name: 'admin_integrations_root',
        component: AdminIntegrationsRoot,
        beforeEnter: [ setAdminBreadCrumb ]
      },
      {
        path: 'omnilife',
        children: [
          {
            path: '',
            name: 'admin_integrations_omnilife',
            component: AdminIntegrationsRoot,
            beforeEnter: [ setAdminBreadCrumb ]
          },
          {
            path: 'workflow_mappings',
            name: 'admin_integrations_omnilife_workflow_mappings',
            component: WorkflowMappingIndex,
            beforeEnter: [ setAdminBreadCrumb ]
          },
          {
            path: 'user_mappings',
            name: 'admin_integrations_omnilife_user_mappings',
            component: UserMappingIndex,
            beforeEnter: [ setAdminBreadCrumb ]
          }
        ]
      }
    ]
  }
]