<template>
  <SingleNumberChart chart-id="total-transplanted-organs"
    :config="chartConfig"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError"
    :header="`Transplanted Organs`"
    @previewReport="previewReport"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SingleNumberChart from "@/src/components/shared/charts/SingleNumberChart.vue";

export default {
  components: {
    SingleNumberChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],
  data() {
    return {
      chartConfig: {
        fieldName: 'total_transplants',
        tooltip: 'Total number of transplanted organs',
      },
      aro: false,
      transplanted: true,
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getSingleChartResult(EP.external_analytics.total_transplanted_organs, filterParameters);
    },
  },
}
</script>
