<!-- eslint-disable vue/no-v-html -->
<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }} Role
    </template>
   
    <template #body>
      <VeeForm @submit="onSubmit">
        <div class="row">
          <TextInput v-model="role.role_name"
            name="name"
            label="Role Name"
            rules="required"
            col-style="form-group-5-column-large" />
        </div>

        <PortalTable ref="role_permissions_index"
          table-id="role_permissions_index"
          :table-data="selected_role_permissions"
          :loading="isSavableStateProcessing"
          sort-field="area"
          :sort-order="1"
          :row-style="rowStyle"
          mode="local"
          empty="No Permissions found!"
          edit-mode="cell">
          <template #headers>
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <h3 class="mt-2" style="padding-left: .75rem;">
                  Manage Permissions
                </h3><hr>
                <p style="padding-left: .75rem;">
                  General note:
                </p>
                <ul class="mb-1">
                  <li>Menu headings are not visible unless there are permissions enabled for features within them.</li>
                  <li>Areas should be added as Read only; other permissions should be added as Create, Read, and Update.</li>
                </ul>
              </div>
            </div>
          </template>
          <template #columns>
            <Column field="entity" 
              header="Permission">
              <template #body="slotProps">
                {{ slotProps.data.entity }}
              </template>
            </Column>
            <Column field="create" 
              header="Create">
              <template #body="slotProps">
                <input :id="`permission_create-${slotProps.data.id}`"
                  v-model="slotProps.data.create"
                  aria-labelledby="create"
                  type="checkbox" class="form-check-input">
              </template>
            </Column>
            <Column field="read" 
              header="Read">
              <template #body="slotProps">
                <input :id="`permission_read-${slotProps.data.id}`"
                  v-model="slotProps.data.read"
                  aria-labelledby="read"
                  type="checkbox" class="form-check-input">
              </template>
            </Column>
            <Column field="write" 
              header="Update">
              <template #body="slotProps">
                <input :id="`permission_write-${slotProps.data.id}`"
                  v-model="slotProps.data.write"
                  aria-labelledby="write"
                  type="checkbox" class="form-check-input">
              </template>
            </Column>
            <Column field="description" 
              header="Description" style="min-width: 130px;">
              <template #body="slotProps">
                <div v-html="slotProps.data.description" />
              </template>
            </Column>
          </template>
        </PortalTable>
        
        <ActionToolbar :component-state="currentState" 
          :error-message="formError" 
          :success-message="successMessage" 
          primary-button-text="Save Role"
          @secondary-click="cancel" />
      </VeeForm>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { Form as VeeForm } from 'vee-validate';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { useRoleStore } from '@/src/stores/role';
import { usePermissionStore } from '@/src/stores/permission';
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';

export default {
  components: {
    CardSection,
    ActionToolbar,
    TextInput,
    VeeForm,
    PortalTable,
    Column
  },
  mixins: [ savableStateMixin ],
  setup() {
    const roleStore = useRoleStore();
    const permissionStore = usePermissionStore();
    return { roleStore, permissionStore };
  },
  data() {
    return {
      selectedRole: null,
      errorMessage: null,
      role: {
        id: '',
        role_name: '',
        role_permissions: [],
        selected_permission_id: ''
      },
      addNewPermission: false,
      permissions: [],
      selected_role_permissions:[],
    }
  },
  computed: {
    createNew() {
      return this.$route.params.id ? false : true;
    }
  },
  async mounted() {
    this.setSavableStateLoading();
    this.selectedRole = {};

    try {
      await this.permissionStore.loadPermissions();

      if(!this.createNew) {
        // on edit
        const response = await this.roleStore.loadRole(this.$route.params.id)
        this.role = {
          id: response.id,
          role_name: response.role_name,
          role_permissions: response.role_permissions
        }
      } 
      this.loadForm();
      this.setSavableStateIdle();

    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    onSubmit() {
      this.role.role_permissions = this.selected_role_permissions;

      if (this.createNew) {
        this.create(this.role);
      } else {
        this.update(this.role);
      }
     
    },
    async create(payload) {
      try {
        this.setSavableStateProcessing();

        const response = await this.roleStore.createRole(payload);
        this.role = {
          id: response.id,
          role_name: response.role_name,
          role_permissions: response.role_permissions
        }
        this.$router.push({ name: 'admin_roles_edit', params: { id: response.id }});
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    async update(payload) {
      this.setSavableStateProcessing();
      try {
        await this.roleStore.updateRole(payload)
        this.setSavableStateSuccess();
      } catch (error) {
        this.handleFormError(error);
      }
    },
    
    loadForm() {
      this.selected_role_permissions = [];

      this.permissionStore.permissions.forEach((p) => {
          const role_permission = this.role?.role_permissions?.find((rp) => {
            return rp.permission.entity == p.entity;
          });
          const area = p.entity.split('_')[0] == 'area' ? p.entity.split('_')[1] : p.entity;
          this.selected_role_permissions.push({
            id: p.id,
            entity: p.entity,
            area: area,
            role_permission_id: role_permission?.id,
            create: role_permission?.create,
            read: role_permission?.read,
            write: role_permission?.write,
            description: p.description
          });
        });
    },
    rowStyle(data) {
      if (data.entity.split('_')[0] === 'area') {
        return { fontWeight: 'bold', background: '#f2f2f2' };
      } else {
        return null;
      }
    },
    cancel() {
      this.$router.push({ name: 'admin_roles' });
    },
  }
}
</script>