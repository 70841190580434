<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError">
    <template #header>
      Comms Templates
    </template>

    <template #body>
      <VeeForm v-if="instance" @submit="onSubmit">
        <!-- category select start -->
        <div class="row">
          <MultiSelectInput v-model="instance.categoryId"
            name="communicationTemplateCategory"
            label="Select Category"
            :options="templateStore.categories"
            label-key="category_name"
            value-key="id"
            rules="required"
            col-style="form-group-standard-column"
            @change="changeTemplate()" />
        </div>

        <div class="row">
          <TextInput v-model="instance.reset_datetime"
            col-style="form-group-standard-column"
            name="communicationTemplateLastReset"
            label="Last Reset"
            :disabled="true" />
          <TextInput v-model="instance.lastSaved"
            col-style="form-group-standard-column"
            name="communicationTemplateLastSaved"
            label="Last Saved"
            :disabled="true" />
        </div>

        <div class="row">
          <TextArea v-model="instance.templateNotes"
            name="communicationTemplateNotes"
            label="Instructions/Notes To Coordinator"
            rows="3"
            cols="100"
            :disabled="true" />
        </div>
        <!-- meta start -->
        <template v-for="(field, index) of instance.categoryFields">
          <template v-if="field.control == 'input'">
            <div :key="index" class="row">
              <TextInput v-model="instance.categoryFieldValues[field.id]"
                :name="field.id"
                :label="field.value"
                :disabled="true" />
            </div>
          </template>
          <template v-else>
            <div :key="index" class="row">
              <TextArea v-model="instance.categoryFieldValues[field.id]"
                :name="field.id"
                :label="field.value"
                rows="3"
                cols="100"
                :disabled="true" />
            </div>
          </template>
        </template>
        <!-- meta end -->

        <br>
        <div class="row">
          <TextArea v-model="instance.instance_text"
            name="communicationTemplateBody"
            rows="15"
            cols="100"
            :hide-label="true"
            rules="required"
            @change="setDirty()" />
        </div>

        <ActionToolbar :component-state="currentState" 
          toolbar-size="col-md-6"
          :secondary-enabled="true"
          :tertiary-enabled="true"
          primary-button-text="Save"
          secondary-button-text="Reset"
          info-message="Copied to clipboard."
          :error-message="formError"
          tertiary-button-text="Copy"
          @secondary-click="onReset()" 
          @tertiary-click="copyToClipboard(instance.instance_text)" />
      </VeeForm>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useTemplateStore } from '@/src/stores/template';
import { Form as VeeForm } from 'vee-validate';
import { pageMixin } from "@/src/mixins/pageMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import {APIRoute, EP} from "@/src/endpoints";
import beApiClient from "@/src/be-api-client";
import { storeToRefs } from 'pinia'
import {useAppCoreStore} from "@/src/stores/app-core";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import TextArea from "@/src/components/shared/FormComponents/TextArea.vue";
import { formatDateTimeTotoLocaleString } from "@/src/date-helpers";

export default {
  components: {
    CardSection,
    ActionToolbar,
    VeeForm,
    MultiSelectInput,
    TextInput,
    TextArea
  },
  mixins: [ pageMixin, savableStateMixin ],
  beforeRouteLeave (to, from, next) {
    // if unsaved changes, warn user
    if (this.dirty) {
      const answer = confirm('You have unsaved changes! Are you sure you want to leave this page?')
      if (answer) { next(); }
    } else { 
      next(); 
    }
  },
  props: {
    donor: { required: true, type: String},
    match: { required: true, type: String },
    txc: { required: true, type: String },
    organGroup: { required: true, type: String },
    instanceId: { required: true, type: String },
  },
  setup() {
    const templateStore = useTemplateStore();
    const appStore = useAppCoreStore();
    const { translateOrganGroup, translateTxcCode } = storeToRefs(templateStore)
    return {
      templateStore, appStore, translateTxcCode, translateOrganGroup
    }
  },
  data() {
    return {
      loading: true,
      formError: null,
      majorError: null,
      donorDetails: null,
      matchDetails: null,
      dirty: false,

      originalInstance: null,
      instance: null
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading()
      // get donor & match details
      await this.templateStore.loadTransplantCentres();
      await this.templateStore.loadOrganGroups();
      // get categories list by organ & txc
      await this.templateStore.loadCategoryTypesByOrganGroupAndTxc(this.organGroup, this.txc);

      const donorDetails = await beApiClient.get(APIRoute(EP.donors.show, { id: this.donor }));
      const matchDetails = await beApiClient.get(APIRoute(EP.matches.show, { id: this.match }));
      if (!donorDetails.data || !matchDetails.data) { this.setSavableStateErrored(); return false;  }
      this.donorDetails = donorDetails.data;
      this.matchDetails = matchDetails.data;

      // build instance
      this.buildInstance();

      // setup breadcrumb url
      this.setupBreadCrumb();

      this.dirty = false;
      this.setSavableStateIdle();
    } catch(error) {
      this.majorError = error;
      this.setSavableStateErrored();
    }
  },
  methods: {
    setDirty() {
      this.dirty = true;
    },
    setupBreadCrumb() {
      this.appStore.setAppBreadcrumbs([
        {
          to: { name: 'internal-dashboards-root' },
          text: 'Internal'
        },
        {
          to: { name: 'internal-dashboard-plugin' },
          text: 'Dashboard'
        },
        {
          to: { name: 'edit-instance', 
                params: { 
                  donor: this.donor, 
                  match: this.match, 
                  txc: this.txc, 
                  organGroup: this.organGroup }
          },
          text: this.generateBreadCrumb(
            this.donorDetails.donor.code,
            this.matchDetails.code,
            this.translateTxcCode(this.txc),
            this.translateOrganGroup(this.organGroup)
          )
        }
      ]);
    },
    async copyToClipboard(string) {

      // this.setSavableStateProcessing(); // probably don't need the save spinner for copy to clipboard
      navigator.clipboard.writeText(string).then(() => {
        this.setSavableStateInfo();
      
        setTimeout(() => {
          this.setSavableStateIdle();
        }, 2000);
      }).catch((error) => {
        console.log('error', error);
        this.setSavableStateErrored();
      });
    },
    async changeTemplate() {
      // if change made, warn user before switching
      if (this.dirty) {
        const answer = confirm('You have unsaved changes! Are you sure you want to leave this page?')
        if (!answer) {
          // no, stay on page
          this.instance.categoryId = this.originalInstance.categoryId;
          return false;
        }
      }

      const categoryId = this.instance.categoryId;
      const found = this.templateStore.categories.find((item) => { return item.id === categoryId; });
      const instanceExists = await this.templateStore.latestTemplateInstance(this.match, categoryId)

      if (instanceExists.length > 0) {
        // if instance exists for category, switch to the edit page

        const instanceId = instanceExists[0].id;

        // problem with router, if change route, does not change the page TOFIX
        window.location = `${window.location.origin}/ui/internal/instances/edit-instance/${this.donor}/${this.match}/${this.txc}/${this.organGroup}/${instanceId}`;

      } else {
        // load template (to find out what meta fields it has)
        const communication_template_id = found.communication_templates[0].id;
        // const template = await this.templateStore.loadTemplate(communication_template_id)

        const response = await this.templateStore.generateInstanceBody(communication_template_id, this.match)

        // build payload to generate first instance of template
        const payload = {
          communication_template_id: communication_template_id,
          instance_text: response.template_body
        }

        // generate first instance of template
        const responseOnSave = await this.templateStore.generateTemplateInstanceFromMatch(this.match, payload)
        const instanceId = responseOnSave.id;

        // problem with router, if change route, does not change the page TOFIX
        window.location = `${window.location.origin}/ui/internal/instances/edit-instance/${this.donor}/${this.match}/${this.txc}/${this.organGroup}/${instanceId}`;
      }
    },
    async buildInstance() {
      const instance = await this.templateStore.loadTemplateInstance(this.match, this.instanceId)

      this.instance = {
        communication_template_id: instance.communication_template_id,
        id: instance.id,
        instance_text: instance.instance_text,
        match_id: instance.match_id,
        reset_datetime: formatDateTimeTotoLocaleString(instance.effective_last_reset),
        lastSaved: instance.last_saved ? formatDateTimeTotoLocaleString(instance.updated_at) : 'N/A',
        updated_at: formatDateTimeTotoLocaleString(instance.updated_at),
        templateNotes: instance.instance_template_notes,
        categoryFieldValues: instance.instance_template_field_values,
        categoryId: instance.template_instance_category.id,
        categoryFields: instance.category_fields
      }

      // keep a copy of instance if need to revert a value;
      this.originalInstance = Object.assign({}, this.instance);

      this.dirty = false;
    },
    async onSubmit() {
      this.formError = null;
      this.setSavableStateProcessing()
      try {
        const found = this.templateStore.categories.find((item) => {
          return item.id === this.instance.categoryId;
        });
        const communication_template_id = found.communication_templates[0].id;

        // build payload to generate first instance of template
        const payload = {
          communication_template_id: communication_template_id,
          instance_text: this.instance.instance_text
        }

        // update instance
        const response = await this.templateStore.updateTemplateInstance(
          this.match, this.instance.id, payload)
        
        // update instance
        this.buildInstance();

        this.setSavableStateSuccess();
      } catch (error) {
        this.formError = error;
        this.setSavableStateErrored();
      }
    },
    async onReset() {
      if(!confirm('Resetting will discard any of your saved changes. Are you sure you want to continue?')) {
        return;
      }

      this.formError = null;
      this.setSavableStateProcessing();
      try {
        // reset instance
        const instance = await this.templateStore.resetTemplateInstance(this.match, this.instance.id)
        // reset form
        this.buildInstance();
        this.setSavableStateSuccess();


      } catch (error) {
        this.formError = error;
        this.setSavableStateErrored();
      }
    }
  }
}
</script>

