<template class="page-content">
  <NewOffers />
</template>

<script lang="js">
import NewOffers from "@/src/components/buckeye-coordinator-area/manual_offers/ManageOffer.vue";

export default {
  components: {
      NewOffers
  }
}
</script>
