<template>
  <div class="content-wrap page-top">
    <div class="container-fluid d-flex justify-content-between">
      <div class="page-top-breadcrumbs">
        <template v-if="breadcrumbs.length > 0">
          <h2 v-for="(crumb, idx) of breadcrumbs" :key="`breadcrumb-${idx}`">
            <router-link :to="crumb.to" v-if="crumb.to">
              {{ crumb.text }}
            </router-link>
            <template v-else>
              {{ crumb.text }}
            </template>
            <template v-if="idx !== (breadcrumbs.length-1)">&nbsp;/&nbsp;</template>
          </h2>
        </template>
        <h2 v-else>
          <slot />
        </h2>
        <h2 v-if="showTransplantCenterBanner()" class="ml-2">
          <span class="transplant-centers">
            {{ showTransplantCenters() }}
          </span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";

// Which routes should we show the Transplant Center banner?
const PATHS_WITH_TRANSPLANT_CENTER_BANNER = [
  '/ui/external/dashboards/available-offers',
  '/ui/external/dashboards/analytics',
  '/ui/external/dashboards/reports',
];

export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  computed: {
    breadcrumbs() {
      return this.appStore.appBreadcrumbs;
    },
  },
  methods: {
    showTransplantCenterBanner() {
      // show transplant center banner on specific external dashboard pages
      // use this.$route as window.location will not show update when path changed
      return this.$route.matched.some(({ path }) => PATHS_WITH_TRANSPLANT_CENTER_BANNER.includes(path));
    },
    getTransplantCenters() {
      const currentUser = this.appStore.currentUser;
      const profileInfo = currentUser && currentUser.profileInfo || null;
      return profileInfo && profileInfo.transplant_centers_with_access ? profileInfo.transplant_centers_with_access : [];
    },
    showTransplantCenters() {
      const centers = this.getTransplantCenters();
      if (centers.length > 0) {
        const centerName = `${centers[0].code} - ${centers[0].name}`
        return centers.length == 1 ? centerName : `${centerName}, AND OTHER CENTRES`;
      }
    },
  }
}
</script>
