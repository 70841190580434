<template>
  <div class="row">
    <div class="col-sm-12 mb-3">
      <p>Send this user an invite / re-invite email. This will ask the user to create a new password.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <ActionToolbar :component-state="currentState"
        :error-message="formError" 
        success-message="Invite successfully sent!" 
        :primary-disabled="user.deactivated_at"
        primary-button-text="Send Invite"
        :secondary-enabled="false"
        @primary-click.prevent="onSubmitInvite()" />
      <p v-if="user.deactivated_at" class="text-secondary">
        The user must be enabled before an invitation can be sent. 
      </p>
    </div>
  </div>
</template>
<script>
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useUserStore } from '@/src/stores/user';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
export default {
  components: {
    ActionToolbar
  },
  mixins: [ savableStateMixin ],
  props: {
    user: { required: true, type: Object }
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore
    }
  },
  methods: {
    async onSubmitInvite() {
      try {
        this.setSavableStateProcessing();
        await this.userStore.inviteUser(this.user.id);
        this.setSavableStateSuccess()
      } catch(error) {
        this.handleFormError(error);
      }
    },  
  }
}
</script>