<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }} Team  {{ selectedTeam.teamName ? `- ${selectedTeam.teamName}` : '' }}
    </template>

    <template #body>
      <SubSection :sub-section-heading="createNew ? '' : 'Edit'" :sub-section-nav="subSectionNav">
        <template #body>
          <VeeForm v-if="selectedTeam" @submit="onSubmit">
            <div class="row">
              <TextInput v-model="selectedTeam.teamName" name="teamName" 
                label="Team Name"
                rules="required"
                col-style="form-group-standard-column" />
            </div>
            <div class="row">
              <MultiSelectInput v-model="selectedTeam.membershipCategoryId"
                name="membershipCategory"
                label="Membership Category"
                :options="teamStore.membershipCategories"
                label-key="name"
                value-key="id"
                rules="required"
                col-style="form-group-standard-column" />
            </div>

            <h3 class="mt-4">
              Manage Team Access
            </h3>

            <div class="row">
              <template v-for="(ta, idx) of selectedTeam.teamAccess" :key="ta.id">
                <div class="col-12">
                  <div class="row">
                    <MultiSelectInput v-model="ta.transplant_center_id"
                      name="team-transplant-center"
                      label="Transplant Center"
                      :options="teamStore.allTransplantCenters"
                      label-key="code"
                      value-key="id"
                      col-style="col-md-5" />

                    <MultiSelectInput v-model="ta.organ_group_id"
                      name="team-organ-group"
                      label="Organ Group"
                      :options="teamStore.organGroups"
                      label-key="name"
                      value-key="id"
                      col-style="col-md-5" />
                    <div class="col-md-2">
                      <label aria-hidden="true" class="d-block">&nbsp;</label>
                      <button class="btn btn-danger" @click.prevent="deleteTeamAccess(idx)">
                        <font-awesome-icon :icon="['fas', 'times']" />
                      </button>
                    </div>
                  </div>
                </div>
              </template>

              <div class="col-12 mt-3 mb-3">
                <button class="btn btn-primary" @click.prevent="addTeamAccess()">
                  <font-awesome-icon :icon="['fas', 'circle-plus']" />
                  Add New Team Access
                </button>
              </div>
            </div>

            <ActionToolbar primary-button-text="Save Team"
              :component-state="currentState"
              :success-message="successMessage"
              :error-message="formError"
              :secondary-button-text="createNew ? 'Cancel' : 'Close'"
              @secondary-click="cancel" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useTeamStore } from '@/src/stores/team';
import SubSection from "@/src/components/shared/SubSection.vue";
import { Form as VeeForm } from 'vee-validate';
import '@/src/vee-validate.js';
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {teamSubSectionNavMixin} from "@/src/components/admin-area/teams/teamSubSectionNavMixin";

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    TextInput,
    MultiSelectInput,
  },
  mixins: [ savableStateMixin, teamSubSectionNavMixin ],
  setup() {
    const teamStore = useTeamStore();
    return { teamStore };
  },
  data() {
    return {
      selectedTeam: {
        teamAccess: [],
        teamName: null,
        membershipCategoryId: null
      },
      successMessage: null
    }
  },
  computed: {
    createNew() {
      return this.$route.params.id ? false : true;
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.teamStore.loadAllTransplantCentres();
      await this.teamStore.loadTransplantCentres();
      await this.teamStore.loadOrganGroups();
      await this.teamStore.loadMembershipCategories();
      if (!this.createNew) {
        await this.loadTeam(this.$route.params.id);                                                                                                                       
      }
      this.setSavableStateIdle(); 

      
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    async loadTeam(id) {
      const record = await this.teamStore.loadTeam(id);
      this.setSelectedTeam(record);
    },
    setSelectedTeam(record) {
      this.selectedTeam = {
        id: record.id,
        teamName: record.name,
        membershipCategoryId: record.membership_category_id,
        teamAccess: record.team_accesses,
      }
    },
    async onSubmit() {
      let response;
      this.setSavableStateProcessing();
      const payload = {
        name: this.selectedTeam.teamName,
        membership_category_id: this.selectedTeam.membershipCategoryId,
        team_accesses: this.selectedTeam.teamAccess
          .map(ta => {
          return {
            transplant_center_id: ta.transplant_center_id,
            organ_group_id: ta.organ_group_id
          }
        })
      }

      try {
        if (this.createNew) {
          response = await this.teamStore.createTeam({team: payload});
          this.successMessage = 'Successfully created team!'
          this.$router.push({name: 'admin_teams_edit', params: {id: response.id}});
        } 
        else {
          payload.id =this.selectedTeam.id,
          response = await this.teamStore.updateTeam({ team: payload });
          this.successMessage = 'Successfully updated team!'
        } 
        this.setSavableStateSuccess();
        this.setSelectedTeam(response);
      }catch(error) {
        this.handleFormError(error)
      }
    },
    deleteTeamAccess(idx) {
     if(this.selectedTeam && this.selectedTeam.teamAccess[idx].transplant_center && this.selectedTeam.teamAccess[idx].organ_group && !confirm(`Are you sure you want to delete this team access (Transplant center:${this.selectedTeam.teamAccess[idx].transplant_center.code} - Organ group:${this.selectedTeam.teamAccess[idx].organ_group.name}) ?`)) {
        return;
      }

      this.selectedTeam.teamAccess.splice(idx, 1);
    },
    addTeamAccess() {
      this.selectedTeam.teamAccess[this.selectedTeam.teamAccess?.length || 0] = {
        id: this.selectedTeam.teamAccess?.length || 0,
        transplant_center_id: null,
        organ_group_id: null
      };
    },
    cancel() {
      this.$router.push({ name: 'admin_teams' });
    },
  }
}
</script>
