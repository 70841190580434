<template>
  <CardSection :error="error" 
    :loading="loading"
    header-class="title-bar">
    <template #header>
      Reports 
    </template>
    <template #body>
      <fieldset>
        <legend>
          <h4 class="legend-title">
            Buckeye QA reports
          </h4>
        </legend>
        <UploadReport />
      </fieldset>
    </template>
  </CardSection>
</template>

<script lang="js">
  import CardSection from "@/src/components/shared/CardSection.vue";
  import UploadReport from '@/src/components/admin-area/reports/UploadReport.vue';

  export default {
    components: {
      CardSection,
      UploadReport,
    }
  }
</script>
