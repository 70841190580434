export const errorMessages = {
  en: {
    messages: {
      integer: "Must be an integer",
      float: "Must be a numeric value",
      email: "Invalid email address",
      boolean: "Must be checked",
      required: "This value is required",
      date: "Required format is: YYYY-MM-DD",
      datetime: "Must be a valid date and time",
    }
  }
}