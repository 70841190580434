/**
 * This mixin defines actions/methods that a component would need to implement if
 * it was a modal that was being rendered. This takes care of abstracting away the bootstrap modal
 * show / hide which are based on jquery for now
 */

export const modalActionsMixin = {
  methods: {
    showModal() {
      if(!this.modalRef) {
        alert('No modalRef defined. Define it as a prop, data or computed. This should not make it to production.');
      }
      $(this.$refs[this.modalRef].$el).modal('show')
    },
    closeModal() {
      this.$emit('modalClosed');
    }
  }
}
