<template>
  <LeftNav :enabled-nav="enabledUserNav" />
</template>

<script lang="js">
import {useAppCoreStore} from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";
import LeftNav from "@/src/components/shared/LeftNav.vue";

/**
 * This array controls the items that appear in the left nav.
 * Array contains Objects for each section. 
 * {
 *    name: 'Title of the Section', // required
 *    subsections: [ // required. The actual Links to display in the subsection. Each link is an object
 *      {
 *        name: 'Text of the link', // required.
 *        url_path: 'string_name_of_the_vue_router_path', // required.
 *        permission_key: PermissionNames.XXX_YYYY // required. The permission to check if the user has it or not, use PermissionNames enum
 *        check_feature: (settings) > { return BOOLEAN BASED ON FEATURE CHECK } // Optional function to check if a feature should be checked before enabling
 *      }
 *    ]
 *  
 * }
 */
const UserAreaNavs = [
  {
    name: 'Manage my profile',
    sub_sections: [
      {
        name: 'Contact information',
        url_path: 'user_manage_profile',
        permission_key: PermissionNames.INTERNAL_USER_MANAGE_PROFILE,
      },
      {
        name: 'Manage on call',
        url_path: 'user_manage_on_call',
        permission_key: PermissionNames.INTERNAL_USER_MANAGE_ON_CALL,
      }
      
    ],
  },
]
export default {
  components: {
    LeftNav
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    enabledUserNav() {
      if(!this.user || !this.userPermissions) return [];
      return UserAreaNavs.map((user_nav) => {
        user_nav.sub_sections.map((sub) =>{
          const nav_perm = this.userPermissions[sub.permission_key];
          sub.enabled = nav_perm ? (nav_perm.read || false) : false;
          if(sub.check_feature) {
            // Have permission, but feature disabled, then it will be disabled.
            sub.enabled = sub.check_feature(this.appStore.applicationSettings) && sub.enabled ? true : false;
          }
          return sub;
        })
        user_nav.enabled = user_nav.sub_sections.some(sub => sub.enabled);
        return user_nav;
      })
    },
  }
}
</script>
