<template>
  <div class="row">
    <div class="col-12">
      <form @submit.prevent="triggerSearch">
        <fieldset>
          <div class="row">
            <div class="col-sm-12">
              <form id="offer-search-form" @submit.prevent="triggerSearch()">
                <div class="row">
                  <div class="standard-form-group">
                    <label for="donorId" class="required">
                      Donor Id
                    </label>
                    <input id="donorId"
                      v-model="donor"
                      type="text"
                      pattern="[A-Z]{4}\d{3}"
                      title="DonorID should contain 7 character (4 letters followed by 3 numbers)."
                      maxlength="7"
                      class="form-control"
                      required>
                  </div>
                  <div class="standard-form-group">
                    <label for="match" class="required">
                      Match
                    </label>
                    <input id="match"
                      v-model="match"
                      type="text"
                      pattern="[0-9]*"
                      title="Match should contain 7 numeric."
                      maxlength="7"
                      class="form-control"
                      required>
                  </div>
                  <MultiSelectInput v-model="tc"
                    name="transplant_center"
                    label="TxC"
                    :options="transplantCenters"
                    label-key="code"
                    value-key="code"
                    col-style="standard-form-group"
                    rules="required" />
                </div>
                <div class="row">
                  <div class="save-toolbar-column">
                    <button type="submit" class="btn btn-success">
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
</template>

<script lang="js">
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";

export default {
  components: {
    MultiSelectInput
  },
  props: {
    transplantCenters: {
      required: true,
      default() {
        return []
      },
      type: Array
    }
  },
emits: ['search'],
  data() {
    return {
      donor: null,
      match: null,
      tc: null
    }
  },
  watch: {
    donor(newVal, _oldVal) {
      if(this.donor) {
        this.donor = this.donor.toUpperCase();
      }
    }
  },
  mounted() {
    // Incoming url format : https://portal.unos.org/DonorNet/DonorContainer.aspx?fp=Y&Don_Id=AKAA167&MatchId=1469048&Mode=add&from=view&PageName=TXC_Console_Poll.aspx&ctr_cd=${tc}&ctr_ty=TX1&TRKR=***
    if(this.$route.query) {
      // Check which of the formats we are coming from, UNOS or internal
      if(this.$route.query.Don_Id) {
        this.donor = this.$route.query.Don_Id;
        this.match = this.$route.query.MatchId;
        this.tc = this.$route.query.tc;
      }
    }
  },
  methods: {
    triggerSearch() {
      if(!this.donor || !this.match || !this.tc) return;
      this.$emit('search', {
        donor_code: this.donor,
        match_code: this.match,
        tc: this.tc
      });
    }
  }
}
</script>
