<template>
  <div v-if="reportData && reportData.columns" class="table-responsive">
    <div class="alert alert-dark" role="alert">
      Previewing {{ reportData.rows.length }} records
    </div>
    <table class="table table-striped table-bordered">
      <thead class="thead-dark" style="font-weight: bold;">
        <tr>
          <td v-for="(k, index) of reportData.columns" :key="index">
            {{ k }}
          </td>
        </tr>
      </thead>
      <tr v-for="(row, index) of reportData.rows" :key="index">
        <td v-for="(k, idx) of reportData.columns" :key="idx">
          {{ row[k] }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    reportData: {
      type: Object,
      default() {
        return { }
      }
    }
  }
}
</script>