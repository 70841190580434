import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors } from "@/src/utils";

export const useMembershipCategoryStore = defineStore('membership-category', {
  state: ()=> {
    return {
      // lookup data
      categories: [],
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    loadPaginatedEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, {
          headers: {'Accept': 'application/json'},
          params: params })
          .then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    loadCategories(params) {
      return this.loadPaginatedEntity(APIRoute(EP.admin.membership_categories.filter_categories), 'membership_categories', params)
    },
    // Load Category to Store
    loadCategory(id) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.admin.membership_categories.show, { id: id })).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },

    // Save Category
    updateCategory(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.patch(APIRoute(EP.admin.membership_categories.update, { id: payload.id }), payload).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },

    // Save Category
    createCategory(payload) {
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.admin.membership_categories.create), payload).then(response => {
          resolve(response.data);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
  }
});