<template>
  <div class="invalid-feedback" :id="`${name}-error`" v-if="errors.length > 0">
    <span v-for="(error, index) in errors" :key="`recipient-client-id-error-${index}`">
      <font-awesome-icon :icon="['fas', 'exclamation-circle']" fixed-width /> {{ error }}
    </span>
  </div>
</template>

<script lang="js">
export default {
  props: {
    name: null,
    errors: []
  }
}
</script>
