<template>
  <SinglePercentageChart chart-id="percent-auto-rule-outs-for-fully-declined-offers"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError"
    :header="`Auto Rule Outs for Fully Declined Offers`"
    @previewReport="previewReport"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SinglePercentageChart from "@/src/components/shared/charts/SinglePercentageChart.vue";

export default {
  components: {
    SinglePercentageChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],

  data() {
    return {
      aro: true,
      transplanted: false,
    };
  },

  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getSingleChartResult(EP.external_analytics.percent_auto_rule_outs_for_fully_declined_offers, filterParameters);
    },
  },
}
</script>
