<template>
  <div>
    <!-- External Reports -->
    <DashboardWidgetLayout widget-id="external-reports-txc-offers-full"
      widget-title="Transplant Center Reports"
      :widget-icon="['fas', 'file-lines']"
      :enable-filters="false">
      <template #content>
        <div class="row">
          <ReportSetView report-set-base-url="/external/report_sets/transplant_center"
            :report-set-default-params="defaultParams" />
        </div>
      </template>
    </DashboardWidgetLayout>
  </div>
</template>

<script lang="js">
import { useAppCoreStore } from "@/src/stores/app-core";
import ReportSetView from "@/src/components/rundown/ReportSetView.vue";
import DashboardWidgetLayout from "~/src/components/shared/DashboardWidgetLayout.vue";

export default {
  components: {
    DashboardWidgetLayout,
    ReportSetView,
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  data() {
    return {
      error: '',
      loading: false,
      organGroups: [],
      defaultParams: {},
    };
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        to: { name: 'external-dashboards-root' },
        text: 'Dashboards',
      },
      {
        text: 'Reports Dashboard',
      },
    ]);
    this.setDefaultParams();
  },
  methods: {
    setDefaultParams() {
      const queryParams = this.$route.query || {};
      Object.assign(this.defaultParams, queryParams);
    },
  }
}
</script>
