<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Edit Team {{ selectedTeam == null ? '' : `- ${selectedTeam.name}` }}
    </template>

    <template #body>
      <SubSection sub-section-heading="Manage Groups" :sub-section-nav="subSectionNav">
        <template #body>
          <div class="form-group row">
            <div class="form-group mr-2 mb-0 col-12 d-flex">
              <router-link :to="{ name: 'admin_teams_edit_groups_new' }" 
                class="btn btn-primary btn-sm ml-auto">
                Add New Group
              </router-link>
            </div>
          </div>

          <PortalTable ref="admin_teams_groups_index"
            table-id="admin-teams-groups-index"
            :table-data="records"
            :total-records="totalRecords"
            :page="pageNumber"
            :page-count="pageCount"
            :loading="isSavableStateProcessing"
            :filter-values="filterValues"
            filter-display="row"
            empty="No team groups found!"
            @on-sort="handleSortChange"
            @on-page="handlePageChange"
            @on-filter-change="onFilterChange">
            <template #columns>
              <Column field="name" header="Group Name" style="min-width: 100px;" 
                :sortable="true"
                filter-field="name" 
                :show-filter-menu="false">
                <template #filter="{ filterModel = { value: null }, filterCallback }">
                  <InputText v-model="filterModel.value"
                    type="text"
                    class="p-column-filter"
                    placeholder="Search by Group name"
                    @input="filterCallback()" />
                </template>
              </Column>
              <Column header="Service Levels" style="min-width: 14rem"
                filter-field="service_level"
                :show-filter-menu="false"
                :filter-menu-style="{ width: '14rem' }">
                <template #filter>
                  <MultiSelectFilter ref="service_level" 
                    filter-field="service_level"
                    option-label="name"
                    option-value="code"
                    class="form-control form-control-select"
                    :options="serviceLevelOptions"
                    @change="onChange" />
                </template>
                <template #body="slotProps">
                  {{ enabledServiceLevelsString(slotProps.data.service_levels) }}
                </template>
              </Column>
              <Column header="Active" style="min-width: 170px;"
                filter-field="active"
                :show-filter-menu="false"
                :filter-menu-style="{ width: '14rem' }">
                <template #filter>
                  <MultiSelectFilter ref="active" 
                    filter-field="active"
                    option-label="name"
                    option-value="code"
                    class="form-control form-control-select"
                    :options="activeOptions"
                    @change="onChange" />
                </template>
                <template #body="slotProps">
                  {{ slotProps.data.disabled_at ? 'No' : 'Yes' }}
                </template>
              </Column>
              <Column header="Actions">
                <template #body="slotProps">
                  <ActionToolbar :component-state="editRowData.currentState[slotProps.data.id]" 
                    :primary-button-text="`Edit`"
                    :secondary-button-text="slotProps.data.disabled_at ? 'Make Active' : 'Make Inactive'"
                    primary-style="outline-primary action btn-fill mx-2"
                    secondary-style="outline-primary action btn-fill mx-2"
                    :success-message="editRowData.message[slotProps.data.id]"
                    :error-message="editRowData.message[slotProps.data.id]"
                    toolbar-size="col-md-12"
                    :secondary-enabled="true"
                    @primary-click="editGroup(slotProps.data)" 
                    @secondary-click="slotProps.data.disabled_at ? onSubmitEnable(slotProps.data) : onSubmitDisable(slotProps.data)" />
                </template>
              </Column>           
            </template>
          </PortalTable>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import { useTeamStore } from '@/src/stores/team';
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import {teamSubSectionNavMixin} from "@/src/components/admin-area/teams/teamSubSectionNavMixin";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { pageMixin } from "@/src/mixins/pageMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import InputText from 'primevue/inputtext';
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { ServiceLevels } from "~/src/enums.js";

export default {
  components: {
    CardSection,
    SubSection,
    PortalTable,
    Column,
    InputText,
    MultiSelectFilter,
    ActionToolbar
  },
  mixins: [ savableStateMixin, teamSubSectionNavMixin, pageMixin, paginationMixin ],
  setup() {
   const teamStore = useTeamStore();
   return {
      teamStore
    }
  },
  data() {
    return {
      selectedTeam: null,
      editRowData: {
        groupId: {},
        message: {},
        currentState: {}
      },
      activeOptions: [
        { code: "enabled", name: "Yes" },
        { code: "disabled", name: "No" }
      ],
      serviceLevelOptions: Object.values(ServiceLevels),
      filterValues: {
        "service_level" : { value: null, matchMode: FilterMatchMode.IN },
        "active" : { value: null, matchMode: FilterMatchMode.IN },
        "name" : { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      }
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      this.selectedTeam = await this.teamStore.loadTeam(this.$route.params.id);
      await this.getRowData();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    editGroup(data){
      this.$router.push({ name: 'admin_teams_edit_groups_edit', params: { team_id: data.team_id, group_id: data.id } });
    },
    enabledServiceLevelsString(data) {
      const serviceLevels = [];
      if(data.triage.enabled) { 
        serviceLevels.push(ServiceLevels.TRIAGE.name);
      }
      if(data.offer_handling.enabled){
        serviceLevels.push(ServiceLevels.OFFER_HANDLING.name);
      }
      if(data.case_handling.enabled){
        serviceLevels.push(ServiceLevels.CASE_HANDLING.name);
      }
      return serviceLevels.join(' | ');
    },
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      this.clearEditRowData();
      if (reset) this.pageNumber = 1;

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
      };

      params = { ...this.search_params, ...params };
      try {
        const response = await this.teamStore.loadTeamGroups(this.$route.params.id,params);
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    },
    async onSubmitDisable(data) {
      this.setSavableStateProcessing();
      this.clearEditRowData();
      if(!confirm(`Are you sure you want disable group: ${data.name}?`)) {
        this.setSavableStateIdle();
        return;
      }

      try {
        await this.teamStore.disableTeamGroup(this.$route.params.id, data.id)
        this.setSavableStateSuccess();
        await this.getRowData();
        this.editRowData.groupId[data.id] = null;
        this.editRowData.currentState[data.id] = this.SaveStates.Success;
        this.editRowData.message[data.id] = 'Successfully Updated!';
      } catch(error) {
        this.editRowData.currentState[data.id] = this.SaveStates.Errored;
        this.editRowData.message[data.id] = error;
      }
    },
    async onSubmitEnable(data) {
      this.setSavableStateProcessing();
      this.clearEditRowData();
      if(!confirm(`Are you sure you want enable group: ${data.name}?`)) {
        this.setSavableStateIdle();
        return;
      }

      try {
        await this.teamStore.enableTeamGroup(this.$route.params.id, data.id)
        await this.getRowData();        
        this.setSavableStateSuccess();
        this.editRowData.groupId[data.id] = null;
        this.editRowData.currentState[data.id] = this.SaveStates.Success;
        this.editRowData.message[data.id] = 'Successfully Updated!';
      } catch(error) {
        this.editRowData.currentState[data.id] = this.SaveStates.Errored;
        this.editRowData.message[data.id] = error;
      }
    },
    onChange(event) {
      this.$refs.admin_teams_groups_index.resetFilters(event);
    },
    clearEditRowData() {
      this.editRowData = {
        groupId: {},
        message: {},
        currentState: {}
      }
    },
    onFilterChange(event) {
      if(event["service_level"] === "") this.$refs.service_level.clearFilters();
      if(event["active"] === "") this.$refs.active.clearFilters();

      this.search_params = event;
      this.getRowData(true);
    }
  }
}
</script>
