<template>
  <SingleNumberChart chart-id="total-offers"
    :config="chartConfig"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError"
    :header="`Total Offers`"
    @previewReport="previewReport"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SingleNumberChart from "@/src/components/shared/charts/SingleNumberChart.vue";

export default {
  components: {
    SingleNumberChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],
  data() {
    return {
      chartConfig: {
        tooltip: 'The total number of offers to your transplant center',
      },
      aro: false,
      transplanted: false,
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getSingleChartResult(EP.external_analytics.total_offers, filterParameters);
    },
  }
}
</script>

<style scoped>
  .chart-section-header-actions {
    float: right;
  }
</style>
