<template>
  <!-- Modal -->
  <ModalLayout :ref="modalRef" modal-id="donorDetailsModal"
    :loading="isSavableStateLoading">
    <template #modal-header>
      <h5 v-if="donor_details && recipient_details" class="modal-title">
        {{ `Donor: ${donor_details.donor.code} | Match: ${match.code}-${match.organ.name} | TC: ${match.transplant_center.code} ` }}
      </h5>
      <h5 v-else>
        Loading match details
      </h5>
    </template>
    <template #modal-body>
      <div v-if="donor_details && recipient_details" class="row">
        <div class="col-sm-12">
          <DonorDetails :details="donor_details" :recipient_details="recipient_details" :match="match" />
        </div>
      </div>
    </template>
  </ModalLayout>
</template>

<script lang="js">
import DonorDetails from "@/src/components/buckeye-coordinator-area/DonorDetails.vue";
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import {APIRoute, EP} from "@/src/endpoints";
import beApiClient from "@/src/be-api-client";

export default {
  components: {
    DonorDetails,
    ModalLayout
  },
  mixins: [savableStateMixin, modalActionsMixin],
  props: {
    matchId: { required: true, type: String },
    donorId: { required: true, type: String }
  },
  emits: ['close'],
  data() {
    return {
      modalRef: 'donor-details-modal',
      match: null,
      donor_details: null,
      recipient_details: null
    }
  },
  watch: {
    matchId(newRow, _oldRow) {
      this.getModalData();
      this.disableSaving = false;
    },
    donorId(newRow, _oldRow) {
      this.getModalData();
      this.disableSaving = false;
    }
  },
  mounted() {
    this.getModalData();
  },
  methods: {
    getModalData() {
      if((!this.matchId || !this.donorId) || this.isSavableStateLoading) return;

      this.setSavableStateLoading();
      this.resetDonorDetails();
      const match_details_ep = APIRoute(EP.matches.show, { id: this.matchId });
      const recipients_ep = APIRoute(EP.matches.recipients.index, { match_id: this.matchId });
      const donor_details_ep = APIRoute(EP.donors.show, { id: this.donorId });

      Promise.all([
        beApiClient.get(recipients_ep, { params: { limit: 10 }}),
        beApiClient.get(donor_details_ep),
        beApiClient.get(match_details_ep)
      ]).then((response) => {
        const recipient_data = response[0].data;
        const donor_data = response[1].data;
        const match_data = response[2].data
        this.recipient_details = recipient_data;
        this.donor_details = donor_data;
        this.match = match_data;
      }).finally(() => {
        this.setSavableStateIdle();
      })
    },
    resetDonorDetails() {
      this.match = null;
      this.donor_details = null;
      this.recipient_details = null;
    },
    closeModal(){
      this.$emit("close");
    }
  }
}
</script>
