<template>
  <modal-layout 
    :ref="modalRef"
    @close="closeModal">
    <template v-slot:modal-header>
      <h5 class="modal-title">
        Customize Columns
      </h5>
    </template>
    <template v-slot:modal-body>
      <div class="row">
        <!-- Iterate through each group of options/checkboxes -->
        <div
            v-for="quintuple in groupsOf(options, 3)" :key="quintuple.key"
            class="col-md-4"
          >
          <ul class="nav flex-column">
            <li v-for="option in quintuple.options" :key="option.code" class="mb-2">
              <div class="checklist border-0 form-check">
                <input type="checkbox"
                  class="form-check-input"
                  :id="`${configId}-${option[fieldKey]}`"
                  :checked="modelValue.includes(option[fieldKey])"
                  @change="onCheckboxChange(option[fieldKey], $event)"
                />
                <label 
                  class="form-check-label selected"
                  :for="`${configId}-${option[fieldKey]}`">
                  <span>
                    <slot name="label">
                      {{ option.label }}
                    </slot>
                  </span>
                </label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 mb-2 save-toolbar action-row">
          <button @click="saveColumns" :disabled="disabled" class="btn btn-success">
            Save
          </button>
          <button class="btn btn-outline-secondary" data-dismiss="modal" aria-label="close" @click="closeModal">
            Close
          </button>
          <Message class="inline-flex" ref="messageComponent"/>
        </div>
      </div>
    </template>
  </modal-layout>
</template>

<script lang="js">
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {modalActionsMixin} from "@/src/mixins/modalActionsMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { SaveStates } from "~/src/enums.js";
import Message from "@/src/components/shared/FormComponents/_message.vue";
import { useAppCoreStore } from "@/src/stores/app-core";
import { useUserStore } from '@/src/stores/user';

export default {
  components: {
    ModalLayout,
    Message
  },
  props: {
    // Required props
    modelValue: { required: true },
    columnKey: { required: true },
    optionDefaults: { required: true },
    configId: { required: true },
    options: { required: true },

    // Optional props
    labelKey: { default: 'label' },
    fieldKey: { default: 'field' },
    hiddenOptions: { default: [] },
  },
  mixins: [ modalActionsMixin, savableStateMixin ],
  data() {
    return {
      preview: null,
      modalRef: 'columnConfigModal',
      errorMessage: null,
    }
  },
  setup() {
    const appStore = useAppCoreStore();
    const userStore = useUserStore();
    return {
      appStore, userStore
    }
  },
  methods: {
    groupsOf(array, splitBy = 3) {
      if (!array) {
        return [];
      }
      // Store options in groups of (splitBy)
      let index = 0;
      let arrayLength = array.length;
      let tempArray = [];
    
      for (index = 0; index < arrayLength; index += splitBy) {
        let newChunk = array.slice(index, index + splitBy);
        // push new chunk in the new array
        tempArray.push(newChunk);
      }

      // attach key to each group
      let key;
      let codes;
      const results = tempArray.map((group) => {
        codes = group.map((option) => {
          const field = option[this.fieldKey];
          return field;
        });
        key = codes.join('-');
        return {
          key: key,
          options: group,
        };
      });

      return results
    },
    resetModal() {
      this.$refs.messageComponent.reset();
    },
    onCheckboxChange(key, event) {
      const ticked = event.target.checked;
      const newValue = Array.from(this.modelValue);
      if (ticked) {
        // Add ticked field to overall model value if not already present
        const index = newValue.indexOf(key);
        if (index == -1) {
          newValue.push(key);
        }
      } else {
        // Remove unticked checkbox field from overall model value if present
        const index = newValue.indexOf(key);
        if (index > -1) {
          newValue.splice(index, 1);
        }
      }
      // Sanitize value and report the change
      const result = [...new Set(newValue)];
      this.$emit('changeSelected', result);
    },
    extractColumnSettings() {
      const newPreferences = this.appStore.currentUser.getPreferences(); // get preferences
      newPreferences[this.columnKey] = this.modelValue; // apply value to this section of preferences
      return newPreferences; // return altered preferences
    },
    async saveColumns() {
      const newState = this.extractColumnSettings();
      const userId = this.appStore.currentUser.profileInfo.id;
      this.$refs.messageComponent.reset();

      try {
        const response = await this.appStore.updatePreferences(newState);
        this.$refs.messageComponent.setMessage(SaveStates.Success, 'Preferences Successfully Updated!');
      } catch(error) {
        this.$refs.messageComponent.setMessage(SaveStates.Errored, error);
      }
    },
    closeModal() {
      this.$emit('reload');
    },
  }
}
</script>
