<template>
  <div class="row">
    <div class="save-toolbar-column">
      <button type="submit" :disabled="disabled || isSavableStateProcessing" :class="`btn btn-${saveButtonClass}`" @click="emitSave($event)">
        {{ saveButtonText }}
        <font-awesome-icon v-if="isSavableStateProcessing" :icon="['fas', 'fa-spinner']" class="fa-spin" />
      </button>
      <button v-if="cancelButton" class="btn btn-outline-secondary" :disabled="(disabled || isSavableStateProcessing) && disabledCancelButton" @click.prevent="emitCancel()">
        {{ cancelButtonText }}
      </button>

      <div v-if="showMessage" class="alert message-component"
        :class="{ 'alert-danger': currentState === SaveStates.Errored, 'alert-success': currentState === SaveStates.Success }"
        role="alert">
        <button type="button" class="close" aria-label="Close" @click="clearState">
          <font-awesome-icon :icon="['fas', 'fa-times']" aria-hidden="true" />
        </button>
        {{ currentMessage }}
      </div>
    </div>
  </div>
</template>

<script lang="js">
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import {SaveStates} from "@/src/enums";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

/**
 * This component provides a standard way to manage form buttons (save, cancel, etc) and any messages related
 * to those actions.
 *
 * Provides two buttons, one submit and one regular.
 *
 */
export default {
  components: {FontAwesomeIcon},
  mixins: [savableStateMixin],
  props: {
    // Show save button or not
    saveButton: { default: true, type: Boolean },
    // Text to display for the save button
    saveButtonText: { default: 'Save', type: String },
    // Class to override the save button style (success, info, danger)
    saveButtonClass: { default: 'success', type: String },
    // Show secondary button or not
    cancelButton: { default: true, type: Boolean },
    // Text to display on the secondary button
    cancelButtonText: { default: 'Cancel', type: String },
    // Disable the buttons from doing anything
    disabled: { default: false, type: Boolean},
    // Disable the cancel button from doing anything
    disabledCancelButton: { default: false, type: Boolean},
    // Current state of the component, uses SaveStates enum. Understands: Blank (default), Processing, Success and Errored
    componentState: { default: SaveStates.Blank, type: String },
    // Success Message to show
    successMessage: { default: 'Saved Successfully!', type: String },
    // Error Message to show
    errorMessage: { default: 'An error occurred!', type: String }
  },
  emits: ['save', 'cancel'],
  computed: {
    showMessage() {
      return [this.SaveStates.Success, this.SaveStates.Errored].includes(this.currentState);
    },
    currentMessage() {
      if(this.currentState === this.SaveStates.Success) {
        return this.successMessage;
      } else if(this.currentState === this.SaveStates.Errored) {
        return this.errorMessage;
      } else {
        return '';
      }
    }
  },
  watch: {
    // We need an internal state, so we use a bridge as it ensures
    // that parent is always becomes the source of truth if it changes.
    componentState(newVal) {
      this.currentState = newVal;
    }
  },
  methods: {
    emitSave(e) {
      this.$emit('save', e);
    },
    emitCancel() {
      this.$emit('cancel');
    },
    clearState() {
      this.currentState = this.SaveStates.Blank;
    }
  }
}
</script>
