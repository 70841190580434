<template>
  <CardSection :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Manage Users
    </template>

    <template #body>
      <div class="form-group row">
        <div class="form-group mr-2 mb-0 col-12 d-flex">
          <router-link :to="{ name: 'admin_users_new' }" 
            class="btn btn-primary btn-sm ml-auto">
            Add New User
          </router-link>
        </div>
      </div>

      <PortalTable ref="users_index"
        table-id="users_index"
        :table-data="records"
        :total-records="totalRecords"
        :page="pageNumber"
        :page-count="pageCount"
        :loading="isSavableStateProcessing"
        :filter-values="filterValues"
        filter-display="row"
        empty="No users found!"
        @on-sort="handleSortChange"
        @on-page="handlePageChange"
        @on-filter-change="onFilterChange">
        <template #columns>
          <Column field="full_name" 
            header="User Name" 
            style="min-width: 100px;" 
            filter-field="full_name" 
            sortable
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by User Name"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="email" 
            header="Email" 
            style="min-width: 100px;" 
            filter-field="email" 
            :show-filter-menu="false">
            <template #filter="{ filterModel = { value: null }, filterCallback }">
              <InputText v-model="filterModel.value"
                type="text"
                class="p-column-filter"
                placeholder="Search by Email"
                @input="filterCallback()" />
            </template>
          </Column>
          <Column field="membership_categories" 
            header="Membership Categories" 
            filter-field="membership_categories" 
            :show-filter-menu="false"
            :filter-menu-style="{ width: '14rem' }"
            style="min-width: 14rem">
            <template #filter>
              <MultiSelectFilter ref="membership_categories" 
                filter-field="membership_categories"
                option-value="id"
                option-label="name"
                :options="userStore.membershipCategories"
                @change="onChangeCategory" />
            </template>
            <template #body="slotProps">
              {{ translateMembershipCategories(slotProps.data.membership_categories) }}
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <router-link :to="{ name: 'admin_users_edit', params: { id: slotProps.data.id } }"
                class="action btn btn-fill btn-outline-primary mx-2">
                Edit
              </router-link>
            </template>
          </Column>
        </template>
      </PortalTable>
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';
import { useUserStore } from '@/src/stores/user';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";

export default {
  components: {
    CardSection,
    PortalTable,
    Column,
    InputText,
    MultiSelectFilter
  },
  mixins: [ savableStateMixin, paginationMixin ],
  setup() {
    const userStore = useUserStore();
    return {
      userStore
    }
  },
  data() {
    return {
      majorError: null,
      filterValues: {
        "full_name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "email": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "membership_categories" : { value: null, matchMode: FilterMatchMode.STARTS_WITH }
      },
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.userStore.loadMembershipCategories()
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
    this.getRowData();
  },
  methods: {
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;
      
      // build search params
      if (this.currentSortField) {
        this.sort_params = { sort_by: this.currentSortField, sort_type: this.currentSortType };
      } else {
        this.sort_params = '';
      }

      // load users
      let params = {
        page: this.pageNumber
      };

      params = { ...this.sort_params,...this.search_params, ...params };
      try {
        const response = await this.userStore.loadUsers(params);
        this.processPagyResponse(response);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      } 
    },
    onFilterChange(event) {
      if(event["membership_categories"] === "") this.$refs.membership_categories.clearFilters();

      this.search_params = event;
      this.getRowData(true);
    },
    onChangeCategory(event) {
      this.$refs.users_index.resetFilters(event);
    },
    translateMembershipCategories(records) {
      return records.map((item) => {
        return item.name;
      }).join(', ')
    },
  }
}
</script>
