<template>
  <SinglePercentageChart chart-id="percent-accepted-after-decline"
    :config="chartConfig"
    :result="chartResult"
    :loading="chartLoading"
    :error="chartError"
    :header="`Accepted After Decline`"
    @previewReport="previewReportWithAAD"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import SinglePercentageChart from "@/src/components/shared/charts/SinglePercentageChart.vue";

export default {
  components: {
    SinglePercentageChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],
  data() {
    return {
      chartConfig: {
        tooltip: 'The percentage of organs offered to your TxC that you declined, that were accepted by another TxC (denominator: offers to your TxC)',
      },
      aro: false,
      transplanted: false,
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getSingleChartResult(EP.external_analytics.percent_accepted_after_decline, filterParameters);
    },

    previewReportWithAAD(value) {
      const routeData = this.$router.resolve({ name: 'external-dash-reports', query: this.previewReportsQuery(value, false, 'AAD') });
      window.open(routeData.href, '_blank');
    },
  },
}
</script>
