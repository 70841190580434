import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import { handleErrors, makeRequest } from "@/src/utils";
import { APIRoute, EP } from "@/src/endpoints";

export const useIntegrationsOmnilifeStore = defineStore('integrations-omnilife', {
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },

    loadWorkflowMappings() {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.integrations.omnilife.workflow_mappings.index));
    },

    loadUserMappings() {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.integrations.omnilife.user_mappings.index));
    }
  }
})