import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useRoleStore = defineStore('role', {
  state: ()=> {
    return {
      // lookup data
      roles: [],
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    // Load all roles
    loadRoles() {
      return this.loadEntity(APIRoute(EP.admin.roles.index), 'roles')
    },
    // Load all roles
    loadAllRoles(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.roles.index), { params: params });
    },
    loadRole(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.roles.show, { id: id }));
    },
    createRole(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.roles.create), { role: payload });
    },
    updateRole(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.admin.roles.update, { id: payload.id }), { role: payload });
    },
  }
});