<template>
  <div>
    <template v-if="enabledDashboards.length !== 1">
      <ul class="nav justify-content-center">
        <li v-for="d in enabledDashboards" :key="d.name" class="nav-item">
          <router-link class="nav-link" :to="{ name: d.url_path }" :title="d.name">
            {{ d.name }}
          </router-link>
        </li>
      </ul>
    </template>
    <router-view />
  </div>
</template>

<script lang="js">
import { useAppCoreStore } from "@/src/stores/app-core";
import {PermissionNames} from "@/src/enums";

const ApplicationDashboards = [
  {
    url_path: 'external-dash-available-offers',
    permission_key: PermissionNames.EXTERNAL_DASHBOARD_ACTIVE_OFFERS,
    name: 'Offers Dashboard',
    enabled: true,
  },
  {
    url_path: 'external-dash-analytics',
    permission_key: PermissionNames.EXTERNAL_DASHBOARD_ANALYTICS,
    name: 'Analytics Dashboard',
    enabled: true,
  },
  {
    url_path: 'external-dash-reports',
    permission_key: PermissionNames.EXTERNAL_DASHBOARD_REPORTS,
    name: 'Reports Dashboard',
    enabled: true,
  },
]
export default {
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore,
    };
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    enabledDashboards() {
      if (!this.userPermissions) return [];
      return ApplicationDashboards.filter((d) => {
        const dash_perm = this.userPermissions[d.permission_key];
        d.enabled = dash_perm ? (dash_perm.read || false) : false;
        return d.enabled;
      });
    },
  },
  watch: {
    enabledDashboards: { 
      handler(newVal) {
        if (newVal.length == 1) {
          this.$router.push({ name: newVal[0].url_path });
          return;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        text: 'Dashboards',
      },
    ]);
  },
}
</script>
