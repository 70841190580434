<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }}
      Template Category
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedCategory" @submit="onSubmit">
            <div class="row">
              <TextInput v-model="selectedCategory.categoryName" name="categoryName" label="Category Name"
                rules="required" col-style="form-group-standard-column" />
            </div>
            <div class="row">
              <MultiSelectInput v-model="selectedCategory.categoryTemplateName"
                name="categoryTemplateName"
                label="Category Type"
                :options="getCategoryTypes"
                label-key="value"
                value-key="key"
                rules="required"
                col-style="form-group-standard-column" />
            </div>
            {{ secondaryButtonText }}
            <ActionToolbar :component-state="currentState" 
              :success-message="successMessage" 
              :error-message="formError" 
              :secondary-button-text="createNew ? 'Cancel' : 'Close'"
              @secondary-click="cancel" />
          </VeeForm>
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useTemplateStore } from '@/src/stores/template';
import SubSection from "@/src/components/shared/SubSection.vue";
import '@/src/vee-validate.js';
import { storeToRefs } from 'pinia';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { Form as VeeForm } from 'vee-validate';

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    MultiSelectInput,
    TextInput,
    VeeForm
  },
  mixins: [ savableStateMixin ],
  setup() {
    const templateStore = useTemplateStore();
    const { getCategoryTypes } = storeToRefs(templateStore)
    return { templateStore, getCategoryTypes };
  },
  data() {
    return {
      selectedCategory: null,
      successMessage: null
    }
  },
  computed: {
    createNew() {
      return this.$route.params.id ? false : true;
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.templateStore.loadCategoryTypes();
      const record = this.createNew ? {} : await this.templateStore.loadCategory(this.$route.params.id);
      this.setSelectedCategory(record);
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    async onSubmit() {
      this.setSavableStateProcessing();
      const payload = {
        category_name: this.selectedCategory.categoryName,
        category_template_name: this.selectedCategory.categoryTemplateName
      }
      let method;
      let response;
      if (this.createNew) {
        this.successMessage = 'Successfully created template category!'
        method = this.templateStore.createCategory;
      } else {
        this.successMessage = 'Successfully updated template category!'
        payload.id = this.selectedCategory.id;
        method = this.templateStore.updateCategory;
      }

      try {
        response = await method(payload);
        this.setSavableStateSuccess();
        if (this.createNew) {
          this.selectedCategory.id = response.id
          this.$router.push({ name: 'admin_communication_template_categories_edit', params: { id: response.id }});
        }
      } catch (error) {
        this.handleFormError(error);
      }
    },
    setSelectedCategory(record) {
      this.selectedCategory = {
        id: record.hasOwnProperty('id') ? record.id : null,
        categoryName: record.hasOwnProperty('category_name') ? record.category_name : null,
        categoryTemplateName: record.hasOwnProperty('category_template_name') ? record.category_template_name : null
      };
    },
    cancel() {
      this.$router.push({ name: 'admin_communication_template_categories' });
    }
  }
}
</script>