<template>
  <div>
    <div v-if="isAnyMembershipOutOfCategory" class="row">
      <div class="col-sm-12">
        <div class="alert alert-warning" role="alert">
          Warning: Teams with asterisks are not in user's categories; please review.
        </div>
      </div>
    </div>
    
    <PortalTable ref="user_team_memberships_index"
      table-id="user_team_memberships_index"
      :table-data="teamMemberships"
      :filter-values="filterValues"
      :sort-order="1"
      sort-field="team.name"
      filter-display="row"
      empty="No assigned Team Membership for the user!"
      css-class="mb-3"
      edit-mode="row"
      mode="local"
      :loading="loading"
      @table-row-select="selectMembershipRows"
      @on-filter-change="onFilterChange"
      @on-row-edit-save="onRowEditSave"
      @on-row-edit-init="onRowEditInit">
      <template #headers>
        <div class="card-header media" style="display: flex; align-items: center">
          <div class="media-left">
            <div>
              <h4 class="card-title d-flex">
                Team Memberships
              </h4>
            </div>
          </div>
        </div>
      </template>
      <template #columns>
        <Column selection-mode="multiple" header-style="width: 3rem" />
        <Column field="team.name"
          header="Team Name"
          filter-field="team.name"
          :show-filter-menu="false"
          :filter-menu-style="{ width: '14rem' }"
          style="min-width: 14rem">
          <template #body="slotProps">
            <span v-if="slotProps.data['out_of_category?']" title="Warning: User is not a member of this team’s category."><b style="color:red;">*</b></span> {{ slotProps.data.team.name }}
          </template>
          <template #filter>
            <MultiSelectFilter ref="teams_filter"
              filter-field="team.name"
              option-value="name"
              option-label="name"
              :options="selectedUserTeams"
              @change="onChangeFilter" />
          </template>
        </Column>
        <Column field="team.membership_category.name" 
          header="Membership Categories" 
          filter-field="team.membership_category.name" 
          :show-filter-menu="false"
          :filter-menu-style="{ width: '14rem' }"
          style="min-width: 14rem">
          <template #filter>
            <MultiSelectFilter ref="membership_categories" 
              filter-field="team.membership_category.name"
              option-value="name"
              option-label="name"
              :options="selectedMembershipCategory"
              @change="onChangeFilter" />
          </template>
        </Column>
        <Column field="role.role_name" 
          header="Role" 
          filter-field="role.role_name"
          :show-filter-menu="false"
          :filter-menu-style="{ width: '14rem' }"
          style="min-width: 100px;">
          <template #filter>
            <MultiSelectFilter ref="roles_filter"
              filter-field="role.role_name"
              option-value="role_name"
              option-label="role_name"
              :options="selectedUserRoles"
              @change="onChangeFilter" />
          </template>
          <template #editor="{ data }">
            <!-- This slotProps.data.id will map to the unique team membership id. We can rely on this to keep each form separate.-->
            <MultiSelectInput v-model="editRowData.roleId[data.id]"
              name="role"
              label="Role"
              :options="userStore.roles"
              label-key="role_name"
              value-key="id"
              rules="required"
              placeholder="Select a Role" />
          </template>
          <template #body="{ data }">
            {{ data.role.role_name }}
            <DismissableMessage :message="editRowData.message[data.id]" 
              :component-state="editRowData.currentState[data.id]" />
          </template>
        </Column>
        <Column :row-editor="true" style="width: 10%; min-width: 8rem" body-style="text-align:center" />
      </template>
    </PortalTable>

    <ActionToolbar :component-state="currentState" 
      :primary-button-text="`Delete Team Membership`"
      toolbar-size="col-md-6"
      :primary-style="`danger`"
      :primary-disabled="!selectedRows.length"
      :success-message="`Successfully deleted Team Memberships!`"
      :secondary-enabled="false"
      :error-message="formError"
      @primary-click="onDeleteTeamMembership" />
  </div>
</template>
<script>
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { useUserStore } from '@/src/stores/user';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import DismissableMessage from "@/src/components/shared/DismissableMessage.vue";
import { FilterMatchMode } from 'primevue/api';

export default {
  components: {
    PortalTable,
    Column,
    ActionToolbar,
    MultiSelectFilter,
    MultiSelectInput,
    DismissableMessage
  },
  mixins: [savableStateMixin],
  props: {
    teamMemberships: { default: () => [], required: true, type: Array },
    selectedUser: { required: true, type: Object },
    loading: { default: false, type: Boolean }
  },
  emits: ['membershipsUpdated'],

  setup() {
    const userStore = useUserStore();
    return { userStore };
  },
  
  data() {
    return {
      selectedRows: [],
      /**
       * We need to handle a form for each individual Row separate as there is a separate save action for each.
       * Since we don't really know how many rows are going to be in the table and we need to maintain Vue reactivity
       * We define a single object here with the properties that we will need:
       *  - roleId: to track to role being updated
       *  - message: Message to show in the text
       * By defining the object this way, we can dynamically add single key => value properties everywhere using
       * Team Membership ID as the key for a row between all three Objects.
      */ 
      editRowData: {
        roleId: {},
        message: {},
        currentState: {}
      },
      filterValues: {
        "team.name" : { value: null, matchMode: FilterMatchMode.IN },
        "role.role_name" : { value: null, matchMode: FilterMatchMode.IN },
        "team.membership_category.name" : { value: null, matchMode: FilterMatchMode.IN }
      },
      reloadAfterDelete: false
    }
  },
  computed: {
    isAnyMembershipOutOfCategory() {
      return this.teamMemberships == [] ? false : this.teamMemberships.some(item => item['out_of_category?'] === true);
    },
    selectedUserTeams() {
      // return object
      return this.selectedUser.team_memberships.map((item) => {
        return { name: item.team.name }
      })
    },
    selectedUserRoles() {
      const values = this.selectedUser.team_memberships.map((item) => {
        return item.role.role_name;
      })
      // de-duplicate array, then return object
      return [...new Set(values)].map((item) => {
        return { role_name: item }
      })
    },
    selectedMembershipCategory() {
      const values = this.selectedUser.team_memberships.map((item) => {
        return item.team.membership_category ? item.team.membership_category.name : null;
      })
      .filter((item) => item !== null); // remove nulls where user is not in a category

      // de-duplicate array, then return object
      return [...new Set(values)].map((item) => {
        return { name: item }
      })
    },
  },
  watch: {
    /**
     * We want to watch for loading to clear our internal state, assumption is that
     * if we go through a loading cycle we are resetting the table.
     */
    loading() {
      if (!this.loading && this.reloadAfterDelete) {
        this.reloadAfterDelete = false;
      }
      else if (!this.reloadAfterDelete) {
        this.setSavableStateBlank();
      }
    }
  },
  created: function() {
    this.setSavableStateBlank();
  },
  methods: {
    selectMembershipRows(data) {
      this.selectedRows = data;
    },

    async onDeleteTeamMembership() {
      if(!this.selectedRows) return;
      
      let teamsToDelete = this.selectedRows.map((row) =>{
        return`Team: ${row.team.name} - Category: ${row.team.membership_category ? row.team.membership_category.name : 'none'} - Role: ${row.role.role_name}`;
      });

      if(confirm(`Are you sure you want to delete these team memberships: \n\n${teamsToDelete.join("\n")}`)) {
        this.reloadAfterDelete = true;
        try {
          this.setSavableStateProcessing();
          await this.userStore.deleteMembership(this.selectedUser.id, this.selectedRows);
          this.selectedRows = [];
          this.setSavableStateSuccess();
          this.$emit('membershipsUpdated');
        } catch(error) {
          this.handleFormError(error);
        }
      }

      // reset selections on portal table
      this.$refs.user_team_memberships_index.resetSelectedRows();
    },

    onChangeFilter(event) {
      this.$refs.user_team_memberships_index.resetFilters(event);
    },
    
    onFilterChange(event) {
      if(event["team.name"] === "") this.$refs.teams_filter.clearFilters();
      if(event["role.role_name"] === "") this.$refs.roles_filter.clearFilters();
      if(event["team.membership_category.name"] === "") this.$refs.membership_categories.clearFilters();
    },
    onRowEditInit(event) {
      this.editRowData.currentState[event.data.id] = this.SaveStates.Blank;
      this.editRowData.message[event.data.id] = '';
    },
    /**
     * This handles the save even as it comes from the table, we localize the messaging and state to the 
     * editRowData for that one row based on the Team Membership ID that comes from event.data.id
     */
    async onRowEditSave(event) {
      try {
        this.editRowData.currentState[event.data.id] = this.SaveStates.Processing;
        this.editRowData.message[event.data.id] = 'Saving...';

        // This sets the payload to null
        const payload = this.editRowData.roleId[event.data.id] ? { role_id: this.editRowData.roleId[event.data.id] } : {};
        await this.userStore.updateMembership(event.data.id, event.data.user_id, payload); 
        
        this.editRowData.roleId[event.data.id] = null;
        this.editRowData.currentState[event.data.id] = this.SaveStates.Success;
        this.editRowData.message[event.data.id] = 'Role successfully Updated!';
        this.$emit('membershipsUpdated');
      } catch(error) {
        this.editRowData.currentState[event.data.id] = this.SaveStates.Errored;
        this.editRowData.message[event.data.id] = this.convertSaveErrorMessage(error);
      };
    },

    convertSaveErrorMessage(error) {
      return error === 'Missing parameters.' ? 'Role is required.' : error;
    }
  }
}
</script>