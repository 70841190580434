import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP } from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useRobocallReductionsStore = defineStore('robocall-reductions', {
  state: ()=> {
    return {
      // lookup data
      active: [],
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    
    // Load robocall reduction
    loadRobocallReduction() {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.application_configurations.robocall_reduction.show));
    },
    enableRobocallReduction() {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.application_configurations.robocall_reduction.enable));
    },
    disableRobocallReduction() {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.application_configurations.robocall_reduction.disable));
    },
    updateRobocallReduction(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.admin.application_configurations.robocall_reduction.update), { robocall_reduction: payload });
    },
  }
});