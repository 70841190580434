<template>
  <footer id="footer" class="content-wrap py-3 mt-2 border-top">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-2">
          <p class="d-block mb-0">
            &copy; {{currentYear()}}
          </p>
        </div>
        <div class="col-sm-1 text-center"></div>
        <div class="col-sm-9">
          <span class="footer-brand float-right">
            Buckeye Transplant Plugin Version {{pluginVersion}}
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="js">
export default {
    props: {
      pluginVersion: {
        type: String,
        required: false
      }
    },
    methods: {
        currentYear() {
            return new Date().getFullYear();
        }
   }
}
</script>
