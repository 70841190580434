import ExternalClinicalDashboard from "@/src/components/external-clinical-area/ExternalClinicalDashboard.vue";
import DonorDetails from "@/src/components/external-clinical-area/DonorDetails.vue";
import ListRecipients from "@/src/components/external-clinical-area/ListRecipients.vue";
import CompareRecipientDonor from "@/src/components/external-clinical-area/CompareRecipientDonor.vue";
import DashboardArea from "@/src/components/external-clinical-area/DashboardArea.vue";
import ExternalAnalyticsDashboard from "@/src/components/external-clinical-area/ExternalAnalyticsDashboard.vue";
import ExternalReportsDashboard from "@/src/components/external-clinical-area/ExternalReportsDashboard.vue";

export const externalRoutes = [
  {
    path: 'external',
    children: [
      {
        path: 'dashboards',
        children: [
          {
            path: '',
            name: 'external-dashboards-root',
            component: DashboardArea,
          },
          {
            path: 'available-offers',
            name: 'external-dash-available-offers',
            component: ExternalClinicalDashboard,
          },
          {
            path: 'match/:id',
            name: 'external-dash-match-show',
            children: [
              {
                path: 'donor',
                name: 'external-dash-match-donor',
                children: [
                  {
                    path: ':donor_id',
                    children: [
                      {
                        path: '',
                        name: 'external-donor-details',
                        component: DonorDetails
                      },
                      {
                        path: 'recipients',
                        name: 'external-donor-list-recipients',
                        component: ListRecipients,
                      },
                      {
                        path: 'recipient/:recipient_id',
                        name: 'compare-donor-recipient',
                        component: CompareRecipientDonor
                      }
                    ],
                  }
                ]
              }
            ]
          },
          {
            path: 'analytics',
            name: 'external-dash-analytics',
            component: ExternalAnalyticsDashboard,
          },
          {
            path: 'reports',
            name: 'external-dash-reports',
            component: ExternalReportsDashboard,
          },
        ]
      }
    ]
  }
]