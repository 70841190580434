<template>
  <StackedHorizontalBarChart chart-id="chart-final-dispositions"
    size-classes="col-12"
    :config="chartConfig"
    :results="chartResults"
    :loading="chartLoading"
    :title="final-dispositions"
    :error="chartError"
    :header="`Final Dispositions`"
    @previewReport="previewReport"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import StackedHorizontalBarChart from "@/src/components/shared/charts/StackedHorizontalBarChart.vue";

export default {
  components: {
    StackedHorizontalBarChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],

  data() {
    return {
      chartConfig: {
        stacked: true,
        xTitle: 'Total Matches',
        barColours: [
          '#509EE3',
          '#88BF4D',
          '#98D9D9',
          '#F7C41F',
          '#F2A86F',
          '#EF8C8C',
          '#7172AD',
        ],
        barHoverColours: [
          '#94c3ee',
          '#cce3b2',
          '#b5e4e4',
          '#fbe291',
          '#f7c9a5',
          '#f19d9d',
          '#bcbcd8',
        ],
        resultLabelField: 'organ_name',
        datasets: [
          {
            label: 'Backup offer never primary',
            dataField: 'backup_offer_never_primary',
            code: 'BAK'
          },
          {
            label: 'Accepted after decline',
            dataField: 'accepted_after_decline',
            code: 'AAD'
          },
          {
            label: 'Not accepted',
            dataField: 'not_accepted',
            code: 'NAC'
          },
          {
            label: 'Accepted cases',
            dataField: 'accepted_cases',
            code: 'AC'
          },
          {
            label: 'No Transplant - Dry Run',
            dataField: 'no_transplant_dry_run',
            code: 'DRY'
          },
          {
            label: 'Other',
            dataField: 'other',
            code: 'OTH'
          },
          {
            label: 'None',
            dataField: 'none',
            code: 'NA'
          },
        ]
      },
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getChartResults(EP.external_analytics.chart_final_dispositions, filterParameters);
    },
  },
}
</script>
