<template>
  <div>
    <MultiSelect :ref="multiselectRef"
      v-model="vModel"
      :option-value="optionValue"
      :option-label="optionLabel" 
      :selection-limit="multiple ? null : 1"
      placeholder="Filter" 
      class="p-column-filter" 
      style="min-width: 14rem"
      display="chip"
      filter
      :max-selected-labels="maxSelectedLabels"
      :options="options"
      @update:model-value="debounce(() => { onChange($event) })">
      <template #option="slotProps">
        <div class="flex align-items-center gap-2">
          <span>{{ slotProps.option[`${optionLabel}`] }}</span>
        </div>
      </template>
    </MultiSelect>
  </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect';
import { createDebounce } from "@/src/utils";

export default {
  components: {
    MultiSelect
  },
  props: {
    optionValue: { default: "id", type: String },
    optionLabel: { default: "code", type: String },
    maxSelectedLabels: { default: 2, type: Number },
    multiselectRef: { default: 'multiselect', type: String },
    filterField: { default: null, type: String },
    options: { default: (() => []), type: Array },
    cacheContextKey: { required: false, default: null, type: String},
    multiple: { default: true, type: Boolean },
  },
  emits: ['change'],
  setup() {
    return { debounce: createDebounce() }
  },
  data() {
    return {
      vModel: null
    }
  },
  watch: {
    // We need to watch for the options changing to handle the cacheContext. In some cases the options are
    // not available on mounted so we check once the options become available if we should be loading from the cache
    // The conditions are:
    // 0. The modelValue was not supplied from the calling component was supplied
    // 1. We went from 0 to more options
    // 2. We have a cacheContextKey
    // 3. The key actually matches one of the options.
    // if we do then we can safely set the value
    'options': {
      handler: function() {
        this.setValueFromCache();
      }
    }
  },
  mounted() {
    this.setValueFromCache();
  },
  methods: {
    onChange(event) {
      this.$emit('change',{ [this.filterField]: event })
    },
    clearFilters() {
      this.vModel = null;
    },
    setFilters(value) {
      if(value === null || this.options.length === 0) return;
      let newValue = value;
      
      const checkAgainst = Array.isArray(newValue) ? newValue : newValue.split(',');
      const matchingOptions = [];
      this.options.find((item) => {
        checkAgainst.includes(item[`${this.optionValue}`]) ? matchingOptions.push(item[`${this.optionValue}`]) : null;
      });
      // if not found, clear the value
      if (matchingOptions.length === 0) { newValue = null; }
      // if we receive a value, update our local copy
      if (newValue) {
        this.vModel = matchingOptions;
      } else {
        // if it's blank, set our internal copy to an empty array, as primevue prefers arrays
        this.vModel = [];
      }
    },
    setValueFromCache() {
      if((this.vModel && this.vModel.length > 0) || this.modelValue || !this.cacheContextKey || this.options.length === 0) return;

      let cachedValue = window.localStorage.getItem(`multiselectfilter.${this.cacheContextKey}`);
      if(!cachedValue) return;

      cachedValue = cachedValue.split(',');
      const found = this.options.find((item) => { return cachedValue.includes(item[this.optionValue]); });

      if(!found) return;

      this.vModel = cachedValue;
      this.$emit('change',{ [this.filterField]: this.vModel })
    },
    clearCacheValue() {
      window.localStorage.removeItem(`multiselectfilter.${this.cacheContextKey}`);
    }
  }
}
</script>
