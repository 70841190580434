import {SaveStates} from "@/src/enums";

/**
 * This mixin provides a standard way to handle states for a given component when dealing with data from the server.
 * The follow SaveStates are available:
 *  - Blank: The starting state, should ideally never be set to this, and just used as starting point
 *  - Idle: State to set to once actions/things are done and we need a neutral state to get to
 *  - Loading: State to use when loading the whole component / section. This should be used on things like initial load
 *  - Processing: State to use when component needs to do specific processing like saving a form, partial loading etc.
 *  - Errored: State to use when something errored out and we need to mark the current set up as a problem
 *  - Info: State to use when we need to show some information to the user
 *
 * It injects the following into the component:
 * Data properties:
 *  - majorError: To store a big error that happened. We usually use this in card sections
 *  - formError: To store the error for a form on the page
 *  - currentState: Property to hold the currentState of this component. Defaults to Blank
 *  - SaveStates: Enum of all possible states supported by this component.
 *
 *  Computed Properties:
 *   - getSavableState
 *   - isSavableState{Loading|Processing|Idle|Blank|Errored|Info|Success}
 *  Methods:
 *    - setSavableState
 *    - setSavableState{Loading|Processing|Idle|Blank|Errored|Info|Success}
 */
export const savableStateMixin = {
  data() {
    return {
      formError: null,
      majorError: null,
      SaveStates,
      currentState: SaveStates.Blank
    }
  },
  computed: {
    /**
     * Get the current state for the component
     * @returns {string} Current state which one of enum: SaveStates
     */
    getSavableState() {
      return this.currentState;
    },
    /**
     * Check if the component is currently in the Loading state
     * @returns {boolean}
     */
    isSavableStateLoading() {
      return this.currentState === SaveStates.Loading;
    },
    /**
     * Check if the component is currently in the Idle state
     * @returns {boolean}
     */
    isSavableStateIdle() {
      return this.currentState === SaveStates.Idle;
    },
    /**
     * Check if the component is currently in the Processing state
     * @returns {boolean}
     */
    isSavableStateProcessing() {
      return this.currentState === SaveStates.Processing;
    },
    /**
     * Check if the component is currently in the Blank state
     * @returns {boolean}
     */
    isSavableStateBlank() {
      return this.currentState === SaveStates.Blank;
    },
    /**
     * Check if the component is currently in the Success state
     * @returns {boolean}
     */
    isSavableStateSuccess() {
      return this.currentState === SaveStates.Success;
    },
    /**
     * Check if the component is currently in the Errored state
     * @returns {boolean}
     */
    isSavableStateErrored() {
      return this.currentState === SaveStates.Errored;
    },
    /**
     * Check if the component is currently in the Info state
     * @returns {boolean}
     */
    isSavableStateInfo() {
      return this.currentState === SaveStates.Info;
    }
  },
  methods: {
    /**
     * Set the component state to a given state
     * @param state {string|null}: The state to set the param to, use SaveStates enum
     */
    setSavableState(state) {
      this.currentState = state;
    },
    /**
     * Set the component state to a Loading state
     */
    setSavableStateLoading() {
      this.setSavableState(SaveStates.Loading);
    },
    /**
     * Set the component state to a Processing state
     */
    setSavableStateProcessing() {
      this.setSavableState(SaveStates.Processing);
    },
    /**
     * Set the component state to a Idle state
     */
    setSavableStateIdle() {
      this.setSavableState(SaveStates.Idle);
    },
    /**
     * Set the component state to a Blank state
     */
    setSavableStateBlank() {
      this.setSavableState(SaveStates.Blank);
    },
    /**
     * Set the component state to a Success state
     */
    setSavableStateSuccess() {
      this.setSavableState(SaveStates.Success);
    },
    /**
     * Set the component state to a Errored state
     */
    setSavableStateErrored() {
      this.setSavableState(SaveStates.Errored);
    },
    /**
     * Set the component state to a Info state
     */
    setSavableStateInfo() {
      this.setSavableState(SaveStates.Info);
    },
    /**
     * Sets the component current state to errored and assigns error to majorError
     * @param {*} error 
     */
    handleMajorError(error) {
      this.setSavableStateErrored();
      this.majorError = error;
    },
    /**
     * Sets the component current state to errored and assigns error to formError
     * @param {*} error 
     */
    handleFormError(error) {
      this.setSavableStateErrored();
      this.formError = error;
    },
    /**
     * Handle's switching to Success state when we create new entities.
     * 
     * @param {String} routeToMatch The path of the route to match against. This needs to be the full path
     */
    switchToSuccessOnCreate(routeToMatch) {
      this.setSavableStateIdle();
      const history = this.$router.options.history.state;
      if (!history.replaced && history.back === routeToMatch) {
        // We need set time out to wait for the action toolbar to render first
        setTimeout(() => {
          this.setSavableStateSuccess();
        }, 100);
      }
    }
  }
}
