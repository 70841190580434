<template>
  <div>
    <DashboardWidgetLayout widget-id="unfinished-business-dashboard-full"
      widget-title="Unfinished Business"
      :widget-icon="['fas', 'user-group']" :enable-filters="false" :error="majorError">
      <template #content>
        <PortalTable ref="unfinished_business_dashboard"
          table-id="unfinished_business_dashboard"
          :total-records="totalRecords"
          :page="pageNumber"
          :page-count="pageCount"
          :table-data="recordList"
          :loading="isSavableStateProcessing"
          filter-display="row"
          empty="No matches found!"
          :filter-values="filterValues"
          @on-sort="handleSortChange"
          @on-page="handlePageChange"
          @on-filter-change="onFilterChange">
          <template #columns>
            <Column field="donor.code" header="Donor" sortable
              filter-field="donor_code"
              :filter-menu-style="{ width: '14rem' }"
              style="min-width: 14rem"
              :show-filter-menu="false">
              <template #body="slotProps">
                <a target="_blank" :href="linkToOffer(slotProps.data)" class="table-link">{{ slotProps.data.donor.code }}</a>
              </template>
              <template #filter="{ filterModel = { value: null }, filterCallback }">
                <InputText v-model="filterModel.value"
                  type="text"
                  :readonly="isSavableStateProcessing"
                  class="p-column-filter form-control"
                  placeholder="Search by Donor"
                  @input="filterCallback()" />
              </template>
            </Column>            
            <Column field="organ.organ_group.name" sortable header="Organ"
              style="min-width: 80px;"
              filter-field="organ_group_id" :show-filter-menu="false">
              <template #filter>
                <MultiSelectFilter ref="organ_group_id" 
                  filter-field="organ_group_id"
                  option-label="name"
                  class="form-control form-control-select"
                  :multiple="false"
                  :options="dashboardStore.organGroups"
                  @change="onChange" />
              </template>
            </Column>
            <Column field="code" header="Match"
              filter-field="match_code"
              :show-filter-menu="false"
              style="min-width: 14rem"
              :filter-menu-style="{ width: '15rem' }">
              <template #filter="{ filterModel = { value: null }, filterCallback }">
                <InputText v-model="filterModel.value"
                  type="text"
                  :readonly="isSavableStateProcessing"
                  class="p-column-filter form-control"
                  placeholder="Search by Match"
                  @input="filterCallback()" />
              </template>
            </Column>
            <Column field="transplant_center.code" sortable header="TC"
              style="min-width: 70px;"
              filter-field="tc_id"
              :show-filter-menu="false"
              :filter-menu-style="{ width: '15rem' }">
              <template #filter>
                <MultiSelectFilter ref="tc_id" 
                  filter-field="tc_id"
                  class="form-control form-control-select"
                  :multiple="false"
                  :options="dashboardStore.transplantCenters" 
                  @change="onChange" />
              </template>
            </Column>
            <Column field="offer_date" header="Offer Date" sortable
              style="min-width: 160px"
              :show-filter-menu="false"
              :filter-menu-style="{ width: '15rem' }">
              <template #body="slotProps">
                {{ formatDate(slotProps.data.offer_date) }}
              </template>
              <template #filter>
                <button type="button" class="btn btn-outline-secondary" title="Filter" @click="toggleFilter">
                  <font-awesome-icon :icon="['fas', 'fa-filter']" aria-hidden="true" />
                </button>
                <button v-if="hasFilter" class="btn btn-outline-secondary" type="button" label="Clear Filter" title="Clear Filters" @click="clearFilters">
                  <div class="fa-layers fa-fw">
                    <font-awesome-icon :icon="['fas', 'fa-filter']" aria-hidden="true" /> 
                    <font-awesome-icon :icon="['fas', 'slash']" transform="grow-3" />
                  </div>
                </button>
                <div v-if="showFilter" class="overlay" @blur="toggleFilter">
                  <span>From:</span>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">Date</span>
                    <InputText ref="from_date"
                      v-model="dateFrom"
                      type="date"
                      class="p-column-filter"
                      placeholder="Offer Date From"
                      @input="filterData" />
                    <span class="p-inputgroup-addon">Time</span>
                    <InputText ref="from_time" v-model="fromTime"
                      type="time"
                      class="p-column-filter"
                      @input="filterData" />
                  </div>
               
                  <span>To:</span>
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">Date</span>
                    <InputText ref="to_date" v-model="dateTo"
                      type="date"
                      class="p-column-filter"
                      @input="filterData" />
                    <span class="p-inputgroup-addon">Time</span>
                    <InputText ref="to_time" v-model="toTime"
                      type="time"
                      class="p-column-filter"
                      @input="filterData" />
                  </div>
                </div>
              </template>
            </Column>
            <Column field="seq" header="Seq." style="min-width: 80px;">
              <template #body="slotProps">
                {{ (slotProps.data.match_effective_value && slotProps.data.match_effective_value.effective_seq) ? slotProps.data.match_effective_value.effective_seq : 'N/A' }}
              </template>
            </Column>
            <Column field="status.name" header="Status" style="min-width: 140px;" />
            <Column field="offer_last_updated_users" header="User"
              :filter-menu-style="{ width: '14rem' }"
              style="min-width: 14rem"  
              filter-field="user"
              :show-filter-menu="false">
              <template #filter="{ filterModel = { value: null }, filterCallback }">
                <InputText v-model="filterModel.value"
                  type="text"
                  :readonly="isSavableStateProcessing"
                  class="p-column-filter form-control"
                  placeholder="Search by User"
                  @input="filterCallback()" />
              </template>
            </Column>
            <Column class="btn-3-icon-group">
              <template #body="slotProps">
                <div class="d-flex">
                  <a v-if="hasPermission('internal_match_decline_offer')" href="#" title="Decline" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="showModalFor(slotProps.data, ActionModals.DECLINE)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', 'ban']" /><span class="mobile-action">Decline</span>
                  </a>
  
                  <a href="#" :title="!slotProps.data.coordinator_note ? `Add Notes` : `Notes`" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="showModalFor(slotProps.data, ActionModals.NOTES)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', !slotProps.data.coordinator_note ? 'plus-square' : 'comment-alt']" /><span class="mobile-action">Notes</span>
                  </a>
                </div>
              </template>
            </Column>
          </template>
        </PortalTable>
      </template>
    </DashboardWidgetLayout>
    
    <!-- Render modal component dynamically -->
    <component :is="requestedModal" 
      v-if="requestedModal" 
      :ref="requestedModal" 
      :match-id="selectedMatch && selectedMatch.id"
      @close="modalClosed()" />
  </div>
</template>

<script lang="js">
import DashboardWidgetLayout from "~/src/components/shared/DashboardWidgetLayout.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import {useDashboardStore} from "@/src/stores/dashboard";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import MultiSelectFilter from "@/src/components/shared/FormComponents/MultiSelectFilter.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { pageMixin } from "@/src/mixins/pageMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import InputText from 'primevue/inputtext';
import DeclineModal from "@/src/components/buckeye-coordinator-area/modals/DeclineModal.vue";
import NotesModal from "@/src/components/buckeye-coordinator-area/modals/CoordinatorNotesModal.vue";

const ActionModals = {
  DECLINE: 'decline-modal',
  NOTES: 'notes-modal'
}

export default {
  components: {
    DashboardWidgetLayout,
    Column,
    PortalTable,
    MultiSelectFilter,
    InputText,
    DeclineModal,
    NotesModal
  },
  mixins: [ savableStateMixin, pageMixin, paginationMixin ],

  setup() {
    const appStore = useAppCoreStore();
    const dashboardStore = useDashboardStore();
    return { appStore, dashboardStore };
  },
  data() {
    return {
      ActionModals,
      recordList: [],
      selectedMatch: null,
      dateFrom: '2024-06-01', // set default offer date to June when dashboard is loaded first
      fromTime: '',
      dateTo: '',
      toTime: '',
      totalRecords: 0,
      filterValues: {
        "tc_id" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "organ_group_id" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "donor_code" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "match_code" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        "user" : { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      },
      showFilter: false,
      requestedModal: null
    }
  },
  computed: {
    user() {
      return this.appStore.currentUser;
    },
    userPermissions() {
      return this.appStore.currentUser ? this.appStore.currentUser.userPermissions : null;
    },
    hasFilter() {
      return !!this.dateFrom || !!this.dateTo || !!this.toTime || !!this.fromTime;
    }
  },
  async mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        to: { name: 'internal-dashboards-root' },
        text: 'Dashboards'
      },
      {
        text: 'Unfinished Business Dashboard'
      }
    ]);
    try {
      this.setSavableStateLoading();
      await this.dashboardStore.loadTransplantCentres();
      await this.dashboardStore.loadOrganGroups();
      await this.getRowData();

      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    filterData() {
      this.getRowData(true);
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    hasPermission(key) {
      if(!this.user || !this.userPermissions) return false;
      return this.userPermissions[key] && this.userPermissions[key].read == true ? true : false;
    },
    showModalFor(match, modalType) {
      this.selectedMatch = match;
      this.requestedModal = modalType;
     
      this.$nextTick(() => {
        let modal = this.$refs[modalType];
        if(!modal) {
          alert(`No modal found for ${modalType}`);
          return;
        }
        modal.showModal();
      });
    },
    modalClosed() {
      this.selectedMatch = null;
      this.getRowData(true);
    },
    clearFilters() {
      this.dateFrom = '2024-06-01', // set default offer date to June
      this.fromTime = ''
      this.toTime = ''
      this.dateTo = ''

      this.showFilter = false;
      this.getRowData(true)
    },
    async getRowData(reset = false) {
      this.setSavableStateProcessing();
      if (reset) this.pageNumber = 1;

      this.dateFrom = this.dateFrom ? this.dateFrom : '2024-06-01';
      const offer_date_from = this.dateFrom && this.fromTime ? new Date(`${this.dateFrom}T${this.fromTime}`) : this.dateFrom ?  new Date(`${this.dateFrom}T00:00`) : '';
      const offer_date_to = this.dateTo && this.toTime ? new Date(`${this.dateTo}T${this.toTime}`) : this.dateTo ? new Date(`${this.dateTo}T00:00`) : '';

      let params = {
        page: this.pageNumber,
        sort_by: this.currentSortField,
        sort_type: this.currentSortType,
        date_from: offer_date_from,
        date_to: offer_date_to,
      };

      params = { ...this.search_params, ...params };

      try {
        this.recordList = [];
        
        const response = await this.dashboardStore.loadMatchesWithUnfinishedBusiness(params);
        this.totalRecords = response.total_records
        this.recordList = response.records
        this.pageCount = response.page_count
        this.setSavableStateIdle();
      } catch(error) {
        this.setSavableStateErrored();  
      }
    },
    linkToOffer(row) {
      return row.email_url && row.email_url.length > 0 ? row.email_url : 'https://auth.unos.org/Login?redirect_uri=https%3A%2F%2Fportal.unos.org';
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    reloadRowData() {
      this.getRowData();
    },
    onChange(event) {
      this.$refs.unfinished_business_dashboard.resetFilters(event);
    },
    onFilterChange(event) {
      if(event["tc_id"] === "") this.$refs.tc_id.clearFilters();
      if(event["organ_group_id"] === "") this.$refs.organ_group_id.clearFilters();

      this.search_params = event;
      this.getRowData(true);
    },
  }
}
</script>
<style>
.overlay {
  position: absolute;
  left: auto;
  top: 5px;
  z-index: 10000 !important; 
  background-color: #f9fafb;
  overflow: scroll;
  margin-left: 150px;
  justify-content: space-between;
  padding: 3rem;
}
</style>
