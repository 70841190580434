<template>
  <div class="card card-form">
    <!-- show simple header -->
    <template v-if="customHeader">
      <slot name="header">
        Default Header in Card Component
      </slot>
    </template>
    <!-- show header with legend and slot for actions -->
    <h3 v-else class="legend-title card-title title-bar">
      <slot name="header">
        Default Header in Component
      </slot>
    </h3>
    <loading-section v-if="loading" />
    <div v-else class="card-body">
      <div v-if="error && !loading" class="alert alert-danger">
        {{ error }}
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<style scoped>
  .accordion-icon {
    transform: rotate(270deg);
    transition-duration: 0.3s;
    cursor: pointer;
  }
  .accordion-icon.rotate {
    transform: rotate(360deg);
    transition-duration: 0.3s;
  }
</style>
<script lang="js">
import LoadingSection from "@/src/components/shared/LoadingSection.vue";

/**
 * This component provides a standard way to define a card section that is going to show content using the
 * bootstrap Card.
 *
 */
export default {
  components: {
    LoadingSection
  },
  props: {
    // Show a component level error message. This shows up right above the body
    error: {
      type: String,
      required: false,
      default: null
    },
    // Mark the component as loading. While the component is loading no content is rendered. It shows a loading spinner instead
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    // Use a custom header slot with all custom HTML
    customHeader: { default: false },
  },
  mounted() {
  }
}
</script>
