<template>
  <CardSection :loading="isSavableStateLoading"
    :error="majorError">
    <template #header>
      Comms Templates
    </template>

    <template #body>
      <template v-if="templateStore.categories.length > 0">
        <VeeForm @submit="onSubmit">
          <!-- category select start -->
          <div class="row">
            <MultiSelectInput v-model="communicationTemplateCategoryId"
              name="communicationTemplateCategory"
              label="Select Category"
              :options="templateStore.categories"
              label-key="category_name"
              value-key="id"
              rules="required"
              col-style="form-group-standard-column" />
          </div>

          <ActionToolbar :component-state="currentState" 
            toolbar-size="col-md-6"
            :secondary-enabled="true"
            primary-button-text="Generate Template"
            info-message="Copied to clipboard."
            :error-message="formError"
            @secondary-click="onCancel" />
        </VeeForm>
      </template>
      <template v-else>
        <div class="row">
          <div class="form-group-8-column-large">
            <p>No templates available for this TXC / Organ combination.</p>
          </div>
        </div>
        <div class="row">
          <div class="form-group-8-column-large">
            <button class="btn btn-outline-secondary" @click.prevent="onCancel()">
              Close
            </button>
          </div>
        </div>
      </template>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import { useTemplateStore } from '@/src/stores/template';
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { Form as VeeForm } from 'vee-validate';
import '@/src/vee-validate.js';
import { pageMixin } from "@/src/mixins/pageMixin";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import {APIRoute, EP} from "@/src/endpoints";
import beApiClient from "@/src/be-api-client";
import { storeToRefs } from 'pinia'
import {useAppCoreStore} from "@/src/stores/app-core";

export default {
  components: {
    CardSection,
    ActionToolbar,
    VeeForm,
    MultiSelectInput
  },
  mixins: [ pageMixin, savableStateMixin ],
  props: {
    donor: { required: true, type: String},
    match: { required: true, type: String },
    txc: { required: true, type: String },
    organGroup: { required: true, type: String },
  },
  setup() {
    const templateStore = useTemplateStore();
    const appStore = useAppCoreStore();
    const { getTemplateList, translateOrganGroup, translateTxcCode } = storeToRefs(templateStore)
    return {
      templateStore, getTemplateList, appStore, translateOrganGroup, translateTxcCode
    }
  },
  data() {
    return {
      loading: true,
      communicationTemplateCategoryId: null,
      formErrorMessage: null,
      majorError: null,
      donorDetails: null,
      matchDetails: null,
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      // get donor & match details
      await this.templateStore.loadTransplantCentres();
      await this.templateStore.loadOrganGroups();
      // get categories list by organ & txc
      await this.templateStore.loadCategoryTypesByOrganGroupAndTxc(this.organGroup, this.txc);

      const donorDetails = await beApiClient.get(APIRoute(EP.donors.show, { id: this.donor }));
      const matchDetails = await beApiClient.get(APIRoute(EP.matches.show, { id: this.match }));
      if (!donorDetails.data || !matchDetails.data) { this.setSavableStateErrored(); return false; }
      this.donorDetails = donorDetails.data;
      this.matchDetails = matchDetails.data;

      this.setupBreadCrumb();
      this.setSavableStateIdle();
    } catch(error) {
      this.majorError = error;
      this.setSavableStateErrored();
      
    }
  },
  methods: {
    // build breadcrumb
    setupBreadCrumb() {
      this.appStore.setAppBreadcrumbs([
        {
          to: { name: 'internal-dashboards-root' },
          text: 'Internal'
        },
        {
          to: { name: 'internal-dashboard-plugin' },
          text: 'Dashboard'
        },
        {
          to: { name: 'generate-instance', 
                params: { 
                  donor: this.donor,
                  match: this.match,
                  txc: this.txc,
                  organGroup: this.organGroup }
          },
          text: this.generateBreadCrumb(
            this.donorDetails.donor.code,
            this.matchDetails.code,
            this.translateTxcCode(this.txc),
            this.translateOrganGroup(this.organGroup)
          )
        }
      ]);
    },
    getCommunicationTemplateIdFromCategory(id) {
      const found = this.templateStore.categories.find((item) => {
        return item.id === id;
      })
      return found && found.communication_templates ? found.communication_templates[0].id : null;
    },
    async onSubmit() {
      try {
        this.setSavableStateProcessing()
        // check if instance already created
        const found = await this.templateStore.latestTemplateInstance(this.match, this.communicationTemplateCategoryId)

        if (found.length < 1) {
          // if nothing found, generate body and generate template instance
          const communication_template_id = this.getCommunicationTemplateIdFromCategory(this.communicationTemplateCategoryId);
          
          // generate initial body
          const response = await this.templateStore.generateInstanceBody(communication_template_id, this.match)

          // build payload to generate first instance of template
          const payload = {
            communication_template_id: communication_template_id,
            instance_text: response.template_body
          }

          // generate first instance of template
          const responseOnSave = await this.templateStore.generateTemplateInstanceFromMatch(this.match, payload)
          const instanceId = responseOnSave.id;

          // set success save state
          this.setSavableStateSuccess();

          // now successfully created instance, go to edit page
          this.$router.push({ name: 'edit-instance',
            params: {
              donor: this.donor, 
              match: this.match, 
              txc: this.txc, 
              organGroup: this.organGroup,
              instanceId: instanceId
            }
          });

        } else {
          const instanceId = found[0].id;

          // switch to edit page
          this.$router.push({ name: 'edit-instance',
            params: {
              donor: this.donor, 
              match: this.match, 
              txc: this.txc, 
              organGroup: this.organGroup,
              instanceId: instanceId
            }
          });
        }

      } catch (error) {
        this.formErrorMessage = error;
        this.setSavableStateErrored();
      }
    },
    onCancel() {
      this.$router.push({ name: 'internal-dashboard-plugin' })
    }
  }
}
</script>

