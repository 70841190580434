<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      {{ createNew ? 'New' : 'Edit' }}
      Auto rule out
    </template>

    <template #body>
      <SubSection>
        <template #body>
          <VeeForm v-if="selectedAutoRuleOut" @submit="onSubmit">
            <AroDisabled :aro-rule="selectedAutoRuleOut" />

            <div class="row">
              <MultiSelectInput v-model="selectedAutoRuleOut.ruleCategory"
                name="ruleCategory"
                label="Rule Category"
                :options="aroStore.ruleCategories"
                cache-context-key="admin.rule_categories"
                label-key="name"
                value-key="id"
                rules="required"
                col-style="form-group-standard-column" />

              <MultiSelectInput v-model="selectedAutoRuleOut.transplantCenter"
                name="transplantCenter"
                label="Transplant Center"
                :options="aroStore.transplantCenters"
                cache-context-key="admin.transplant_center"
                label-key="code"
                value-key="id"
                rules="required"
                :disabled="hasAssociatedMatch"
                col-style="form-group-standard-column" />

              <MultiSelectInput v-model="selectedAutoRuleOut.organGroup"
                name="organ"
                label="Organ"
                :options="aroStore.organGroups"
                cache-context-key="admin.organ_group"
                label-key="name"
                value-key="id"
                rules="required"
                :disabled="hasAssociatedMatch"
                col-style="form-group-standard-column" />
            </div>
            <div class="row">
              <TextInput v-model="selectedAutoRuleOut.ruleIdentifier"
                name="ruleIdentifier"
                label="Rule Identifier"
                rules="required"
                col-style="form-group-standard-column" />
              <TextInput v-model="selectedAutoRuleOut.dataSource"
                name="dataSource"
                label="Data Source"
                rules="required"
                col-style="form-group-standard-column" />
            </div>
            <div class="row">
              <TextArea v-model="selectedAutoRuleOut.ruleDescription"
                name="ruleDescription"
                label="Rule Description"
                rows="3"
                cols="100"
                rules="required" />
            </div>
            <div class="row">
              <TextArea v-model="selectedAutoRuleOut.dataReference"
                name="dataReference"
                rows="15"
                cols="100"
                :hide-label="true">
                <template #header>
                  <div class="action-row d-flex">
                    <label for="dataReference">
                      Data Reference
                    </label>
                    <button class="btn btn-primary ml-auto"
                      @click.prevent="openVariableReference()">
                      Variable Reference
                    </button>
                  </div>
                </template>
              </TextArea>
            </div>
            <div class="row">
              <SingleSelect v-model="selectedAutoRuleOut.declineCode1"
                name="declineCode1"
                label="Associated decline code 1"
                rules="required"
                :options="aroStore.declineCodes"
                :label-key="customLabel"
                :filter="true"
                :show-clear="true"
                value-key="id"
                col-style="form-group-standard-column"
                @change="showDeclineComment1" />

              <SingleSelect v-model="selectedAutoRuleOut.declineCode2"
                name="declineCode2"
                label="Associated decline code 2"
                :options="aroStore.declineCodes"
                :label-key="customLabel"
                :filter="true"
                :show-clear="true"
                value-key="id"
                col-style="form-group-standard-column"
                @change="showDeclineComment2" />
            </div>
            <div class="row">
              <TextArea v-if="isUnosCommentOneRequired" v-model="selectedAutoRuleOut.unosComment1"
                name="unosComment1"
                label="UNOS Comment 1"
                rows="1"
                cols="20"
                col-style="form-group-standard-column" />
              <TextArea v-if="isUnosCommentTwoRequired" v-model="selectedAutoRuleOut.unosComment2"
                name="unosComment2"
                label="UNOS Comment 2"
                rows="1"
                cols="20"
                :col-style="`form-group-standard-column ${!isUnosCommentOneRequired ? 'offset-md-3' : ''} `" />
            </div>

            <ActionToolbar :component-state="currentState" 
              toolbar-size="col-md-6"
              :success-message="successMessage" 
              :secondary-enabled="true"
              :tertiary-enabled="!createNew"
              :primary-button-text="Save"
              :secondary-button-text="createNew ? 'Cancel' : 'Close'"
              :error-message="errorMessage"
              :tertiary-button-text="!selectedAutoRuleOut.disabledAt ? 'Disable rule' : 'Enable Rule' "
              @secondary-click="cancel()" 
              @tertiary-click="!selectedAutoRuleOut.disabledAt ? onSubmitDisable() : onSubmitEnable()" />  
          </VeeForm>
          <ModalVariableReference ref="templateVariablesModal" />
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate';
import { useAutoRuleOutStore } from '@/src/stores/aro-rules';
import TextArea from "@/src/components/shared/FormComponents/TextArea.vue";
import ModalVariableReference from "@/src/components/admin-area/templates/ModalVariableReference.vue";
import AroDisabled from "@/src/components/admin-area/aro-rules/_aroDisabled.vue";
import SingleSelect from "@/src/components/shared/FormComponents/SingleSelect.vue";

export default {
  components: {
    CardSection,
    SubSection,
    MultiSelectInput,
    TextInput,
    TextArea,
    VeeForm,
    ModalVariableReference,
    AroDisabled,
    ActionToolbar,
    SingleSelect
  },
  mixins: [ savableStateMixin ],
  setup() {
    const aroStore = useAutoRuleOutStore();
    return {
      aroStore
    }
  },
  data() {
    return {
      selectedAutoRuleOut: null,
      errorMessage: null,
      successMessage: null,
      declineCodesThatRequireComments: [ '701', '716', '717', '750', '754', '798' ],
      isUnosCommentOneRequired: false,
      isUnosCommentTwoRequired: false
    }
  },
  computed: {
    createNew() {
      return this.$route.params.id ? false : true;
    },
    hasAssociatedMatch() {
      return this.selectedAutoRuleOut && this.selectedAutoRuleOut.hasAssociatedMatch; 
    }
  },
  async mounted() {
    this.setSavableStateLoading();
    this.selectedAutoRuleOut = {};

    try {
      await this.aroStore.loadRuleCategories();
      await this.aroStore.loadDeclineCodes();
      await this.aroStore.loadTransplantCentres();
      await this.aroStore.loadOrganGroups();
      if(!this.createNew) {
        // on edit
        const response = await this.aroStore.loadAutoRuleOut(this.$route.params.id)
        this.loadForm(response);
      } 
      this.setSavableStateIdle();

    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    showDeclineComment1() {
      const declineCode = this.selectedAutoRuleOut.declineCode1 && this.aroStore.declineCodes.find((item) => {
        return item.id == this.selectedAutoRuleOut.declineCode1;
      })

      if(declineCode && this.declineCodesThatRequireComments.includes(declineCode.code.toString())) {
        this.isUnosCommentOneRequired = true;
      } else {
        this.isUnosCommentOneRequired = false;
        this.selectedAutoRuleOut.unosComment1 = null;
      }
    },
    showDeclineComment2() {
      const declineCode = this.selectedAutoRuleOut.declineCode2 && this.aroStore.declineCodes.find((item) => {
        return item.id == this.selectedAutoRuleOut.declineCode2;
      })

      if(declineCode && this.declineCodesThatRequireComments.includes(declineCode.code.toString())) {
        this.isUnosCommentTwoRequired = true;
      } else {
        this.isUnosCommentTwoRequired = false;
        this.selectedAutoRuleOut.unosComment2 = null;
      }
    },
    onSubmit() {
      const payload = {
        aro_rule_category_id: this.selectedAutoRuleOut.ruleCategory,
        organ_group_id: this.selectedAutoRuleOut.organGroup,
        transplant_center_id: this.selectedAutoRuleOut.transplantCenter,
        rule_identifier: this.selectedAutoRuleOut.ruleIdentifier.trim(),
        data_source: this.selectedAutoRuleOut.dataSource,
        rule_description: this.selectedAutoRuleOut.ruleDescription,
        data_reference : this.selectedAutoRuleOut.dataReference,
        decline_code_one_id: this.selectedAutoRuleOut.declineCode1,
        decline_code_two_id: this.selectedAutoRuleOut.declineCode2,
        unos_comment_one: this.selectedAutoRuleOut.unosComment1,
        unos_comment_two: this.selectedAutoRuleOut.unosComment2
      }
      if (this.createNew) {
        this.create(payload);
      } else {
        payload.id = this.selectedAutoRuleOut.id;
        this.update(payload);
      }
     
    },
    async create(payload) {
      this.setSavableStateProcessing();
      try {
        const response = await this.aroStore.createAutoRuleOut(payload)
        this.setSavableStateSuccess();
        this.successMessage = 'Successfully created auto rule out!';
        this.$router.push({ name: 'admin_aro_rules_edit', params: { id: response.id }});
        this.loadForm(response);
      } catch(error) {
        this.errorMessage = error.toString();
        this.setSavableStateErrored();
      }
    },
    async update(payload) {
      this.setSavableStateProcessing();
      try {
        await this.aroStore.updateAutoRuleOut(payload);
        this.successMessage = 'Successfully updated auto rule out!';
        this.setSavableStateSuccess();
      } catch (error) {
        this.errorMessage = error.toString();
        this.setSavableStateErrored();
      }
    },
    async onSubmitDisable() {
      this.setSavableStateProcessing()
      try {
        const response = await this.aroStore.disableAutoRule(this.$route.params.id)
        this.loadForm(response);
        this.successMessage = 'Successfully updated auto rule out!';
        this.setSavableStateSuccess();
      } catch(error) {
        this.errorMessage = error.toString();
        this.setSavableStateErrored();
      }
    },
    async onSubmitEnable() {
      this.setSavableStateProcessing()
      try {
        const response = await this.aroStore.enableAutoRule(this.$route.params.id)
        this.loadForm(response);
        this.successMessage = 'Successfully updated auto rule out!';
        this.setSavableStateSuccess();
      } catch(error) {
        this.errorMessage = error.toString();
        this.setSavableStateErrored();
      }
    },
    loadForm(record) {
      this.selectedAutoRuleOut = {
        id: record.id,
        ruleCategory: record.aro_rule_category_id,
        organGroup: record.organ_group_id,
        transplantCenter: record.transplant_center_id,
        ruleIdentifier: record.rule_identifier,
        dataSource: record.data_source,
        dataReference: record.data_reference,
        ruleDescription: record.rule_description,
        declineCode1: record.decline_code_one_id,
        declineCode2: record.decline_code_two_id,
        disabledAt: record.disabled_at,
        hasAssociatedMatch: record['has_associated_match?'],
        unosComment1: record.unos_comment_one,
        unosComment2: record.unos_comment_two
      }

      this.showDeclineComment1();
      this.showDeclineComment2();
    },
    openVariableReference() {
      this.$refs['templateVariablesModal'].showModal();
    },
    customLabel(option) {
      // Decline codes - options label
      return `${option.code} - ${option.category}`;
    },
    cancel() {
      this.$router.push({ name: 'admin_aro_rules' });
    },
    clearError() {
      this.errorMessage = null;
      this.setSavableStateIdle();
    }
  }
}
</script>