<template>
  <HorizontalBarChart chart-id="chart-transplanted-organs"
    :config="chartConfig"
    :results="chartResults"
    :loading="chartLoading"
    :error="chartError"
    :header="`Transplanted Organs`"
    @previewReport="previewReport"
    @reloadChart="loadChartData(filters)"/>
</template>

<script lang="js">
import { EP } from "~/src/endpoints";
import { chartMixin } from "@/src/mixins/chartMixin.js";
import HorizontalBarChart from "@/src/components/shared/charts/HorizontalBarChart.vue";

export default {
  components: {
    HorizontalBarChart,
  },
  mixins: [chartMixin],

  props: ['organ_groups', 'transplant_centers'],

  data() {
    return {
      chartConfig: {
        xTitle: 'Total Transplanted Organs',
        barColours: ['#A989C5'],
        barHoverColours: ['#c6b0d8'],
        resultLabelField: 'organ_name',
        datasets: [
          {
            dataField: 'number_of_transplants',
            label: 'Number of Transplants',
          }
        ]
      },
      filters: {},
      aro: false,
      transplanted: true,
    };
  },
  methods: {
    loadChartData(filterParameters) {
      this.filters = filterParameters || {};
      this.getChartResults(EP.external_analytics.chart_transplanted_organs, filterParameters);
    },
  },
}
</script>
