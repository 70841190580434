<template>
  <div v-if="user && user.deactivated_at" class="row">
    <div class="col-sm-12">
      <div class="alert alert-danger" role="alert">
        <span>This user is disabled</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // The user for which to check if they are disabled.
    user: { required: true, type: Object }
  }
}
</script>
