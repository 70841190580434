import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP } from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useAutoRuleOutStore = defineStore('auto-rule-out', {
  state: ()=> {
    return {
      // lookup data
      ruleCategories: [],
      transplantCenters: [],
      organGroups: [],
      declineCodes: [],
    }
  },
  getters: {
    getVerificationTypes() {
      return [
        { name: 'Yes', label: 'Yes' },
        { name: 'No', label: 'No' },
        { name: 'Cannot evaluate', label: 'Cannot evaluate' }
      ];  
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    
    // Load Rule Categories
    loadRuleCategories() {
      return this.loadEntity(APIRoute(EP.admin.aro_rule_categories.index), 'ruleCategories')
    },
    // load transplant centres
    loadTransplantCentres() {
      return this.loadEntity(APIRoute(EP.admin.transplant_centers.index), 'transplantCenters')
    },
    // load organ groups
    loadOrganGroups() {
      return this.loadEntity(APIRoute(EP.admin.organ_groups.index), 'organGroups')
    },
    // Load all decline codes
    loadDeclineCodes() {
      return this.loadEntity(APIRoute(EP.bypass_and_refusals.decline_codes), 'declineCodes')
    },
    // Load Auto Rule Outs
    loadAutoRuleOuts(params) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.aro_rules.index), { params: params });
    },
    loadAutoRuleOut(id) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.aro_rules.show, { id: id }));
    },
    createAutoRuleOut(payload) {
      return makeRequest(beApiClient.post, APIRoute(EP.admin.aro_rules.create), { aro_rule: payload });
    },
    updateAutoRuleOut(payload) {
      return makeRequest(beApiClient.patch, APIRoute(EP.admin.aro_rules.update, { id: payload.id }), { aro_rule: payload });
    },
    disableAutoRule(id) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.aro_rules.disable, { aro_rule_id: id }));
    },
    enableAutoRule(id) {
      return makeRequest(beApiClient.put, APIRoute(EP.admin.aro_rules.enable, { aro_rule_id: id }));
    },

    //Match level
    loadProcedureNote(matchId) {
      return makeRequest(beApiClient.get, APIRoute(EP.matches.procedure_notes.show, { match_id: matchId }));
    },
    loadApplicableAutoRulesForMatch(matchId){
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.matches.applicable_aro_rules.index, {  match_id: matchId })).then(response => {
          resolve(response);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    loadAutoRuleOutsForMatch(matchId) {
      return new Promise((resolve, reject) => {
        beApiClient.get(APIRoute(EP.matches.aro_rules.index, {  match_id: matchId })).then(response => {
          resolve(response);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    updateApplicableAutoRulesForMatch(matchId, payload){
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.matches.applicable_aro_rules.update, { match_id: matchId }), { match_aro_rules: payload }).then(response => {
          resolve(response);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    },
    resetApplicableAutoRulesForMatch(matchId){
      return new Promise((resolve, reject) => {
        beApiClient.post(APIRoute(EP.matches.applicable_aro_rules.reset, {  match_id: matchId })).then(response => {
          resolve(response);
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        })
      });
    }

  }
});