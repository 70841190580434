<template>
  <div>
    <DashboardWidgetLayout widget-id="coordinators-manual-offer-dashboard-full"
      widget-title="Offer Handling"
      :widget-icon="['fas', 'user-group']">
      <template #filters>
        <div class="row">
          <MultiSelectInput v-model="selectedOutcome"
            name="outcome"
            label="Outcomes:"
            :options="[ {id:'', name:'All'}, ...outcomeList ]"
            label-key="name"
            value-key="id"
            col-style="form-group-standard-column" />
          <MultiSelectInput v-model="selectedTeam"
            name="team"
            label="Team:"
            :options="[ {id:'', name:'All'}, ...teamsList ]"
            label-key="name"
            value-key="id"
            col-style="form-group-standard-column" />
          <div class="form-group-standard-column form-group-date-time">
            <div>
              <label for="from_date">
                <span>Offer Date From:</span>
              </label>
              <input id="from_date" ref="from_date" v-model="dateFrom" class="form-control" type="date">
            </div>
            <div>
              <label for="from_time">
                <span>Offer Time From:</span>
              </label>
              <input id="from_time" ref="from_time" v-model="fromTime" class="form-control" type="time">
            </div>
          </div>
          <div class="form-group-standard-column form-group-date-time">
            <div>
              <label for="to_date">
                <span>Offer Date To:</span>
              </label>
              <input id="to_date" ref="to_date" v-model="dateTo" class="form-control" type="date">
            </div>
            <div>
              <label for="to_time">
                <span>Offer Time To:</span>
              </label>
              <input id="to_time" ref="to_time" v-model="toTime" class="form-control" type="time">
            </div>
          </div>
          <div class="form-group-standard-column">
            <label for="search">
              <span>Keywords:</span>
            </label>
            <input id="search" v-model="searchString" type="text" class="form-control">
          </div>
          <div class="form-group-standard-column">
            <p class="p-label">
              Include Dismissed
            </p>
            <div class="form-check form-check-inline">
              <input id="include_dismissed"
                v-model="showDismissed"
                aria-labelledby="include_dismissed"
                type="checkbox" class="form-check-input">
              <label for="include_dismissed" class="form-check-label">
                <span>Yes</span>
              </label>
            </div>
          </div>
          <div class="form-group-standard-column">
            <p class="p-label">
              Show Cases Only?
            </p>
            <div class="form-check form-check-inline">
              <input id="show_cases_only"
                v-model="showCasesOnly"
                aria-labelledby="show_cases_only"
                type="checkbox" class="form-check-input">
              <label for="show_cases_only" class="form-check-label">
                <span>Yes</span>
              </label>
            </div>
          </div>
        </div>
        <div class="sub-divider" />
        <div class="save-toolbar-column">
          <button class="btn btn-success" @click="filterRecords()">
            Search
          </button>
          <button class="btn btn-secondary" @click="clearFilters()">
            Clear Filters
          </button>
        </div>
      </template>
      <template #content>
        <div class="row">
          <div class="col-sm-12">
            <div class="nav list-patients">
              <div class="ml-auto">
                <router-link :to="{ name: 'manage-offer' }" class="btn btn-primary">
                  <span>Record New Offer</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <PortalTable table-id="coordinator-dashboard-manual"
          mode="remote"
          :total-records="totalRecords"
          :page="page"
          :page-count="pageCount"
          :table-data="recordList"
          :loading="loading"
          @on-sort-change="onSortChange"
          @on-page="onPortalPage"
          @on-sort="onPortalSort">
          <template #columns>
            <Column field="donor.code" header="Donor" style="min-width: 100px;">
              <template #body="slotProps">
                <a href="#" class="table-link" @click="loadOfferDetails(slotProps.data)">{{ slotProps.data.donor.code }}</a>
              </template>
            </Column>
            <Column field="organ.organ_group.name" header="Organ" style="min-width: 100px;" />
            <Column field="code" header="Match" style="min-width: 80px;" />
            <Column field="transplant_center.code" header="TC" style="min-width: 100px;" />
            <Column field="acceptancelist_page_visited_at" header="Last Update" sortable style="min-width: 170px;">
              <template #body="slotProps">
                {{ formatLastUpdate(slotProps.data) }}
              </template>
            </Column>
            <Column field="seq" header="Seq." sortable style="min-width: 80px;">
              <template #body="slotProps">
                {{ slotProps.data.offers_ordered_by_seq == null ? 'N/A' : slotProps.data.offers_ordered_by_seq[0]?.seq }}
              </template>
            </Column>
            <Column field="outcome" header="Outcome" style="min-width: 100px;">
              <template #body="slotProps">
                {{ slotProps.data.outcome? slotProps.data.outcome.name : slotProps.data.calculated_outcome?.name }}
              </template>
            </Column>
            <Column class="btn-2-icon-group">
              <template #body="slotProps">
                <template v-if="slotProps.data.dismissed">
                  <a href="#" title="Activate" class="action btn btn-fill btn-outline-primary mx-" @click.prevent="activateRow(slotProps.data)">
                    <font-awesome-icon class="desktop-action" :icon="['fas', 'circle-plus']" /><span class="mobile-action">Activate</span>
                  </a>
                </template>
                <template v-else>
                  <a href="#" title="Dismiss Offer" class="action btn btn-fill btn-outline-danger mx-2" @click.prevent="dismissRow(slotProps.data)">
                    <font-awesome-icon class="desktop-action" :icon="['far', 'circle-xmark']" /><span class="mobile-action">Dismiss Offer</span>
                  </a>
                </template>

                <a href="#" title="Details" class="action btn btn-fill btn-outline-primary mx-2" @click.prevent="loadOfferDetails(slotProps.data)">
                  <font-awesome-icon class="desktop-action" :icon="['fas', 'file-lines']" /><span class="mobile-action">Details</span>
                </a>
              </template>
            </Column>
          </template>
        </PortalTable>
      </template>
    </DashboardWidgetLayout>
  </div>
</template>

<script lang="js">
import beApiClient from "@/src/be-api-client";
import DashboardWidgetLayout from "~/src/components/shared/DashboardWidgetLayout.vue";
import {APIRoute, EP} from "~/src/endpoints";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import {useAppCoreStore} from "@/src/stores/app-core";
import Column from 'primevue/column';
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";

export default {
  components: {
    PortalTable,
    DashboardWidgetLayout,
    Column,
    MultiSelectInput
  },
  setup() {
    const appStore = useAppCoreStore();
    return {
      appStore
    }
  },
  data() {
    return {
      csrfToken: $("meta[name='csrf-token']").attr("content"),
      recordList: [],
      teamsList: [],
      searchString: '',
      currentSortField: '',
      currentSortType: '',
      loading: false,
      selectedRow: {donor: '', match: '', organ: '', tc: '', offer_date: ''},
      selectedTeam: '',
      selectedOutcome: '',
      dateFrom: '',
      fromTime: '',
      dateTo: '',
      toTime: '',
      pageCount: 1,
      totalRecords: 0,
      version: '',
      page: 1,
      statusList: [],
      outcomeList: [],
      showDismissed: false,
      showCasesOnly: false,
      modalDataPath: 'dashboard/get_modal_data',
    }
  },
  mounted() {
    this.appStore.setAppBreadcrumbs([
      {
        to: { name: 'internal-dashboards-root' },
        text: 'Dashboards'
      },
      {
        text: 'Offers Dashboard'
      }
    ]);
    let params = new URLSearchParams(window.location.search)
    this.page = params.get('page') || 1
    this.searchString = params.get('search') || ''
    this.selectedOutcome = params.get('outcome') || ''
    this.selectedTeam = params.get('team') || ''
    this.showDismissed = params.get('show_dismissed') === 'true'
    this.showCasesOnly = params.get('show_cases_only') === 'true'

    try {
      this.getRowData()
    } catch(error) {
      console.log(error)
    }
  },
  methods: {
    formatLastUpdate(data) {
      if(data.add_manually) return this.formatDate(data.updated_at);
      return this.formatDate(data.acceptancelist_page_visited_at || data.offer_date)
    },
    search() {
      const offer_date_from = this.dateFrom && this.fromTime  ? new Date(`${this.dateFrom}T${this.fromTime}`) : this.dateFrom ? this.dateFrom : '';
      const offer_date_to = this.dateTo && this.toTime ? new Date(`${this.dateTo}T${this.toTime}`) : this.dateTo ? this.dateTo : '';
      return `/coordinator_dashboard?page=${this.page}&search=${this.searchString.trim()}&outcome=${this.selectedOutcome}&show_dismissed=${this.showDismissed}&team=${this.selectedTeam}&date_from=${offer_date_from}&date_to=${offer_date_to}&sortField=${this.currentSortField}&sortType=${this.currentSortType}&show_cases_only=${this.showCasesOnly}`
    },
    loadOfferDetails(match) {
      this.$router.push({
        name: 'manage-offer',
        query: {
          donor_code: match.donor.code,
          match_code: match.code,
          tc: match.transplant_center.code
        }
      })
    },
    clearFilters() {
      this.searchString = ''
      this.selectedOutcome = ''
      this.selectedTeam = ''
      this.showDismissed = false
      this.showCasesOnly = false
      this.dateFrom = ''
      this.fromTime = ''
      this.toTime = ''
      this.dateTo = ''
      this.$refs.from_date.value = ''
      this.$refs.from_time.value = ''
      this.$refs.to_date.value = ''
      this.$refs.to_time.value = ''

      this.getRowData(true)
    },
    dismissRow(row) {
      this.sendAxiosPatchRequest('dismiss', row)
    },
    sendAxiosPatchRequest(endpoint, row) {
      beApiClient.patch(`matches/${row.id}/${endpoint}`).then((response) => {
        this.getRowData()
      }).catch((error) => {
        console.log(error)
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    activateRow(row) {
      this.sendAxiosPatchRequest('activate', row)
    },
    filterRecords() {
      this.getRowData(true);
    },
    getRowData(reset = false) {
      const _vm = this
      if (reset) this.page = 1;

      const offer_date_from = this.dateFrom && this.fromTime  ? new Date(`${this.dateFrom}T${this.fromTime}`) : this.dateFrom ? this.dateFrom : '';
      const offer_date_to = this.dateTo && this.toTime ? new Date(`${this.dateTo}T${this.toTime}`) : this.dateTo ? this.dateTo : '';
      const params = {
        page: this.page,
        search: this.searchString.trim(),
        status: this.selectedStatus,
        outcome: this.selectedOutcome,
        show_dismissed: this.showDismissed,
        team: this.selectedTeam,
        date_from: offer_date_from,
        date_to: offer_date_to,
        sortField: this.currentSortField,
        sortType: this.currentSortType,
        show_cases_only: this.showCasesOnly
      }

      // Get data
      this.loading = true;
      const url = APIRoute(EP.coordinator_dashboard.manual);
      beApiClient.get(url, {
        headers: {'Accept': 'application/json'},
        params: params })
      .then((response) => {
        _vm.totalRecords = response.data.total_records
        _vm.recordList = response.data.records
        _vm.pageCount = response.data.page_count
        _vm.teamsList = response.data.teams
        _vm.statusList = response.data.statuses
        _vm.outcomeList = response.data.outcomes
        _vm.loading = false
      })
    },
    timeFromDate(date) {
      let daysCalc = 1000 * 60 * 60 * 24
      let milliseconds = new Date() - new Date(date)
      let days = Math.floor(milliseconds / daysCalc);
      milliseconds -= days * daysCalc

      let hours = Math.floor(milliseconds / (daysCalc / 24));
      milliseconds -= hours * (daysCalc / 24)

      let minutes = Math.floor(milliseconds / (daysCalc / (24 * 60)));
      milliseconds -= minutes * (daysCalc / (24 * 60))

      let seconds = Math.floor(milliseconds / (daysCalc / (24 * 60 * 60)));

      return `${days > 0 ? `${days}d, ` : ''}${hours > 0 ? `${hours}h, ` : ''}${minutes > 0 ? `${minutes}m, ` : ''}${seconds > 0 ? `${seconds}s` : ''}`
    },
    getModalData(record) {
      this.$router.push({
        name: 'edit-offers',
        params: {
          id: record.donor.code + '-' +  record.code + '-' + record.transplant_center.code
        }
      });
    },
    onSortChange(params) {
      if (params == null) {
        this.currentSortField = undefined;
        this.currentSortType = undefined;
        this.getRowData(true)
      }
    },
    onPortalSort(params) {
      if (!params.sortField) { return false; }
      this.currentSortField = params.sortField;
      switch(params.sortOrder) {
        case 1:
          this.currentSortType = 'asc';
          break;
        case -1:
          this.currentSortType = 'desc';
          break;
      }
      this.getRowData();
    },
    onPortalPage(page) {
      this.page = page;
      this.getRowData();
    }
  }
}
</script>
