<template>
  <CardSection :loading="isSavableStateLoading" :error="majorError">
    <template #header>
      Edit Team {{ selectedTeam ? `- ${selectedTeam.teamName}` : '' }}
    </template>

    <template #body>
      <SubSection sub-section-heading="Manage Members" :sub-section-nav="subSectionNav">
        <template #body>
          <VeeForm ref="addMembershipForm"
            @submit="onAddMembership">
            <div class="row">
              <MultiSelectInput v-model="addMembershipUsers"
                name="full_name"
                :options="usersMissingFromTeam"
                label="Users"
                label-key="full_name"
                value-key="id"
                col-style="form-group-5-column-large col-xl-5"
                :multiple="true"
                rules="required"
                placeholder="Search for User" />

              <MultiSelectInput v-model="addMembershipRole"
                name="role"
                :options="roleStore.roles"
                label="Role"
                label-key="role_name"
                value-key="id"
                col-style="form-group-5-column-large col-xl-5"
                placeholder="Search for Role"
                rules="required" />
            </div>

            <ActionToolbar :component-state="currentState" 
              :primary-button-text="`Add Member(s)`"
              toolbar-size="col-md-6"
              :secondary-enabled="false"
              :error-message="formError" />  
          </VeeForm>

          <TeamMembershipsTable :users-on-team="usersOnTeam"
            :selected-team="selectedTeam"
            :page-number="pageNumber"
            :page-count="pageCount"
            :total-records="totalRecords"
            :loading="isSavableStateLoading || isSavableStateProcessing"
            @handle-page-change="handlePageChange"
            @filters-updated="handleFilterChange"
            @memberships-updated="reloadTeam()" />
        </template>
      </SubSection>
    </template>
  </CardSection>
</template>

<script lang="js">
import CardSection from "@/src/components/shared/CardSection.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { useTeamStore } from '@/src/stores/team';
import { useRoleStore } from '@/src/stores/role';
import { useUserStore } from '@/src/stores/user';
import SubSection from "@/src/components/shared/SubSection.vue";
import { Form as VeeForm } from 'vee-validate';
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { FilterMatchMode } from 'primevue/api';
import {teamSubSectionNavMixin} from "@/src/components/admin-area/teams/teamSubSectionNavMixin";
import { paginationMixin } from "@/src/mixins/paginationMixin";
import TeamMembershipsTable from "./_teamMembershipsTable.vue";

export default {
  components: {
    CardSection,
    SubSection,
    ActionToolbar,
    VeeForm,
    MultiSelectInput,
    TeamMembershipsTable
  },
  mixins: [ savableStateMixin, paginationMixin, teamSubSectionNavMixin ],
  emits: {'membershipsUpdated': null},
  setup() {
   const teamStore = useTeamStore();
   const roleStore = useRoleStore();
   return { teamStore, roleStore };
  },
  data() {
    return {
      selectedRows: [],
      /**
       * We need to handle a form for each individual Row separate as there is a separate save action for each.
       * Since we don't really know how many rows are going to be in the table and we need to maintain Vue reactivity
       * We define a single object here with the properties that we will need:
       *  - roleId: to track to role being updated
       *  - message: Message to show in the text
       * By defining the object this way, we can dynamically add single key => value properties everywhere using
       * Team Membership ID as the key for a row between all three Objects.
      */ 
      editRowData: {
        roleId: {},
        message: {},
        currentState: {}
      },
      addMembershipUsers: null,
      addMembershipRole: null,
      usersOnTeam: [],
      usersMissingFromTeam: [],
      currentSortField: '',
      currentSortType: '',
      search_params: '',
      sort_params: '',
      pageNumber: 1,
      pageCount: '',
      totalRecords: 0,
      selectedTeam: null,
      // teamId: this.$route.params.id
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.teamStore.loadTeams();
      await this.roleStore.loadRoles();
      await this.loadTeam(this.$route.params.id)
      this.setSavableStateIdle();
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    /**
     * Loads the Team and users not in this team
     * @param id
     * @return {Promise<void>}
     */
    async loadTeam(id) {
      const record = await this.teamStore.loadTeam(id)
      await this.getRowData(false);
      this.selectedTeam = {
        id: record.id,
        teamName: record.name,
        membershipCategoryId: record.membership_category_id,
        teamAccess: record.team_accesses,
      }

      const usersMissingFromTeam = await this.teamStore.loadUsersMissingFromTeam(this.selectedTeam.id)
      this.usersMissingFromTeam = usersMissingFromTeam.data || [];
      this.resetAddMembershipForm();
    },
    async handleFilterChange(event) {
      this.search_params = event;
      this.getRowData(true);
    },
    async getRowData(reset = false) {
      if (reset) this.pageNumber = 1;
      
      // build search params
      if (this.currentSortField) {
        this.sort_params = { sort_by: this.currentSortField, sort_type: this.currentSortType };
      } else {
        this.sort_params = '';
      }

      // load users
      let params = {
        page: this.pageNumber
      };

      params = { ...this.sort_params,...this.search_params, ...params };
      const response = await this.teamStore.loadUsersOnTeam(this.$route.params.id, params);

      this.usersOnTeam = response.data;
      this.totalRecords = response.pagy.count;
      this.pageCount = response.pagy.pages;
      this.pageNumber = response.pagy.page;
    },
    async onAddMembership() {
      try {
        this.setSavableStateProcessing();
        const payload = {
          team: {
            id: this.selectedTeam.id,
            team_memberships: this.addMembershipUsers.map((item) => {
              return {
                user_id: item,
                role_id: this.addMembershipRole
              }
            })
          }
        }
        await this.teamStore.addUsersToTeam(this.selectedTeam.id, payload);
        await this.loadTeam(this.selectedTeam.id);
        this.setSavableStateSuccess();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    resetAddMembershipForm() {
      this.addMembershipUsers = null;
      this.addMembershipRole = null;
      // TODO: Figure out interactions between form and primevue
      setTimeout(() => this.$refs.addMembershipForm.resetForm(), 20);
    },
    async reloadTeam() {
      try {
        this.setSavableStateProcessing();
        await this.loadTeam(this.selectedTeam.id);
        this.setSavableStateIdle();
      } catch(error) {
        this.handleMajorError(error);
      }
    }
  }
}
</script>
