export const userSubSectionNavMixin = {
  computed: {
    subSectionNav() {
      if(!this.$route.params.id) return null;
      return [
        { to: { name: 'admin_users_edit', params: { id: this.$route.params.id } }, label: 'Profile' },
        { to: { name: 'admin_users_edit_teams', params: { id: this.$route.params.id } }, label: 'Teams' },
        { to: { name: 'admin_users_edit_advanced', params: { id: this.$route.params.id } }, label: 'Advanced' },
      ]
    }
  }
}
